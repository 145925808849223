import { Action, Dispatch } from "../../utils/state";
import { Refine } from "../../utils/type";
import { Translations } from "../../translations";
import { mapObject } from "../../utils/object";
import { modifyAt, objectFromList, countWhere } from "../../utils/array";
import { parseUnresolved, showUnresolved } from "../model/atformula";
import { ManageSetAtFormula, ManageBenchmarkSetValueWeight } from "../protocol/client";
import { State, FieldFilters, filtersToString, ManageTool, ManageState, getCurrentItem, getSourceForItem, ManageEditWeights, ManageEditChart, chartNumberFields, ChartNumberKey, FilterOptions, ManageEditFilterOptions, Filters, ManageEditFilters, ManageEditAtFormula, SourceSplitDate, ManageEditBenchmarkItem, getBenchmarkSourceForItem, DirectoryLayout } from "../model";
import { ManageAction } from "../protocol";
import { selectDetailChart } from "./";

const setManageTool = (tool: ManageTool | undefined) => lift((manage) => ({ ...manage, tool }));

const lift = (map: (manage: ManageState, state: State) => ManageState): Action<State> => (state) => ({
  ...state,
  activeTool: undefined,
  manage: state.manage === undefined ? undefined : map(state.manage, state),
});

const liftTool = <T extends ManageTool["type"]>(tool: T | T[], map: (tool: Refine<ManageTool, T>, manage: ManageState, state: State) => ManageTool): Action<State> =>
  lift((manage, state) => {
    if (manage.tool !== undefined && (manage.tool.type === tool || tool instanceof Array && (tool as ManageTool["type"][]).indexOf(manage.tool.type) !== -1)) {
      return {
        ...manage,
        tool: map(manage.tool as Refine<ManageTool, T>, manage, state),
      };
    }
    return manage;
  });

const currentItem = (state: State) => {
  if (state.dashboard.type !== "dashboard") return;
  if (state.path.length === 0) return state.dashboard.root;
  return state.dashboard.items[state.path[state.path.length - 1]];
};

export const cancelTool: Action<State> = setManageTool(undefined);

export const startEditTitle: Action<State> = (state) => setManageTool({ type: "title", title: currentItem(state)!.title })(state);
export const startAddItem: Action<State> = setManageTool({ type: "add-item" });
export const startEditBenchmark: Action<State> = (state) => setManageTool({ type: "edit-benchmark" })(state);

export const editTitle = (lang: keyof Translations, value: string) => liftTool(["title", "edit-chart"], (tool) => ({
  ...tool,
  title: {
    ...tool.title,
    [lang]: value,
  },
}));

export const saveTitle: Action<State> = lift((manage, state) => {
  if (manage.tool === undefined || manage.tool.type !== "title") return manage;

  return {
    ...manage,
    tool: undefined,
    committedAction: {
      action: "set-title",
      itemId: currentItem(state)!.itemId,
      title: manage.tool.title,
    },
  };
});

const commitAction = (action: (manage: ManageState, state: State) => ManageAction | undefined) => lift((manage, state) => ({
  ...manage,
  tool: undefined,
  committedAction: action(manage, state),
}));

const commitActionForTool = <T extends ManageTool["type"]>(tool: T, keepOpen: boolean, action: (tool: Refine<ManageTool, T>, manage: ManageState, state: State) => ManageAction | undefined): Action<State> => lift((manage, state) => {
  if (manage.tool !== undefined && manage.tool.type === tool) {
    return {
      ...manage,
      tool: keepOpen ? manage.tool : undefined,
      committedAction: action(manage.tool as Refine<ManageTool, T>, manage, state),
    };
  }
  return manage;
});

export const addItem = (source: "audit" | "at" | "directory") => commitAction((manage, state) => ({ action: "add-item", itemId: currentItem(state)!.itemId, source }));

export const removeItem = commitAction((manage, state) => ({ action: "remove-item", itemId: currentItem(state)!.itemId }));

export const startEditWeights = lift((manage, state) => {
  if (state.dashboard.type !== "dashboard") return manage;

  const directory = getCurrentItem(state);
  if (directory === undefined || directory.type === "root" || directory.items === undefined) return manage;
  const source = getSourceForItem(state, directory);
  if (source === undefined || source.type !== "directory") return manage;

  const items: ManageEditWeights["items"] = [];
  for (const id of directory.items) {
    const item = state.dashboard.items[id];
    if (item === undefined) continue;
    let weight = source.weights[id];
    if (weight === undefined) weight = 0;
    items.push({
      id,
      title: item.title,
      weight: weight.toString(),
    });
  }

  const tool: ManageEditWeights = { type: "edit-weights", items, unit: source.unit, decimals: directory.decimals.toString() };

  return { ...manage, tool };
});

export const editWeight = (index: number, weight: string) => liftTool("edit-weights", (tool, manage) => ({
  ...tool,
  items: modifyAt(tool.items, index, (item) => ({ ...item, weight })),
}));

export const saveWeights = commitActionForTool("edit-weights", false, (tool, manage, state) => ({
  action: "set-directory-weights",
  itemId: currentItem(state)!.itemId,
  weights: objectFromList(tool.items, (item) => item.id, (item) => Math.abs(parseInt(item.weight, 10))),
  unit: tool.unit,
  decimals: parseInt(tool.decimals, 10),
}));

export const startManageCharts = setManageTool({
  type: "charts",
});

export const removeChart = (detailIndex: number) => commitActionForTool("charts", true, (tool, manage, state) => ({
  action: "remove-detail-chart",
  itemId: currentItem(state)!.itemId,
  detailIndex,
}));

export const addChart = commitActionForTool("charts", true, (tool, manage, state) => ({
  action: "add-detail-chart",
  itemId: currentItem(state)!.itemId,
}));

export const setChartAsDetailChart = (value: boolean) => commitActionForTool("charts", true, (tool, manage, state) => ({
  action: "set-chart-as-detail-chart",
  itemId: currentItem(state)!.itemId,
  value,
}));

export const startEditChart = (index: number | undefined, detailIndex: number | undefined): Action<State> => (state) => {
  const item = getCurrentItem(state);
  if (item === undefined || item.type === "root") return state;

  const { chart, title } = index === undefined ? item : item.detailCharts[index];

  const tool: ManageEditChart = {
    type: "edit-chart",
    detailIndex,
    title: detailIndex === undefined ? undefined : title,
    chartType: chart.type,
    split: "service",
    min: "0",
    max: "100",
    transitionGreenOrange: "90",
    transitionOrangeRed: "80",
  };

  for (const { key, value } of chartNumberFields(chart)) {
    tool[key] = value === undefined ? "" : value.toString(10);
  }
  if (chart.type === "bar" || chart.type === "trend") {
    tool.split = chart.split;
  }

  // Select the selected detail chart and set the current tool.
  return selectDetailChart(index)(setManageTool(tool)(state));
};

export const editChartField = <T extends ChartNumberKey | "chartType" | "split">(key: T, value: ManageEditChart[T]) => liftTool("edit-chart", (tool, manage, state) => ({
  ...tool,
  [key]: value,
}));

export const saveChart = (s: State) => startManageCharts(commitActionForTool("edit-chart", false, (tool, manage, state) => ({
  action: "set-chart",
  itemId: currentItem(state)!.itemId,
  detailIndex: tool.detailIndex === undefined ? null : tool.detailIndex,
  title: tool.title === undefined ? null : tool.title,
  chart: {
    chartType: tool.chartType,
    split: tool.split,
    min: tool.min,
    max: tool.max,
    transitionGreenOrange: tool.transitionGreenOrange,
    transitionOrangeRed: tool.transitionOrangeRed,
  },
}))(s));

export const startEditFilters = (benchmarkId?: number) => setManageTool({
  type: "edit-filters",
  benchmarkId,
  active: undefined,
  filters: undefined,
  counts: {},
});

const editFilterCount = (tool: ManageEditFilters): ManageEditFilters => ({
  ...tool,
  counts: mapObject(tool.filters || {}, (options) => countWhere(options, (option) => option.selected)),
});

export const provideFilterOptions = (filterOptions: FilterOptions) => liftTool("edit-filters", (tool, manage, state) => {
  const item = getCurrentItem(state);
  if (item === undefined || item.type === "root") return tool;
  const source = getSourceForItem(state, item);
  if (source === undefined || source.type === "directory") return tool;

  let fields: FieldFilters;
  if (tool.benchmarkId === undefined) {
    fields = source.filters.fields || {};
  } else {
    const benchmarks = getBenchmarkSourceForItem(state, item);
    if (benchmarks === undefined) return tool;
    const benchmark = benchmarks.sources[tool.benchmarkId];
    if (benchmark === undefined || typeof benchmark.source === "number") return tool;
    fields = benchmark.source.filters.fields || {};
  }

  const filters: ManageEditFilterOptions = mapObject(filterOptions, (list, key) => {
    const selected = fields[key] || [];
    return list.map(({ label, value }) => ({ label, value, selected: selected.indexOf(value) !== -1 }));
  });
  return editFilterCount({ ...tool, filters });
});

export const filterOptionsSetActive = (active: keyof FilterOptions | undefined) => liftTool("edit-filters", (tool) => {
  return { ...tool, active };
});

export const toggleFilterOptions = (index: number) => liftTool("edit-filters", (tool) => {
  if (tool.filters === undefined || tool.active === undefined) return tool;

  const current = tool.filters[tool.active];
  if (current === undefined) return tool;

  return editFilterCount({
    ...tool,
    filters: {
      ...tool.filters,
      [tool.active]: modifyAt(current, index, (item) => ({ ...item, selected: !item.selected })),
    },
  });
});

export const saveFilters = lift((manage, state) => {
  if (manage.tool === undefined || manage.tool.type !== "edit-filters") return manage;
  const tool = manage.tool;

  if (tool.filters === undefined) return manage;

  const fields: FieldFilters = mapObject(tool.filters, (options) => {
    const ids = options.filter((option) => option.selected)
      .map((option) => option.value);
    if (ids.length === 0) return undefined;
    return ids as string[];
    // This is a (string | null)[] for locations, and a string[] for the other cases.
    // We cannot enforce that with the type system, we thus need to add a cast here.
  });

  const filters: Filters = { fields };

  const committedAction: ManageAction = {
    action: "set-filters",
    itemId: currentItem(state)!.itemId,
    benchmarkId: tool.benchmarkId === undefined ? null : tool.benchmarkId,
    filters: filtersToString(filters),
  };
  return {
    ...manage,
    committedAction,
    tool: tool.benchmarkId === undefined ? undefined : { type: "edit-benchmark" },
  };
});

export const startEditAtFormula = (benchmarkId: number | undefined) => lift((manage, state) => {
  const item = currentItem(state);
  if (item === undefined || item.type === "root") return manage;
  const tool: ManageEditAtFormula = { type: "edit-formula", benchmarkId, expression: undefined, unit: undefined, result: undefined, timeout: undefined, decimals: item.decimals.toString() };
  return {
    ...manage,
    tool,
  };
});

export const editAtFormula = (dispatch: Dispatch<State>, expression: string) => liftTool("edit-formula", (tool) => {
  if (tool.timeout !== undefined) window.clearTimeout(tool.timeout);

  return {
    ...tool,
    expression,
    result: undefined,
    timout: window.setTimeout(() => dispatch(tryParseAtFormula), 400),
  };
});

export const editUnit = (unit: string) => liftTool(["edit-formula", "edit-weights"], (tool) => ({
  ...tool,
  unit,
}));

export const editDecimals = (decimals: string) => liftTool(["edit-formula", "edit-weights"], (tool) => ({
  ...tool,
  decimals,
}));

export const startEditNorm: Action<State> = (state) => {
  const item = currentItem(state);
  if (item === undefined || item.type === "root") return state;

  return setManageTool({ type: "edit-norm-trend", norm: item.norm === undefined ? "" : item.norm.toString(10), trend: item.trendPeriod, })(state);
};

export const editNorm = (norm: string) => liftTool(["edit-norm-trend"], (tool) => ({
  ...tool,
  norm,
}));

export const editTrend = (trend: SourceSplitDate | undefined) => liftTool(["edit-norm-trend"], (tool) => ({
  ...tool,
  trend,
}));

export const saveNorm = commitActionForTool("edit-norm-trend", false, (tool, manage, state) => {
  let norm: number | null = null;
  if (tool.norm !== "") {
    norm = parseFloat(tool.norm);
    if (!isFinite(norm)) norm = null;
  }
  return {
    action: "set-norm-trend",
    itemId: currentItem(state)!.itemId,
    norm,
    trend: tool.trend === undefined ? null : tool.trend,
  };
});

export const tryParseAtFormula = liftTool("edit-formula", (tool) => ({
  ...tool,
  result: tool.expression === undefined ? undefined : parseUnresolved(tool.expression),
}));

export const saveAtFormula: Action<State> = (state) => {
  state = tryParseAtFormula(state);
  if (state.manage === undefined || state.manage.tool === undefined || state.manage.tool.type !== "edit-formula") {
    return state;
  }

  if (state.manage.tool.result === undefined || state.manage.tool.result.type === "parse-error") {
    return state;
  }

  const action: ManageSetAtFormula = {
    action: "set-at-formula",
    itemId: currentItem(state)!.itemId,
    benchmarkId: state.manage.tool.benchmarkId === undefined ? null : state.manage.tool.benchmarkId,
    expression: state.manage.tool.expression || "",
    unit: state.manage.tool.unit || "",
    decimals: parseInt(state.manage.tool.decimals, 10),
  };

  return {
    ...state,
    manage: {
      ...state.manage,
      committedAction: action,
      tool: state.manage.tool.benchmarkId === undefined ? undefined : { type: "edit-benchmark" },
    },
  };
};

export const addBenchmarkSource = (source: "constant" | "at" | "audit") => commitActionForTool("edit-benchmark", true, (tool, manage, state) => {
  return {
    action: "add-benchmark-source",
    itemId: currentItem(state)!.itemId,
    source,
  };
});

export const removeBenchmarkSource = (benchmarkId: number) => commitActionForTool("edit-benchmark", true, (tool, manage, state) => {
  return {
    action: "remove-benchmark-source",
    itemId: currentItem(state)!.itemId,
    benchmarkId,
  };
});

export const startEditBenchmarkItem = (benchmarkId: number) => lift((manage, state) => {
  const item = currentItem(state);
  if (item === undefined || item.type === "root") return manage;
  const benchmarks = getBenchmarkSourceForItem(state, item);
  if (benchmarks === undefined) return manage;
  const benchmark = benchmarks.sources[benchmarkId];

  const tool: ManageEditBenchmarkItem = {
    type: "edit-benchmark-item",
    index: benchmarkId,
    value: typeof benchmark.source === "number" ? benchmark.source.toFixed(item.decimals) : undefined,
    weight: benchmark.weight.toFixed(0),
    period: benchmark.period,
  };

  return {
    ...manage,
    tool,
  };
});

export const editBenchmarkItem = (key: "value" | "weight", value: string) => liftTool("edit-benchmark-item", (tool) => ({
  ...tool,
  [ key ]: value,
}));

export const editBenchmarkPeriod = (months: 3 | 6 | 12) => liftTool("edit-benchmark-item", (tool) => ({
  ...tool,
  period: { type: "last-months", months },
}));

export const saveBenchmarkItem = lift((manage, state) => {
  if (manage.tool !== undefined && manage.tool.type === "edit-benchmark-item") {
    let value = null;
    if (manage.tool.value !== undefined) {
      value = parseFloat(manage.tool.value);
      if (!isFinite(value)) value = 0;
    }
    const weight = parseInt(manage.tool.weight, 10);

    const period = filtersToString({ period: manage.tool.period });

    const committedAction: ManageBenchmarkSetValueWeight = {
      action: "benchmark-set-value-weight",
      itemId: currentItem(state)!.itemId,
      benchmarkId: manage.tool.index,
      value,
      weight,
      period,
    };

    return {
      ...manage,
      tool: { type: "edit-benchmark" },
      committedAction,
    };
  }
  return manage;
});

export const setDirectoryLayout = (layout: DirectoryLayout): Action<State> => commitAction((manage, state) => ({
  action: "directory-set-layout",
  itemId: currentItem(state)!.itemId,
  layout,
}));
