import { Translations } from "../../translations";
import { ServerErrorReason } from "../protocol/server";

export const errorMessages: Translations<{ [_ in ServerErrorReason]: string | ((description: string | undefined) => string) }> = {
  nl: {
    unknown: "Er is een onbekende fout opgetreden. Probeer de pagina te herladen.",
    "bad-request": "Er is iets fout gegaan. Probeer de pagina te herladen. Fout: bad request.",
    "manage-filter-own-organisation": "U moet de eigen organisatie als opdrachtgever of opdrachtnemer kiezen.",
    "manage-at-parse-error": "De expressie kon niet worden ingelezen.",
    "manage-at-unresolved-reference": (description) => `Deze variabelen uit de expressie werden niet gevonden: ${ description }. Check wat de goede codes van de bedoelde vragen zijn.`,
  },
  en: {
    unknown: "An unknown error has occured. Try to reload the page.",
    "bad-request": "An error occured. Try to reload the page. Error: bad request.",
    "manage-filter-own-organisation": "You must choose your organisation as client or supplier.",
    "manage-at-parse-error": "The expression could not be parsed.",
    "manage-at-unresolved-reference": (description) => `The following variables in the expression could not be found: ${ description }. Please check the codes of the questions.`,
  },
};
