// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import * as React from "react";
import { Glyphicon, CsrfForm } from "../utils/view";
import { Dispatch } from "../utils/state";
import * as model from "./model";
import * as action from "./action";
import { updateCustomStyle } from "./style";
import { t } from "../translations";

export function renderer(dispatch: Dispatch<model.State>) {
  return Render;

  function Render(state: model.State) {
    updateCustomStyle(state.colors);

    return (
      <div>
        <nav className="topbar-container">
          <TopBar {...state} />
          <Breadcrumbs {...state} />
        </nav>
        <Sidebar {...state} />
      </div>
    );
  }

  function Sidebar(state: model.State) {
    if (state.user == null) return <div></div>;
    const last = state.menus[state.path[state.path.length - 1]] === undefined ? state.path.length - 2 : state.path.length - 1;

    let additionalClasses = "";

    if (state.isMobile && !state.sidebarVisibleIfMobile) {
      additionalClasses += " sidebar-hidden";
    }

    return (
      <div className="sidebar-container">
        <div className={ `sidebar-background${ additionalClasses }` } onClick={ (e) => dispatch(action.setMenu(e, false)) }></div>
        <div className={ `sidebar${ additionalClasses }` }>
        <div className="sidebar-header-actions">
          <a href="/users/sign_out" data-method="delete"><Glyphicon icon="log-out" /></a>
          <LanguageDropdown {...state} />
        </div>
          {
            Object.keys(state.menus).map((key) => <MenuList
              key={ key }
              name={ key }
              state={ state }
            />)
          }
        </div>
      </div>
    );
  }

  function MenuList({ state, name }: { state: model.State, name: string }) {
    const menu = state.menus[name];
    const index = state.path.indexOf(name);

    let root = false;
    let active = false;
    let selected: string | undefined;

    let className = "nav-sidebar";

    let zIndex = index;

    let parent: model.Menu | undefined;
    let parentItem: model.MenuItem | undefined;

    if (index !== -1) {
      root = index === 0;
      // The menu is active if it is the last element of the path or if it is the next-to-last element and the last element
      // is not a menu (but refers to the selected item in this menu)
      active = index === state.path.length - 1 || (index === state.path.length - 2 && state.menus[state.path[index + 1]] === undefined);
      selected = state.path[index + 1];

      if (selected === undefined && root) {
        selected = "root";
      }

      className += ` nav-sidebar-${ root ? "root" : "sub" }`;
      className += ` nav-sidebar-${ active ? "" : "in" }active`;

      parent = state.menus[state.path[index - 1]] || state.menus[state.path[index - 2]];
      if (parent !== undefined) {
        for (const item of parent.items) {
          if (item.key === name) {
            parentItem = item;
          }
        }
      }
    } else {
      className += " nav-sidebar-hidden nav-sidebar-sub nav-sidebar-active";
      zIndex = state.path.length;
    }

    let footer = undefined;
    if (root && state.isMobile) {
      footer = <footer><a href="/copyright" onClick={ (e) => dispatch(action.setMenu(e, false)) }>{ t.menu.copyright }</a></footer>;
    }

    return (
      <div className={ className }>
        { root ? <RootHeader {...state} /> : <SubHeader parent={parent} parentItem={parentItem} /> }
        <ul style={{ zIndex }}>
          { menu.items.map((item, i) =>
            <MenuItem menu={name} key={i} item={item} selected={selected === item.key} />) }
        </ul>
        { footer }
      </div>
    );
  }

  function MenuItem({ menu, item, selected }: { menu: string, item: model.MenuItem, selected: boolean }) {
    return (
      <li className={ selected ? "active" : "" }>
        <a href={ item.path } onClick={ (e) => dispatch(action.openSubMenu(e, menu, item.key)) }>
          { item.icon && <Glyphicon icon={ item.icon } /> }
          { item.title }
        </a>
      </li>
    );
  }

  function RootHeader(state: model.State) {
    return (
      <div className="sidebar-header sidebar-header-root">
        <span>{ t.menu.menu }</span>
      </div>
    );
  }

  function SubHeader({ parent, parentItem }: { parent?: model.Menu, parentItem?: model.MenuItem }) {
    if (parent === undefined || parentItem === undefined) {
      return (<div className="sidebar-header sidebar-header-sub"></div>);
    }

    return (
      <div className="sidebar-header sidebar-header-sub">
        <span>
          <a className="sidebar-header-back" href="/" onClick={ () => dispatch(action.root()) }>&lsaquo;</a>
          <a className="sidebar-header-title" href={ parentItem.path }>{ parentItem && parentItem.title }</a>
        </span>
      </div>
    );
  }

  function TopBar(state: model.State) {
    return (
      <div className="topbar">
        <div className="topbar-header">
          <a href="javascript:;" className="topbar-open-menu" onClick={ (e) => dispatch(action.setMenu(e, true)) } style={ state.user == null ? { visibility: "hidden" } : {} }>
            <Glyphicon icon="menu-hamburger" />
          </a>
          { !state.isMobile || state.organisation.hasLogo ?
            <div className="topbar-logo">
              <img src={ state.organisation.logo } />
            </div> : null
          }
          { !state.isMobile || !state.organisation.hasLogo ?
            <a href="/" className="topbar-brand">{ state.organisation.name }</a> : null
          }
        </div>

        <div className="topbar-right">
          <TopBarRight { ...state } />
        </div>
      </div>
    );
  }

  function TopBarRight(state: model.State) {
    if (state.user === null) {
      return (
        <ul></ul>
      );
    }

    let notificationClassName = "topbar-notifications";
    if (state.notifications > 0) {
      notificationClassName += " topbar-notifications-new";
    } else {
      notificationClassName += " topbar-notifications-none";
    }

    return (
      <ul>
        <li className="topbar-notifications-li">
          <a href="/notifications" className={ notificationClassName }>
            <Glyphicon icon="bell" />
            <div>{ state.notifications > 99 ? 99 : state.notifications }</div>
          </a>
        </li>
        <li>
          <LanguageDropdown {...state} />
        </li>
        <li>
          <a href={ state.user.url } className="topbar-profile">
            <Glyphicon icon="user" />
            { ` ${state.user.name}` }
          </a>
        </li>
        <li>
          <a href="/users/sign_out" className="btn btn-danger" data-method="delete">
            { t.menu.logout }
          </a>
        </li>
      </ul>
    );
  }

  function LanguageDropdown({ locale, user, csrf, languageDropdownVisible }: model.State) {
    let icon = "";
    for (const language of locale.languages) {
      if (language.locale === locale.current) {
        icon = language.icon;
      }
    }

    return (
      <div>
        <div className="language-select" onClick={ () => dispatch(action.toggleLanguageDropdown()) }>
          <img src={ icon } />
        </div>
        <div style={{ display: languageDropdownVisible ? "block" : "none" }} className="language-dropdown">
          <div className="language-dropdown-overlay" onClick={ () => dispatch(action.toggleLanguageDropdown()) }></div>
          <div className="language-dropdown">
            <CsrfForm csrf={ csrf } method="patch" action={ user!.updateLangUrl } data-partial="false">
              <input type="hidden" name="user[path]" />
              {
                locale.languages.map((lang) =>
                  <button key={lang.locale} type="submit" name="user[locale]" value={lang.locale}>
                    <img src={ lang.icon } />
                  </button>,
                )
              }
            </CsrfForm>
          </div>
        </div>
      </div>
    );
  }

  function Breadcrumbs({ breadcrumbs }: model.State) {
    const crumbs: JSX.Element[] = [];

    for (let i = 0; i < breadcrumbs.length - 1; i++) {
      const crumb = breadcrumbs[i];
      crumbs.push(<a key={i} href={crumb.url}>{ crumb.text }</a>);
    }

    if (breadcrumbs.length !== 0) {
      const crumb = breadcrumbs[breadcrumbs.length - 1];
      crumbs.push(<a key={breadcrumbs.length - 1} className="current" href={crumb.url}>{ crumb.text }</a>);
    }

    return (
      <div className="breadcrumbs-container">
        <div>
          <div className="breadcrumbs">
            { crumbs }
          </div>
        </div>
      </div>
    );
  }
}
