import { pickadateConfig } from "../config";

// Check for support of the date input type

export const supportsDateInput = checkDateInputSupport();

function checkDateInputSupport() {
  try {
    const testInput = document.createElement("input");
    testInput.type = "date";
    testInput.value = "a";
    return testInput.value !== "a";
  } catch {
    return false;
  }
}

export function enableDatePicker(elements: JQuery, config?: any) {
  if (supportsDateInput) {
    elements.attr("type", "date");
    return;
  }
  // Pickadate expects that the initial value is passed in the data-value attribute,
  // so we need to copy 'value' to 'data-value'.
  elements.each((_index, element) => {
    const value = element.getAttribute("value");
    if (value !== null) element.setAttribute("data-value", value);
  });
  const fullConfig = config ? { ...pickadateConfig, ...config } : pickadateConfig;
  (elements as any).pickadate(fullConfig);
}
