import * as React from "react";
import { Dispatch } from "../../utils/state";
import { Glyphicon, DatePicker } from "../../utils/view";
import { State, FilterState, FilterOptionsState, Tool, TimePeriodLastMonths, TimePeriod } from "../model";
import * as action from "../action";
import { t } from "../../translations";

export function TimePeriodForm({ dispatch, filters }: { dispatch: Dispatch<State>, filters: FilterState }) {
  const selected = filters.current.period;
  return (
    <div className="time-period-form">
      <TimePeriodOptionLastMonths dispatch={ dispatch } selected={ selected } months={ 12 } />
      <TimePeriodOptionLastMonths dispatch={ dispatch } selected={ selected } months={ 6 } />
      <TimePeriodOptionLastMonths dispatch={ dispatch } selected={ selected } months={ 3 } />
      <br /><br />
      <TimePeriodOption text={ t.dashboard.time_period.full.custom } selected={ selected.type === "custom" } onClick={ () => dispatch(action.setTimePeriodCustom) } />
      {
        filters.current.period.type !== "custom" ? null : <div className="time-period-dates">
            <DatePicker value={ filters.current.period.start } onChange={ (value) => dispatch(action.setTimePeriodDate("start", value)) } />
            <DatePicker value={ filters.current.period.end } onChange={ (value) => dispatch(action.setTimePeriodDate("end", value)) } />
          </div>
      }
    </div>
  );
}

function TimePeriodOptionLastMonths({ selected, months, dispatch }: { selected: TimePeriod, months: TimePeriodLastMonths["months"], dispatch: Dispatch<State> }) {
  return <TimePeriodOption
    text={ t.dashboard.time_period.full.last_months[months] }
    selected={ selected.type === "last-months" && selected.months === months }
    onClick={ () => dispatch(action.setTimePeriod({ type: "last-months", months: months })) }
  />;
}

function TimePeriodOption({ onClick, selected, text }: { onClick: () => void, selected: boolean, text: string }) {
  let className = "time-period-option";
  if (selected) {
    className += " time-period-option-selected";
  } else {
    className += " time-period-option-not-selected";
  }
  return <a href="javascript:;" className={ className } onClick={ onClick }>
    <Glyphicon icon={ selected ? "check" : "unchecked" } />
    { " " + text }
  </a>;
}

export function FilterForm({ inTool, dispatch, filters }: { inTool: boolean, dispatch: Dispatch<State>, filters: FilterState }) {
  let className = "filter-form";
  if (inTool) {
    className += " filter-form-tool";
  } else {
    className += " filter-form-static";
  }

  let text = null;
  if (filters.optionKeys === "loading" || filters.optionKeys === "uninitialized") {
    text = t.notices.loading;
  } else if (filters.optionKeys.length === 0 && inTool) {
    className += " filter-form-has-message";
    text = t.dashboard.filters.no_filters;
  }

  return (
    <div className={ className }>
      <div className="filter-form-icon" onClick={ () => dispatch(action.openTool(Tool.Filter)) }>
        <Glyphicon icon="filter" />
      </div>
      <span onClick={ () => dispatch(action.openTool(Tool.Filter)) }>Filters:</span>
      <FilterField inTool={ inTool } dispatch={ dispatch } filters={ filters } field="client" />
      <FilterField inTool={ inTool } dispatch={ dispatch } filters={ filters } field="supplier" />
      <FilterField inTool={ inTool } dispatch={ dispatch } filters={ filters } field="service" />
      <FilterField inTool={ inTool } dispatch={ dispatch } filters={ filters } field="location" />
      <FilterField inTool={ inTool } dispatch={ dispatch } filters={ filters } field="category" />
      { text && <div className="filter-form-message">{ text }</div> }
    </div>
  );
}

export function FilterField({ inTool, dispatch, filters, field }: { inTool: boolean, dispatch: Dispatch<State>, filters: FilterState, field: keyof FilterOptionsState }) {
  if (!(filters.optionKeys instanceof Array) || filters.optionKeys.indexOf(field) === -1) {
    return null;
  }

  let className = "filter-field";

  if (filters.submenu === field && inTool) {
    className += " open";
  }

  // Check whether the 'no filter' button is selected
  const selection = filters.current.fields && filters.current.fields[field];
  const selectionSize = selection === undefined ? 0 : selection.length;

  if (selectionSize !== 0) {
    className += " has-selection";
  }

  const fieldState = filters.options[field];
  const list = fieldState === undefined ? [] : fieldState.list || [];

  return (
    <div className={ className }>
      <div className="filter-field-header">
        <a href="javascript:;" onClick={() => dispatch(action.toggleFiltersSubmenu(field))}>
          { t.dashboard.filters[field] }
          {
            selectionSize === 0 ? null : <div className="filter-field-count">{ selectionSize }</div>
          }
        </a>
      </div>
      { inTool ?
        <ul className="filter-field-options">
          {
            fieldState !== undefined && fieldState.list === undefined ? <li>{ t.notices.loading }</li>
              : fieldState !== undefined && fieldState.list!.length === 0 ? <li>Geen opties beschikbaar</li>
              : null
          }
          { list.map(({ label: optionLabel, value, selected }, index) =>
            <li key={ value || "null" } onClick={ () => dispatch(action.toggleFilter(field, index)) } className={ selected ? "selected" : "" }>
              <Glyphicon icon={ selected ? "check" : "unchecked" } />
              { optionLabel }
            </li>)
          }
          <li className={ selectionSize !== 0 ? "filter-field-options-remove layout-color-light" : "hidden" } onClick={ () => dispatch(action.removeFilters(field)) }><Glyphicon icon="trash" />{ t.dashboard.filters.remove }</li>
        </ul> : null
      }
    </div>
  );
}
