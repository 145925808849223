import { isMobile } from "../navigation/navigation";
import * as search from "./search";

interface ColumnData {
  index: number,
  width: number,
}

const buttonsWidth = 26;

export const tableHandler = (el: HTMLElement) => {
  const table = el as HTMLTableElement;

  const initialClassName = table.className;

  if (table.tHead == null || table.tHead.rows.length === 0 || table.tHead.rows[0].getAttribute("data-preserve-all-columns") === "true") {
    return {};
  }

  const columns: { [priority: number]: ColumnData } = {};
  const headerRow = table.tHead.rows[0];
  const columnCount = headerRow.cells.length;
  let hasButtons = false;
  let standardButtonsWidth = 0;

  const visible: boolean[] = [];
  for (let i = 0; i < columnCount; i++) {
    visible[i] = true;
    const th = headerRow.cells[i];
    const priorityString = th.getAttribute("data-priority") || "0";
    const width = parseInt(th.getAttribute("data-width") || "100", 10);

    if (priorityString === "buttons") {
      columns[columnCount - 1] = { index: i, width };
      hasButtons = true;
      standardButtonsWidth = width;
    } else {
      const priority = parseInt(priorityString, 10);
      columns[priority] = { index: i, width };
    }
  }

  const fullWidths = el.getElementsByClassName("td-full-width");

  const searchHandler = el.getAttribute("data-search") === "true" ? search.tableSearchHandler(table, headerRow, visible) : undefined;

  resize();

  return { resize, unmount };

  function resize() {
    let remaining = table.parentElement!.getBoundingClientRect().width;
    let i = 0;

    for (; i < columnCount; i++) {
      const width = columns[i].width;
      const isButtonColumn = i === columnCount - 1;
      if (isButtonColumn && isMobile() && width !== 1) break; // Always hide buttons on mobile interface
      if (width + (isButtonColumn ? 0 : buttonsWidth) < remaining) {
        visible[i] = true;
        remaining -= width;
      } else {
        break;
      }
    }

    if (i === 0) i = 1;
    let colspan = columnCount;

    let className = initialClassName;

    if (i !== columnCount) {
      if (i === columnCount - 1) {
        className += " table-hide-column-single";
      }
      className += " table-hide-column";
      for (; i < columnCount - 1; i++) {
        className += ` table-hide-column-${ columns[i].index }`;
        colspan -= 1;
        visible[columns[i].index] = false;
      }
    }

    if (hasButtons) {
      if (i === columnCount - 1) {
        className += ` table-hide-buttons`;
        visible[headerRow.cells.length - 1] = false;
        headerRow.cells[headerRow.cells.length - 1].setAttribute("width", `${buttonsWidth}px`);
      } else {
        visible[headerRow.cells.length - 1] = true;
        headerRow.cells[headerRow.cells.length - 1].setAttribute("width", `${standardButtonsWidth}px`);
      }
    }

    for (let j = 0; j < fullWidths.length; j++) {
      fullWidths[j].setAttribute("colspan", colspan.toString());
    }

    table.className = className;
  }
  function unmount() {
    // Unmount the table
    if (searchHandler) searchHandler.unmount();
  }
};
