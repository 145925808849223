// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import * as ReactDom from "react-dom";
import { host, Dispatch } from "../utils/state";
import * as translations from "../translations";
import * as model from "./model";
import { renderer } from "./view";

export type Breadcrumb = model.Breadcrumb;

let dispatch: Dispatch<model.State>;
export let getState: () => model.State;

export function mount() {
  const element = document.getElementById("menus");
  const state = initial(element!);

  const menu = host(element!, state, renderer, onChange);
  dispatch = menu.dispatch;
  getState = menu.getState;
}

export function update(diff: Partial<model.State>, sync?: boolean) {
  dispatch((state) => ({...state, ...diff}), sync);
}

export function isLoggedIn() {
  return getState().user !== null;
}

export function userId() {
  const user = getState().user;
  if (user !== null) return user.id;
  return null;
}

function initial(element: HTMLElement): model.State {
  return { ...(window as any).fpmMenus, isMobile: false, sidebarVisibleIfMobile: false, languageDropdownVisible: false };
}

export function currentLocale() {
  return getState().locale.current;
}

function onChange(state: model.State) {
  translations.setLocale(state.locale.current);
}
