export type SourceSplitDate = "date-1-month" | "date-3-month" | "date-12-month";
export type SourceSplitNonDate = "service" | "location" | "category" | "client" | "supplier";
export type SourceSplit = SourceSplitNonDate | SourceSplitDate;

export function isSourceSplitDate(split: string | undefined): split is SourceSplitDate {
  return split === "date-1-month" || split === "date-3-month" || split === "date-12-month";
}
export function getSplitDatePeriod(split: SourceSplitDate): { unit: "month", count: number } {
  if (split === "date-1-month") {
    return { unit: "month", count: 1};
  } else if (split === "date-3-month") {
    return { unit: "month", count: 3};
  } else {
    return { unit: "month", count: 12 };
  }
}

export enum ChartState {
  Normal,
  Loading,
  NoData,
}

export interface BaseChart {
  unit?: string,
}

export interface Text {
  content: string,
  width: number,
}

export interface NumberChart extends BaseChart {
  type: "number",
  value?: number,
  text?: Text,
  textDecimalUnit?: Text, // decimal and the unit
}

export interface BarChart extends BaseChart {
  type: "bar",
  split: SourceSplit,
  min: number,
  max: number,

  barGroups?: BarChartGroup[],
  bars?: BarChartBar[],
  labelWidth?: number,
  valueWidth?: number,
  valueUnitWidth?: number,
}

export interface BarChartGroup {
  id: string,
  label: string,
  labelWidths: Float32Array,
  bars: BarChartBar[],
  average: number,
}
export interface BarChartBar {
  id: string | null,
  label: string,
  labelWidths: Float32Array,
  value: number,
}

export interface TrendChart extends BaseChart {
  type: "trend",

  // Split per timeCount timeUnit, eg. split per 3 months
  split: SourceSplitDate,

  min: number,
  max: number,

  fromPeriod?: number,
  values?: TrendChartValue[],
}

export interface TrendChartValue {
  value?: number,
}

export interface TrafficLightChart extends BaseChart {
  type: "traffic-light",

  // Value of transition from green to orange
  transitionGreenOrange: number,
  // Value of transition from orange to red
  transitionOrangeRed: number,

  value?: number,
}

export type StaticChart = NumberChart | BarChart | TrafficLightChart | TrendChart;
export type Chart = StaticChart & { state: ChartState };

export function trendChartRange(chart: TrendChart, date: [Date, Date]): { to: number, from: number, array: TrendChartValue[] } {
  const { unit, count } = getSplitDatePeriod(chart.split);

  const from = period(date[0]);
  const to = period(date[1]);

  const array: TrendChartValue[] = new Array(to - from);
  for (let i = 0; i < to - from; i++) {
    array[i] = { value: undefined };
  }

  return { to, from, array };

  function period(d: Date) {
    const month = d.getMonth() + (d.getFullYear() - 2000) * 12;
    if (unit !== "month") throw new Error("Unsupported time unit");
    return ((month | 0) / (count | 0)) | 0;
  }
}
