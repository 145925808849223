// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

let currentDark = "";
let currentLight = "";

export function updateCustomStyle([dark, light]: [string, string]) {
  // Only update if necessary
  if (currentDark === dark && currentLight === light) return;

  currentDark = dark;
  currentLight = light;

  const style = `
body,
a.topbar-brand:link,
a.topbar-brand:hover,
a.topbar-brand:active,
a.topbar-brand:visited {
  color: #${ dark };
}
a:link, a:hover, a:active, a:visited, .filter-field-options > .selected > i, .layout-color-light,
.table-searchable > thead > .table-header > th:last-child {
  color: #${ light };
}
.btn-default,
.btn-default:link,
.btn-default:hover,
.btn-default:active,
.btn-default:visited,
.btn-default:hover:active,
.label-default,
.label-default:link,
.label-default[href]:hover,
.label-default[href]:active,
.label-default[href]:visited,
.label-default[href]:hover:active {
  background-color: #${ dark };
}
.btn-primary,
.btn-primary:link,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:hover:active,
.label-primary,
.label-primary:link,
.label-primary[href]:hover,
.label-primary[href]:active,
.label-primary[href]:visited,
.label-primary[href]:hover:active  {
  background-color: #${ light };
}`;

  document.getElementById("custom-stylesheet")!.textContent = style;
}
