import * as React from "react";
import { Action, Dispatch } from "../../utils/state";
import { State, DragState, DragSide, ManageState, getCurrentItem, Dashboard, Item, Root, Tool, dragToHidden } from "../model";
import { updateOrder } from "../model/personalize";
import { getItemLayout } from "../model/layout";

export type DragApi = "touch" | "drag";
// Drag and drop is implemented using the HTML5 drag and drop API on desktop and touch events on mobile.
export let dragApi: DragApi | undefined;

export const dragStart = (index: number): Action<State> => (state) => {
  if (dragApi === undefined) dragApi = "drag"; // Detected support for the drag api
  if (dragApi !== "drag") return state;
  return {
    ...state,
    drag: { selected: index, hover: { index, side: DragSide.Left }, touch: undefined, },
  };
};

export const dragCancel: Action<State> = (state) => (dragApi !== "drag" ? state : {
  ...state,
  drag: undefined,
});

export const dragDrop: Action<State> = (state) => {
  if (state.drag === undefined || state.dashboard.type !== "dashboard" || state.drag.hover === undefined) return state;

  if (state.manage !== undefined) {
    const manage: ManageState = {
      ...state.manage,
      committedAction: {
        action: "move-items",
        itemId: state.dashboard.items[getCurrentItem(state)!.items![state.drag.selected]]!.itemId,
        index: state.drag.hover.index,
      },
    };
    return {
      ...state,
      manage,
    };
  } else {
    const parent = getCurrentItem(state);
    if (parent === undefined || parent.items === undefined) return state;

    const oldIndex = state.drag.selected;
    let newIndex = state.drag.hover.index;

    const child = parent.items[oldIndex];
    const newItems = parent.items.filter((item) => item !== child);

    let firstHidden = newItems.length;
    if (parent.type === "root") {
      firstHidden = parent.firstHidden;
    }
    const newHidden = dragToHidden(state);
    const oldHidden = oldIndex >= firstHidden;

    if (oldIndex < newIndex) newIndex--;

    if (newHidden && !oldHidden) firstHidden--;
    if (!newHidden && oldHidden) firstHidden++;

    if (firstHidden < 1) firstHidden = 1;

    newItems.splice(newIndex, 0, child);

    let rootLayout = state.rootLayout;

    const newParent: Item | Root = { ...parent, items: newItems };
    if (newParent.type === "root") {
      newParent.firstHidden = firstHidden;
      rootLayout = getItemLayout(state, newParent);
    }
    const newDashboard: Dashboard = newParent.type === "root"
      ? { ...state.dashboard, root: newParent }
      : { ...state.dashboard, items: { ...state.dashboard.items, [newParent.id]: newParent } };

    const prefOrder = newItems.slice(0, firstHidden).map((item) => newDashboard.items[item]!.originalId);
    const prefHidden = parent.type === "item" ? undefined : newItems.slice(firstHidden).map((item) => newDashboard.items[item]!.originalId);

    return {
      ...state,
      dashboard: newDashboard,
      user: {
        preferences: updateOrder(state.user.preferences, prefOrder, prefHidden),
        shouldPush: true,
      },
      drag: undefined,
      rootLayout,
      // Force `showHiddenItems` to false if there are no hidden items
      showHiddenItems: firstHidden === newItems.length ? false : state.showHiddenItems,
    };
  }
};

const lift = (api: DragApi, action: (state: DragState) => DragState | undefined): Action<State> => (state) => {
  if (state.drag === undefined || api !== dragApi) return state;
  return { ...state, drag: action(state.drag) };
};

export const hoverStart = (api: DragApi, index: number, side: DragSide) => lift(api, (drag) => ({
  ...drag,
  hover: { index, side },
}));

export const hoverEnd = (api: DragApi, index: number, side: DragSide) => lift(api, (drag) => (drag.hover === undefined || drag.hover.index !== index || drag.hover.side !== side ? drag : {
  ...drag,
  hover: undefined,
}));

export const touchStart = (index: number, event: React.TouchEvent<HTMLDivElement>, dispatch: Dispatch<State>): Action<State> => (state) => {
  if (dragApi === undefined) dragApi = "touch"; // Detected support for the touch api
  if (dragApi !== "touch") return state;

  const touch = event.changedTouches[0];

  const startX = touch.pageX - state.container.offsetLeft;
  const startY = touch.pageY - state.container.offsetTop;

  return {
    ...state,
    drag: {
      selected: index,
      hover: { index, side: DragSide.Left },
      touch: {
        timeout: window.setTimeout(() => dispatch(touchStartTimeout), 150),
        identifier: touch.identifier,
        startX,
        startY,
        currentX: startX,
        currentY: startY,
      },
    },
  };
};

export const touchMove = (ev: React.TouchEvent<HTMLDivElement>): Action<State> => (state) => {
  if (state.drag === undefined || state.drag.touch === undefined || dragApi !== "touch") return state;

  for (let i = 0; i < ev.changedTouches.length; i++) {
    const touch = ev.changedTouches[i];
    if (touch.identifier !== state.drag.touch.identifier) continue;

    const areas = state.container.getElementsByClassName("dashboard-item-droppable");
    let hover: DragState["hover"];
    for (let j = 0; j < areas.length; j++) {
      const area = areas[j];
      const rect = area.getBoundingClientRect();
      if (rect.left <= touch.clientX && touch.clientX <= rect.left + rect.width && rect.top <= touch.clientY && touch.clientY <= rect.top + rect.height) {
        const index = parseInt(area.getAttribute("data-index")!, 10);
        const side = parseInt(area.getAttribute("data-side")!, 10);
        hover = { index, side };
      }
    }

    const drag: DragState = {
      ...state.drag,
      hover,
      touch: {
        ...state.drag.touch,
        currentX: touch.pageX - state.container.offsetLeft,
        currentY: touch.pageY - state.container.offsetTop,
      },
    };
    return { ...state, drag };
  }
  return state;
};

export const touchEnd = (ev: React.TouchEvent<HTMLDivElement>, success: boolean): Action<State> => (state) => {
  if (state.drag === undefined || state.drag.touch === undefined || dragApi !== "touch") return state;

  for (let i = 0; i < ev.changedTouches.length; i++) {
    const touch = ev.changedTouches[i];
    if (touch.identifier !== state.drag.touch.identifier) continue;

    if (state.drag.touch.timeout !== undefined) {
      window.clearTimeout(state.drag.touch.timeout);
    } else {
      document.body.removeEventListener("touchmove", handlerPreventScroll);

      if (success) {
        state = dragDrop(state);
      }
      return {
        ...state,
        drag: undefined,
      };
    }
  }

  return state;
};

export const touchStartTimeout: Action<State> = (state) => {
  if (state.drag === undefined || state.drag.touch === undefined) return state;
  const touch = state.drag.touch;
  const distanceSq = (touch.currentX - touch.startX) ** 2 + (touch.currentY - touch.startY) ** 2;
  if (distanceSq > 50) {
    return {
      ...state,
      drag: undefined,
    };
  }

  document.body.addEventListener("touchmove", handlerPreventScroll, { passive: false });

  const drag: DragState = {
    ...state.drag,
    touch: {
      timeout: undefined,
      identifier: touch.identifier,
      startX: touch.currentX,
      startY: touch.currentY,
      currentX: touch.currentX,
      currentY: touch.currentY,
    },
  };
  return { ...state, drag };
};

export const click: Action<State> = (state) => {
  if (state.manage !== undefined) return state;
  return {
    ...state,
    activeTool: Tool.Personalize,
  };
};

const handlerPreventScroll = (event: TouchEvent) => {
  event.preventDefault();
};
