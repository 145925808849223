import { isIE } from "../navigation/navigation";

function downloadFont(path: string, callback: (value: string) => void) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = (e) => {
    if (xhr.readyState !== xhr.DONE) {
      console.log("Failed to load font", e);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;
      callback(result.substring(result.indexOf(",") + 1));
    };
    reader.readAsDataURL(xhr.response as Blob);
  };
  xhr.open("GET", path, true);
  xhr.send();
}

let fontDataLato = "";

export function onload() {
  if (!isIE) {
    downloadFont((window as any).fpmPaths.lato, (value) => fontDataLato = value);
  }
}

interface Component {
  height: number,
  newPage(): void,
  apply(): void,
}

function noop() {
  // Does nothing
}

const margin = 20;
const pageWidth = 210;
const pageHeight = 297;

const tableMargin = 2;

const gray = "#bdc3c7";

function addMargin(marginHeight = 5): Component {
  return {
    height: marginHeight,
    newPage: noop,
    apply: noop,
  };
}

export function renderPdf(root: HTMLElement, layoutColorLight: string, layoutColorDark: string) {
  const pdf = new jsPDF();
  (pdf as any).addFileToVFS("lato.ttf", fontDataLato);
  pdf.addFont("lato.ttf", "Lato", "normal");
  pdf.setFont("Lato", "normal");

  let y = 20;

  for (const component of renderNodes(root.childNodes, margin, pageWidth - margin * 2)) {
    if (y + component.height > pageHeight - margin) {
      newPage();
      component.newPage();
    }
    component.apply();
    y += component.height;
  }

  return pdf;

  function newPage() {
    y = 20;
    pdf.addPage();
  }

  function combineComponents(components: IterableIterator<Component>) {
    const list: Component[] = [];
    let heightSum = 0;

    for (const component of components) {
      list.push(component);
      heightSum += component.height;
    }

    return {
      height: heightSum,
      newPage: noop,
      apply: () => {
        const initialY = y;
        for (const component of list) {
          component.apply();
          y += component.height;
        }
        y = initialY;
      },
    };
  }

  function* renderNodes(nodes: NodeList, x: number, width: number): IterableIterator<Component> {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.nodeType === node.TEXT_NODE) {
        yield renderText(10, "#000000", node.textContent!, x, width);
      } else if (node.nodeType === node.ELEMENT_NODE) {
        yield* renderElement(node as HTMLElement, x, width);
      }
    }
  }

  function* renderElement(element: HTMLElement, x: number, width: number): IterableIterator<Component> {
    if (element.tagName === "H1") {
      yield renderText(20, layoutColorLight, (element.textContent || "").toUpperCase(), x, width);
    } else if (element.tagName === "H2") {
      yield renderText(16, layoutColorDark, (element.textContent || "").toUpperCase(), x, width, 4);
    } else if (element.tagName === "H3") {
      yield renderText(13, layoutColorDark, (element.textContent || "").toUpperCase(), x, width, 4);
    } else if (element.tagName === "P") {
      yield* renderNodes(element.childNodes, x, width);
      yield addMargin();
    } else if (element.className === "field") {
      const title = renderText(10, "#666666", element.children[0].textContent || "", x, width * 0.17, 0.2, 0.2);
      const value = renderText(10, "#000000", element.children[1].textContent || "", x + width * 0.19, width * 0.81, 0.2, 0.2);
      const component: Component = {
        height: Math.max(title.height, value.height),
        newPage: noop,
        apply: () => {
          title.apply();
          value.apply();
        },
      };
      yield component;
    } else if (element.tagName === "DIV" || element.tagName === "SPAN") {
      if (element.className === "fontsize-small") {
        yield renderText(7, "#111111", element.textContent || "", x, width);
      } else if (element.className === "newpage") {
        newPage();
      } else {
        yield* renderNodes(element.childNodes, x, width);
      }
    } else if (element.tagName === "TABLE") {
      yield* renderTable(element as HTMLTableElement, x, width);
    } else if (element.tagName === "BR") {
      return addMargin();
    } else {
      console.log("Unsupported element in conversion to PDF. Cannot render tag " + element.tagName);
    }
  }

  function renderText(size: number, color: string, text: string, x: number, width: number, paddingTop = 0, paddingBottom = 0): Component {
    text = text.trim();
    if (text === "") return { height: 0, newPage: noop, apply: noop };

    pdf.setFontSize(size);
    const lines: string[] = pdf.splitTextToSize(text, width);

    const lineHeight = size / 2.1;

    return {
      height: lineHeight * lines.length + paddingTop + paddingBottom,
      newPage: noop,
      apply: () => {
        pdf.setFontSize(size);
        pdf.setTextColor(color as any);
        for (let i = 0; i < lines.length; i++) {
          pdf.text(lines[i], x, y + paddingTop + lineHeight * (i + 0.76));
        }
      },
    };
  }

  function* renderTable(element: HTMLTableElement, x: number, width: number): IterableIterator<Component> {
    const widths = element.getAttribute("data-widths")!.split(",").map((item) => parseInt(item, 10));

    const head = element.tHead === null ? undefined : combineComponents(renderSection(element.tHead, true));

    if (head !== undefined) yield head;
    for (let i = 0; i < element.tBodies.length; i++) {
      yield* renderSection(element.tBodies[i], false);
    }

    function* renderSection(section: HTMLTableSectionElement, isHead: boolean): IterableIterator<Component> {
      for (let i = 0; i < section.rows.length; i++) {
        yield renderRow(section.rows[i], isHead);
      }
    }

    function renderRow(row: HTMLTableRowElement, isHead: boolean): Component {
      let cellX = x;
      const applies: (() => void)[] = [];
      let height = 1;

      for (let i = 0; i < row.cells.length; i++) {
        const cellWidth = widths[i] * width / 100;
        const content = combineComponents(
          renderNodes(
            row.cells[i].childNodes,
            cellX + tableMargin,
            cellWidth - 2 * tableMargin
          )
        );
        cellX += cellWidth;
        applies.push(content.apply);
        if (content.height > height) height = content.height;
      }

      height += tableMargin * 2;

      return {
        height,
        newPage: () => {
          if (!isHead && head !== undefined) {
            head.apply();
            y += head.height;
          }
        },
        apply: () => {
          pdf.setFillColor(gray);
          if (isHead) {
            pdf.rect(x, y, width, height, "F");
          } else {
            pdf.rect(x, y + height - 0.4, width, 0.8, "F");
          }
          const initialY = y;
          y += tableMargin;
          for (const apply of applies) apply();
          y = initialY;
        },
      };
    }
  }
}
