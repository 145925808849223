import * as React from "react";
import { Dispatch } from "../../utils/state";
import { Translations, t } from "../../translations";
import { TrendChart, State, Item, SourceSplitDate } from "../model";
import * as action from "../action";
import * as colors from "./colors";
import { translate } from "../../translations/get";
import { getYAxisBlockCount } from "./barchart";
import { withUnit } from "../utils";

const enum LabelStyle {
  FullMonth,
  ShortMonth,
  Quarter,
  Year,
  None,
}

export function TrendPicture({ item, chart, width, height, isDetailChart, dispatch }: { item: Item, chart: TrendChart, width: number, height: number, isDetailChart: boolean, dispatch: Dispatch<State> }) {
  let norm: JSX.Element | undefined;

  // Graph is generated as a `w * 100` canvas and rendered as `width * height`.
  // The value of `w` is calculated such that these two rectangles have the same ratio.
  const scale = 100 / height;
  const w = Math.round(width * scale);

  const yTop = isDetailChart ? 8 : 10;
  const yBottom = isDetailChart ? 75 : 80;
  const maxBarHeight = yBottom - yTop;

  const x0 = w * 0.1;
  const innerWidth = w - x0 * 2;

  if (item.norm !== undefined) {
    const y = yBottom - toHeight(item.norm);
    norm = <line x1={w * 0.02} y1={ y } x2={w * 0.98} y2={ y } stroke={ colors.norm } strokeWidth={ scale } strokeDasharray={ 5 * scale } />

    if (isDetailChart && x0 / scale > 60) {
      norm = <g>
        { norm }
        <rect x={ 1 } y={ y - 13 * scale } rx={ 10 * scale } ry={ 10 * scale } width={ 50 * scale } height={ 25 * scale } fill={ colors.norm } radius={ 5 * scale } />
        <text x={ 1 + 7 * scale } y={ y } fill="#fff" fontSize={ `${14 * scale}px` } dominantBaseline="middle">Norm</text>
      </g>;
    }
  }

  const points: JSX.Element[] = [];
  const lines: JSX.Element[] = [];
  const verticalLines: JSX.Element[] = [];

  const path: string[] = [];

  // horizontal lines & labels
  let horizontalLines: JSX.Element[] = [];
  if (isDetailChart) {
    const blocks = getYAxisBlockCount(chart.min, chart.max, item.decimals);
    const step = (chart.max - chart.min) / blocks;
    for (let i = 0; i <= blocks; i++) {
      const value = chart.min + step * i;
      const y = yTop + maxBarHeight - toHeight(value);
      if (i !== 0) horizontalLines.push(<line key={2 * i} x1={w * 0.02 + 40 * scale} y1={ y } x2={w * 0.98} y2={ y } stroke={ colors.lightgray } strokeWidth={ scale } />);
      horizontalLines.push(<text key={2 * i + 1} x={ w * 0.02 + 36 * scale } y={ y } fill={ colors.gray } fontSize={ `${14 * scale}px` } dominantBaseline="middle" textAnchor="end">{ withUnit(value, chart.unit, item.decimals) }</text>);
    }
  }

  if (chart.values !== undefined && chart.fromPeriod !== undefined) {
    // Depending on the size of the chart and the size of the chosen date range, we will show labels for a different period size than the data for the chart.
    // For instance, we could show quarter or year labels for month-based data, if the labels would otherwise be too small.
    // We set a multiplier and an offset to denote this change in scale.

    let multiplier = 1;
    let labelStyle = LabelStyle.FullMonth;

    const labelWidth = innerWidth * multiplier / chart.values.length / scale;

    if (chart.split === "date-1-month") {
      if (labelWidth < 20) {
        multiplier = 12;
        if (labelWidth < 2 || !isDetailChart) {
          labelStyle = LabelStyle.None;
        } else {
          labelStyle = LabelStyle.Year;
        }
      } else if (labelWidth < 70) {
        labelStyle = LabelStyle.Quarter;
        multiplier = 3;
      } else if (labelWidth < 100) {
        labelStyle = LabelStyle.ShortMonth;
      }
    } else {
      labelStyle = LabelStyle.Quarter;
      if (labelWidth < 60) {
        multiplier = 4;
        if (labelWidth < 8 || !isDetailChart) {
          labelStyle = LabelStyle.None;
        } else {
          labelStyle = LabelStyle.Year;
        }
      }
    }

    let offset = multiplier - (chart.fromPeriod % multiplier);
    if (offset === multiplier) offset = 0;

    let prevX: number | undefined;
    let prevY: number | undefined;

    // Vertical lines & labels
    // For a non-detail chart with labelstyle none, we will also hide the labels
    if (isDetailChart || labelStyle !== LabelStyle.None) {
      for (let i = 0; i <= chart.values.length; i++) {
        const x = x0 + innerWidth * i / chart.values.length;

        let labelX: number | undefined;
        let sectionStart = false;
        if (i % multiplier === offset) {
          // New section starts here
          sectionStart = true;
          if (i + multiplier <= chart.values.length) {
            // This block fits in the current range, so show the label
            labelX = x + innerWidth * multiplier / 2 / chart.values.length;
          } else if (i + multiplier / 2 <= chart.values.length) {
            // The block ends outside of the current range, but there is still some room to show the label
            labelX = (x + x0 + innerWidth) / 2;
          }
        } else if (i === 0 && multiplier / 2 <= offset) {
          // The block started before the current position, but there is still some room to show that label
          labelX = x0 + innerWidth * offset / 2 / chart.values.length;
        }

        verticalLines.push(
          <g key={ i }>
            <line stroke={ colors.lightgray } x1={ x } y1={ isDetailChart || sectionStart ? yTop : yBottom } x2={ x } y2={ yBottom + (sectionStart ? (isDetailChart ? 2 + 60 * scale : 2 + 40 * scale) : 0) } strokeWidth={ scale } />
            { labelX === undefined ? null : <text x={ labelX } y={ yBottom + 2 + 20 * scale } fill="currentColor" fontSize={ `${18 * scale}px` } textAnchor="middle">{ showLabel(chart.split, chart.fromPeriod + i, labelStyle) }</text>}
            { labelX === undefined || labelStyle === LabelStyle.Year || labelStyle === LabelStyle.None || !isDetailChart ? null : <text x={ labelX } y={ yBottom + 2 + 45 * scale } fill="currentColor" fontSize={ `${16 * scale}px` } textAnchor="middle">{ showLabel(chart.split, chart.fromPeriod + i, LabelStyle.Year) }</text>}
          </g>
        );
      }
    }

    // Coordinates of the first data point
    let xFirst: number | undefined;
    let yFirst: number | undefined;

    // Last data point
    let xLast = 0;
    let yLast = 0;

    // We don't show circles if the data points are too close to each other.
    const showCircles = isDetailChart && (chart.values.length <= 1 || innerWidth / chart.values.length > 18 * scale);

    for (let i = 0; i < chart.values.length; i++) {
      const item = chart.values[i];

      if (item.value === undefined) {
        continue;
      }

      const x = x0 + innerWidth * (i + 0.5) / chart.values.length;
      const y = yBottom - toHeight(item.value);

      if (xFirst === undefined) {
        xFirst = x;
        yFirst = y;
      }
      xLast = x;
      yLast = y;

      if (showCircles) {
        points.push(<circle className="layout-color-light" key={ i } cx={ x } cy={ y } r={ 5 * scale } stroke="currentColor" strokeWidth={ 3 * scale } fill="white" />);
      } else {
        points.push(<circle className="layout-color-light" key={ i } cx={ x } cy={ y } r={ 1.5 * scale } strokeWidth={ 0 } fill="currentColor" />);
      }
      if (prevX !== undefined && prevY !== undefined) {
        lines.push(<line className="layout-color-light" key={ i } x1={ prevX } y1={ prevY } x2={ x } y2={ y } stroke="currentColor" strokeWidth={ 3 * scale } />);
      }
      path.push(`${ x },${ y }`);
      prevX = x;
      prevY = y;
    }

    if (xFirst !== undefined) {
      const offset = (!showCircles ? 1.5 : xFirst === xLast ? 5 : 2) * scale;
      path.push(`${ xLast + offset },${ yLast }`, `${ xLast + offset },${ yBottom }`, `${ xFirst - offset },${ yBottom }`, `${ xFirst - offset },${ yFirst }`);
    }
  }

  return (
    <svg viewBox={ `0 0 ${ w } 100 `} width={ width } height={ height }>
      { horizontalLines }
      { verticalLines }
      { norm }
      { path.length === 0 ? null : <polygon points={ path.join(' ') } fill="currentColor" className="layout-color-light" opacity=".4" /> }
      <line x1={ x0 } y1={ yBottom } x2={ x0 + innerWidth } y2={ yBottom } stroke="currentColor" strokeWidth={ scale * 3 } />
      { lines }
      { points }
    </svg>
  );

  function toHeight(value: number) {
    return Math.min(maxBarHeight, Math.max(0, maxBarHeight * (value - chart.min) / (chart.max - chart.min)));
  }
}

function showLabel(split: SourceSplitDate, period: number, style: LabelStyle) {
  if (split === "date-3-month") period *= 3;

  if (style === LabelStyle.Year) {
    const year = (period / 12) | 0;
    return (2000 + year).toString();
  } else if (style === LabelStyle.Quarter) {
    const quarter = (period / 3 | 0) % 4;
    return `Q${ 1 + quarter}`;
  } else if (style === LabelStyle.None) {
    return "";
  }
  const month = period % 12;
  const strings = style === LabelStyle.FullMonth ? t.dashboard.months.full : t.dashboard.months.short;
  return strings[month];
}
