// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

if (!(window as any).Symbol) {
  (window as any).Symbol = () => { throw new Error("Creating new symbols is not supported with this simple polyfill."); };
  (window as any).Symbol.iterator = "Symbol(Symbol.iterator)";
}

import * as dashboard from "./dashboard";
import * as menu from "./menu";
import * as exporter from "./export";
import { enableConditionalSelect } from "./utils/conditional-select";
import * as navigation from "./navigation/navigation";
import { ManageState } from "./dashboard/model";
import { tableHandler } from "./table";
import { actionPopupSelectPreviousRow, actionPopupSelectNextRow, actionPopupClose, actionPopupConfirm } from "./navigation/mobile";
import * as search from "./table/search";
import { enableDatePicker } from "./utils/form";
import { setLocale } from "./translations";

interface FrontendPage {
  unmount?(): void,
  resize?(): void,
}

const pages: { [type: string]: (el: HTMLElement) => FrontendPage } = {
  "manage-dashboard": (el) => {
    const manage: ManageState = {
      organisation: el.getAttribute("data-organisation")!,
      edit: el.getAttribute("data-edit") === "true",
      admin: el.getAttribute("data-admin") === "true",
      source: undefined,
      committedAction: undefined,
      tool: undefined,
    };
    return { unmount: dashboard.init(manage, el) };
  },
  table: tableHandler,
};

let hooksUnmount: (() => void)[] = [];
let hooksResize: (() => void)[] = [];

if (document.readyState !== "loading") {
  onLoad();
} else {
  document.addEventListener("DOMContentLoaded", onLoad);
}

window.addEventListener("resize", onResize);
document.body.addEventListener("keydown", onKeyDown);

function onLoad() {
  navigation.init();
  menu.mount();
  setLocale(menu.currentLocale());
  dashboard.init();
  onRenderElement(document.getElementById("main")!);
  navigation.onload(onRender, onRenderElement);
  onRender();
  exporter.initialize();
  search.onload();

  dashboard.resize();
}

// Called after initial load and when new pages are loaded with XHR
function onRender() {
  const $datepicker = $(".datepicker");
  enableDatePicker($datepicker);

  const isLoggedIn = menu.isLoggedIn();
  dashboard.setVisibility(location.pathname === "/" && isLoggedIn);
  dashboard.setAuth(isLoggedIn);

  // Unmount all previous components to prevent memory leaks
  for (const fn of hooksUnmount) fn();

  hooksUnmount = [];
  hooksResize = [];

  // Render new components
  const elements = document.body.querySelectorAll("[data-frontend]");
  for (let i = 0; i < elements.length; i++) {
    const el = elements[i];
    const page = pages[el.getAttribute("data-frontend")!];
    if (!page) continue;

    // Render component & register unmount and resize hooks
    const component = page(el as HTMLElement);
    if (component.unmount) hooksUnmount.push(component.unmount);
    if (component.resize) hooksResize.push(component.resize);
  }
  enableConditionalSelect();
}

function onRenderElement(element: HTMLElement) {
  const $element = $(element);
  ($element.find('[data-toggle="popover"]') as any).popover();
}

function onResize() {
  navigation.resize();
  dashboard.resize();
  for (const fn of hooksResize) fn();
}
function onKeyDown(e: KeyboardEvent) {
  let prevent = false;
  if (e.code === "ArrowUp") {
    prevent = actionPopupSelectPreviousRow();
  } else if (e.code === "ArrowDown") {
    prevent = actionPopupSelectNextRow();
  } else if (e.code === "Escape") {
    prevent = actionPopupClose();
  } else if (e.code === "Enter") {
    prevent = actionPopupConfirm();
  }

  if (prevent) e.preventDefault();
}
