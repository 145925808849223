// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

export function addInput(form: HTMLFormElement, name: string, value: string) {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = name;
  input.value = value;
  form.appendChild(input);
}

export function findParent(element: HTMLElement | null, tag: string) {
  while (element != null) {
    if (element.tagName === tag) {
      return element;
    }

    element = element.parentElement;
  }
  return undefined;
}
