import * as React from "react";
import * as colors from "./colors";
import { TrafficLightChart } from "../model";

enum Color {
  Green,
  Orange,
  Red,
  None,
}

interface Icon {
  icon: string,
  color: string,
}

const icons = {
  // glyphicon-ok-circle
  ok: { icon: "\uE089", color: colors.success },
  // glyphicon-question-sign
  warning: { icon: "\uE085", color: colors.warning },
  // glyphicon-alert
  danger: { icon: "\uE209", color: colors.danger },
};

export enum Orientation {
  Horizontal,
  Vertical,
}

export function TripletPicture({ element, width, height }: { element: (index: number, elementX: number, elementY: number, elementWidth: number, elementHeight: number, scale: number, orientation: Orientation) => JSX.Element, width: number, height: number }) {
  // Graph is generated as a `w * h` canvas and rendered as `width * height`.
  // The value of `w` and `h` are calculated such that these two rectangles have the same ratio,
  // and Math.min(w, h) = 100.
  let w: number;
  let h: number;
  let scale: number;
  if (width > height) {
    h = 100;
    scale = 100 / height;
    w = Math.round(width * scale);
  } else {
    w = 100;
    scale = 100 / width;
    h = Math.round(height * scale);
  }

  const cx = w / 2;
  const cy = h / 2;

  const orientation = w > h ? Orientation.Horizontal : Orientation.Vertical;

  return (
    <svg viewBox={ `0 0 ${ w } ${ h }` } width={ width } height={ height }>
      { renderElement(0) }
      { renderElement(2) }
      { renderElement(1) }
    </svg>
  );

  function renderElement(index: number) {
    let elementWidth = w;
    let elementHeight = h;
    let x = cx;
    let y = cy;
    if (w > h) {
      x += (index - 1) * 0.3 * w;
      elementWidth *= 0.3;
    } else {
      y += (index - 1) * 0.3 * h;
      elementHeight *= 0.3;
    }
    return element(index, x, y, elementWidth, elementHeight, scale, orientation);
  }
}

export function TrafficLightPicture({ chart, width, height }: { chart: TrafficLightChart, width: number, height: number }) {
  const color = getColor(chart.value, chart.transitionGreenOrange, chart.transitionOrangeRed);

  let sorted: Icon[];
  switch (color) {
    case Color.Green:
      sorted = [icons.warning, icons.ok, icons.danger];
      break;
    case Color.Red:
      sorted = [icons.ok, icons.danger, icons.warning];
      break;
    default:
      sorted = [icons.ok, icons.warning, icons.danger];
  }

  return <TripletPicture width={ width } height={ height } element={ element } />;

  function element(index: number, x: number, y: number, elementWidth: number, elementHeight: number, scale: number, orientation: Orientation) {
    const fontSize = orientation === Orientation.Horizontal
      ? Math.min(Math.max(elementWidth * 3.33, elementHeight) * 0.143, 35)
      : Math.min(Math.max(elementWidth, elementHeight * 3.33) * 0.143, 35);

    const icon = sorted[index];
    return <Light x={x} y={y} icon={icon} active={index == 1 && color !== Color.None} size={ fontSize } />;
  }
}

function Light({ x, y, icon, active, size }: { x: number, y: number, icon: Icon, active: boolean, size: number }) {
  if (active) size *= 2;
  return <text x={ x } y={ y } fill={ active ? icon.color : colors.lightgray } textAnchor="middle" dominantBaseline="middle" fontFamily="Glyphicons Halflings" fontSize={ size + "px" }>{ icon.icon }</text>;
}

function getColor(value: number | undefined, transitionGreenOrange: number, transitionOrangeRed: number) {
  if (value === undefined) {
    return Color.None;
  }

  if (transitionGreenOrange < transitionOrangeRed) {
    value = -value;
    transitionGreenOrange = -transitionGreenOrange;
    transitionOrangeRed = -transitionOrangeRed;
  }

  // This will now hold:
  // transitionGreenOrange > transitionOrangeRed
  // (so 'green > orange > red')

  if (value >= transitionGreenOrange) {
    return Color.Green;
  } else if (value >= transitionOrangeRed) {
    return Color.Orange;
  } else {
    return Color.Red;
  }
}
