
function char(str: string) {
  return str.charCodeAt(0);
}

const charSpace = char(" ");
const charNewline = char("\n");
const charCR = char("\r");
const charTab = char("\t");
const charDash = char("-");
const charDot = char(".");
const charAt = char("@");
const charComma = char(",");
const charExclamation = char("!");
const charSemicolon = char(";");
const charLeftParen = char("(");
const charRightParen = char(")");

export interface Completer {
  index(text: string): void,
  query(text: string): Set<string> | undefined,
}

export function createCompleter(): Completer {
  const map: Map<string, Set<string>> = new Map();

  return { index, query };

  function query(text: string) {
    return map.get(normalize(text));
  }

  function index(text: string) {
    forEachWord(text, (word, start, end) => {
      const length = end - start;
      for (let i = 0; i < length; i++) {
        const minLength = i === 0 ? 1 : 4;
        for (let j = i + minLength; j <= length; j++) {
          const substring = normalize(word.substring(i, j));
          const set = map.get(substring);
          if (set === undefined) {
            map.set(substring, new Set([word]));
          } else {
            set.add(word);
          }
        }
      }
    });
  }
}

function normalize(str: string) {
  return str.toLowerCase();
}

export function characterEndsWord(chr: number) {
  switch (chr) {
    case charSpace:
    case charNewline:
    case charCR:
    case charTab:
    case charDash:
    case charDot:
    case charAt:
    case charComma:
    case charExclamation:
    case charSemicolon:
    case charLeftParen:
    case charRightParen:
      return true;
  }
  return false;
}

export function forEachWord(text: string, callback: (word: string, start: number, end: number) => void) {
  let start = 0;
  let end = 0;
  while (true) {
    if (end === text.length) {
      onWord();
      return;
    }
    const chr = text.charCodeAt(end);
    if (characterEndsWord(chr)) onWord();
    end++;
  }

  function onWord() {
    const word = text.substring(start, end);
    callback(word, start, end);
    start = end + 1;
  }
}
