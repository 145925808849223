import { TextTranslations } from "../interface";

export const en: TextTranslations = {
  menu: {
    menu: "Menu",
    logout: "Log out",
    copyright: "Copyright",
  },
  actions: {
    ok: "Ok",
    back: "Back",
    close: "Close",
    show: "Show",
    save: "Save",
    cancel: "Cancel",
    select: "Select",
    edit: "Edit",
    delete: "Delete",
  },
  notices: {
    loading: "Loading...",
    connecting: "Connecting...",
    no_connection: "No connection",
    change_is_being_applied: "The change is being applied...",
    dashboard_no_permission: "You don't have permission (yet) to view the dashboard",
    dashboard_manage_no_dashboard: "Your organisation doesn't (yet) have a dashboard. Click 'edit' to create a dashboard.",
  },
  languages: {
    en: "English",
    nl: "Dutch",
  },
  dashboard: {
    time_period: {
      short: {
        last_months: {
          12: "12 months",
          6: "6 months",
          3: "3 months",
        },
        custom: "Custom",
      },
      full: {
        last_months: {
          12: "Last 12 months",
          6: "Last 6 months",
          3: "Last 3 months",
        },
        custom: "Custom",
      },
    },
    months: {
      short: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
      full: ["Janunary", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    },
    item: {
      trend: "Trend",
      benchmark: "Benchmark",
      norm: "Norm",
      sources: "Sources",
      redflags: "Red flags",
      no_data: "No data available",
    },
    chart_type: {
      number: "Number",
      traffic_light: "Traffic light",
      bar: "Bar chart",
      trend: "Trend chart",
    },
    chart_date_period: {
      month: "Month",
      quarter: "Quarter",
      year: "Year",
    },
    personalize: {
      title: "Personalize",
      description: "Drag the items to change their order. An item can be dragged by clicking or tapping on it, holding the mousebutton or finger attached and moving it to a new place",
      delete: "Remove all my preferences",
    },
    filters: {
      filters: "Filters",
      no_filters: "No filters can be set. If the dashboard contains data of multiple locations, services or organisations, you can choose filters here.",
      no_options_available: "No options available",
      client: "Client",
      supplier: "Supplier",
      service: "Service",
      location: "Location",
      category: "Audit category",
      remove: "Remove filters",
    },
    sources: {
      audit: "Audit",
      at: "GDP",
      location: "Location",
      date: "Date",
      score: "Score",
      audit_one: "1 audit",
      audits: "audits",
      at_one: "1 GDP",
      ats: "GDPs",
      no_data: "There is no data available, satisfying the given filters",
      go_to_measurement: "Go to measurement",
      audit_type: "Kind",
      audit_no_rejected_requirements: "No rejected requirements.",
      audit_rejected_requirements: "Rejected requirements",
      audit_weight: "Weight",

      at_questions: "GDP questions",
      at_value: "Value",
      at_show_history: "Show history",

      loading_more_data: "More data is being loaded...",
      no_permission_audit: "You don't have permission to open this audit.",
      no_permission_at: "You don't have permission to open this audit.",
    },
    redflags: {
      critical_requirement_one: "1 critical requirement was rejected",
      critical_requirements: "critical requirements were rejected",
      critical_requirements_none: "No critical requirements were rejected",
      locations: "Locations",
      requirement: "Requirement",
      count_red_flags: "Number of red flags",
      loading_more_data: "More data is being loaded...",
      no_permission_one: "This requirement was rejected in one measurement, for which you have no permissions.",
      no_permission_multiple: "This requirement was rejected in measurements, for which you have no permissions.",
    },
  },
  dashboard_manage: {
    actions: {
      edit_motto: "Edit motto",
      edit_title: "Edit title of item",
      edit_filters: "Edit filters for source of item",
      edit_unit_weights: "Edit unit and weights of nested items",
      edit_at_formula: "Edit GDP-formula of item",
      edit_charts: "Edit charts of item",
      edit_norm_trend: "Edit norm and trend",
      delete_item: "Delete current item",
      set_benchmark: "Configure benchmark",
      add_item: "Add item",
      directory_layout_fixed: "Layout: same size for all items",
      directory_layout_fluent: "Layout: dynamic sizes",
    },
    tools: {
      title: {
        caption: "Edit title",
      },
      add_item: {
        caption: "Add item",
        description: "Choose the source for this item:",
        directory: "Directory",
        audit: "Audit",
        at: "GDP",
      },
      edit_weights: {
        caption: "Edit weights of items in this directory",
        unit: "Unit",
        decimals: "Decimals",
      },
      charts: {
        caption: "Manage charts",
        first_as_default: "Use standard chart as first detail chart",
        no_detail_chart: "Don't show a detail chart",
      },
      edit_chart: {
        caption: "Chart",
        min: "Minimum",
        max: "Maximum",
        transition_green_orange: "Transition green-orange",
        transition_orange_red: "Transition orange-red",
        chart_type: "Chart type",
        split_on: "Split on",
        info: "Decimal number should be written with a dot, like 7.5.",
      },
      edit_filters: {
        item_caption: "Edit sources for item",
        benchmark_caption: "Edit filters for benchmark",
        all: "all",
        selected: "selected",
        choose_filters_for: "Configure filters for",
        info_no_selection: "If no options are selected, no filter is applied.",
      },
      edit_formula: {
        caption: "Configure GDP-formula for item",
        expression_may_contain: "The expression may consist of:",
        expression_number: "Numbers",
        expression_questions: "GDP question (example: A4)",
        expression_arithmetics: "Sum, product, division and subtraction (examples: A1 - 1, 2 * B3)",
        expression_parentheses: "Parentheses (example: (A1 + 3) * 2)",
        valid_expression: "Valid expression",
        parse_error: "The formula could not be read",
        expected_on_position_left: "On position",
        expected_on_position_right: "was expected:",
        eof: "End of input",
      },
      edit_norm_trend: {
        norm_caption: "Norm",
        trend_caption: "Trend",
        trend_description: "The trend is based on the value of the current period and the previous period. This period can be set to a month, quarter or year.",
        no_trend: "Don't show the trend",
      },
      set_benchmark: {
        caption: "Configure benchmark",
        no_benchmark: "No benchmark has been configured yet.",
        add_benchmark_source: "Add additional source to benchmark",
        add_audit_source: "Add audit source",
        add_at_source: "Add GDP source",
        add_constant_source: "Add constant value",
        audit: "Audit",
        at: "GDP",
        constant: "Constant value",
        edit_weight_period: "Edit weight and period",
        edit_filters: "Edit filters",
        edit_at_formula: "Edit GDP formula",
      },
      edit_benchmark_item: {
        caption: "Edit benchmark",
        value: "Value",
        weight: "Weight",
        period: "Period",
      },
    },
  },
  export: {},
  search: {
    date: {
      min: "Between",
      max: "and",
      from: "From",
      till: "Till",
    },
  },
};
