// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import * as React from "react";
import { pickadateConfig } from "../config";
import { supportsDateInput } from "./form";

export function Glyphicon({ icon }: { icon: string }) {
  return <i className={ `glyphicon glyphicon-${ icon }` }></i>;
}

export function CsrfForm({ method, csrf, children, ...rest }: { method: string, csrf: { param: string, token: string }, children?: {} } & JSX.IntrinsicElements["form"]) {
  return (
    <form method="post" {...rest}>
      <input type="hidden" name="_method" value={method} />
      <input type="hidden" name={csrf.param} value={csrf.token} />
      { children }
    </form>
  );
}

export function Input({ id, label, type, value, onChange, onBlur, }: { id: string, label: string, type?: string, value?: string, onChange: (value: string) => void, onBlur?: () => void, }) {
  return <div className="form-group">
    <label className="control-label" htmlFor={ id }>{ label }</label>
    <input
      className="form-control"
      id={ id }
      type={ type }
      value={ value }
      onChange={ (e) => onChange(e.target.value) }
      onBlur={ (e) => {
        onChange((e.target as EventTarget & HTMLInputElement).value);
        if (onBlur !== undefined) onBlur();
      } } />
  </div>;
}

function padStart(source: string, length: number, char: string) {
  while (source.length < length) {
    source = char + source;
  }
  return source;
}

export class DatePicker extends React.Component<{ value: Date, onChange: (value: Date | null) => void }, {}> {
  element: HTMLInputElement | null = null;
  old: Date;
  picker: any = null;

  render() {
    const year = padStart(this.props.value.getFullYear().toString(), 4, "0");
    const month = padStart((this.props.value.getMonth() + 1).toString(), 2, "0");
    const date = padStart(this.props.value.getDate().toString(), 2, "0");
    const value = `${ year }-${ month }-${ date }`;
    if (supportsDateInput) {
      return <input ref={ (element) => this.element = element } type="date" value={value} />;
    } else {
      return <input ref={ (element) => this.element = element } data-value={ value } />;
    }
  }

  private initializePickadate() {
    if (this.element === null || supportsDateInput) return;

    if (this.picker !== null) {
      if (+this.old !== +this.props.value) {
        this.old = this.props.value;
        this.picker.set("select", this.props.value);
      }
      return;
    }

    this.old = this.props.value;

    this.picker = ($(this.element) as any).pickadate({
      ...pickadateConfig,
      onSet: (value: any) => {
        if (value.select != undefined) {
          this.props.onChange(new Date(value.select));
        }
      },
      container: document.getElementById('pickadate-container'),
    }).pickadate('picker');
  }

  componentDidUpdate() {
    this.initializePickadate();
  }

  componentDidMount() {
    this.initializePickadate();
    this.element!.addEventListener("input", () => this.props.onChange(this.element!.valueAsDate));
  }

  componentWillUnmount() {
    if (this.picker) this.picker.stop();
  }
}

export function Checkbox({ label, checked, onChange }: { label: string, checked?: boolean, onChange: (checked: boolean) => void }) {
  return <div className="checkbox">
    <label>
      <input type="checkbox" checked={ checked } onChange={ (e) => onChange(e.target.checked) } />
      { " " + label }
    </label>
  </div>;
}

export function Select<T>({ name, label, options, selected, onChange, dropdown }: { name: string, label: string, options: { text: string, value: T }[], selected: T, onChange: (value: T) => void, dropdown?: boolean }) {
  if (dropdown) {
    const $options = [];
    let selectedIndex = 0;
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.value === selected) {
        selectedIndex = i;
      }
      $options.push(<option key={ i } value={ i }>{ option.text }</option>);
    }
    return <div className="form-group">
      <label className="control-label" htmlFor={ name }>{ label }</label>
      <select className="form-control" id={ name } value={ selectedIndex } onChange={ (e) => onChange(options[+e.target.value].value) }>
        { $options }
      </select>
    </div>;
  }

  return <div className="form-group">
    <label className="control-label">{ label }</label>
    {
      options.map(({ text, value}, index) => <div className="radio">
          <label>
            <input name={ name } type="radio" onChange={ (e) => { if (e.target.checked) { onChange(value) } } } checked={ selected === value } />
            { text }
          </label>
        </div>)
    }
  </div>;
}

