// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import * as React from "react";
import { Dispatch } from "../utils/state";
import { State, Item, Visibility, SocketState, Root, NoDashboardReason, Tool, layout } from "./model";
import { renderPage } from "./view/page";
import { ManageOverlay } from "./view/manage";
import { SourceDetails } from "./view/sourcedetails";
import { RedFlagDetails } from "./view/redflagdetails";
import { t } from "../translations";

export const render = (dispatch: Dispatch<State>) => (state: State) => {
  if (state.visibility === Visibility.Uninitialized) {
    return <div></div>;
  }

  let statusMessage: JSX.Element | undefined;

  if (state.socketState !== SocketState.Open) {
    statusMessage = <div className="alert alert-info">{ state.socketState === SocketState.Connecting ? t.notices.connecting : t.notices.no_connection }</div>;
  } else if (state.dashboard.type == "no-dashboard" && state.dashboard.reason === NoDashboardReason.NoPermission) {
    statusMessage = <div className="alert alert-info">{ "U heeft (nog) geen rechten om het dashboard te bekijken." }</div>;
  } else if (state.dashboard.type == "no-dashboard" && state.dashboard.reason === NoDashboardReason.NotFound && state.manage !== undefined) {
    statusMessage = <div className="alert alert-info">{ "Uw organisatie heeft (nog) geen dashboard. Druk op 'bewerk' om een dashboard aan te maken." }</div>;
  }

  const dashboard = state.dashboard;

  let className = "dashboard";

  if (state.visibility === Visibility.Hidden) {
    className += " dashboard-hidden";
  }
  if (state.path.length === 0) {
    className += " dashboard-root";
  }

  let pages;

  let activeItem: Item | undefined = undefined;

  let maxHeight = 0;
  if (dashboard.type === "dashboard") {
    pages = [];
    let queue: (Root | Item)[] = [dashboard.root];

    for (const item of queue) {
      const { element, height } = renderPage({ state, page: item, dispatch });
      pages.push(element);
      if (height > maxHeight) maxHeight = height;

      if (item.type === "item" && state.path[state.path.length - 1] === item.id) {
        activeItem = item;
      }

      if (item.items !== undefined) {
        queue.push(...item.items.map(child => dashboard.items[child]!));
      }
    }
  } else {
    maxHeight = 100;
    pages = <div></div>;
  }

  const moreContent = state.activeTool === Tool.Source || state.activeTool === Tool.RedFlags;

  return (
    <div className={ className } style={{ height: `${ maxHeight }px` }}>
      { !moreContent || activeItem === undefined ? null :
        <div className="dashboard-page-more-content" style={{ top: (layout.topBarHeightWithBreadcrumbs - layout.topBarHeightWithoutBreadcrumbs + layout.interfaceTopHeight + layout.smallDetailGraphHeight) + "px" }}>
          { state.activeTool === Tool.Source && <SourceDetails page={ activeItem } state={ state } details={ state.sourceDetails } unit={ activeItem.chart.unit } dispatch={ dispatch } /> }
          { state.activeTool === Tool.RedFlags && <RedFlagDetails state={ state } details={ state.redFlagDetails } dispatch={ dispatch } /> }
        </div>
      }
      { pages }
      <ManageOverlay state={ state } dispatch={ dispatch } />
      { statusMessage }
    </div>
  );
};
