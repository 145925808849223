import { enableDatePicker, supportsDateInput } from "../utils/form";
import { t } from "../translations";
import { formatDateShort } from "../utils/date";
import { isIE } from "../navigation/navigation";
import { createCompleter, Completer, characterEndsWord } from "./search-completions";
import { createRowIndexer, RowIndexer, RowSearchDelegate, RowSearcher } from "./search-rows";
import { createDateSearchIndexer, DateSearchIndexers } from "./search-date";

type ColumnSearchKind = "input" | "date" | undefined;

interface Column {
  index: number,
  kind: ColumnSearchKind,
  element: HTMLDivElement,
  input: HTMLInputElement,
  inputDates: [HTMLInputElement, HTMLInputElement] | undefined,
  inputDateLabels: [HTMLLabelElement, HTMLLabelElement] | undefined,
  dates: [Date | undefined, Date | undefined] | undefined,
  elementCurrentQuery: HTMLElement,
  x: number,
  width: number,
}

export let onStreamingNewRow: (() => void) | undefined;

let close: (() => void) | undefined;

const completionCount = 20;

export function onload() {
  document.getElementById("table-search-background")!.addEventListener("click", () => {
    if (close) close();
  });
}

enum IndexState {
  Done,
  Busy,
  AwaitNextRow,
  Cancelled,
}

export function tableSearchHandler(table: HTMLTableElement, header: HTMLTableRowElement, visible: boolean[]) {
  if (isIE) return { unmount: () => { /* ignore */ } };

  for (let i = 0; i < header.children.length; i++) {
    const th = header.children[i];
    th.addEventListener("click", () => {
      open(i === header.children.length - 1 ? undefined : i);
    });
  }

  const searchContainer = document.createElement("div");
  let isOpen = false;
  let isForm = false;
  setClassName();

  const columns: Column[] = [];
  const elementColumns = document.createElement("div");
  elementColumns.className = "table-search-columns";
  searchContainer.appendChild(elementColumns);

  const elementCompletions = document.createElement("div");
  elementCompletions.className = "table-search-completions";
  searchContainer.appendChild(elementCompletions);

  const elementDatePickerContainer = document.createElement("div");
  elementDatePickerContainer.className = "table-search-pickadate-container";
  searchContainer.appendChild(elementDatePickerContainer);

  const completers: Completer[] = [];
  let activeIndex = 0;

  // Indexing progress
  let indexCurrentRow = 0;
  let indexCurrentColumn = 0;
  let indexState = IndexState.Busy;
  const body = table.tBodies[table.tBodies.length - 1];

  const indexPopupElement = document.createElement("div");
  const dateIndexers: DateSearchIndexers = {};

  for (let i = 0; i < header.children.length - 1; i++) {
    completers[i] = createCompleter();

    const column = document.createElement("div");
    column.className = "table-search-column";
    elementColumns.appendChild(column);

    const title = document.createElement("div");
    title.className = "table-search-column-title";
    title.textContent = header.children[i].textContent;

    const kind = (header.children[i].getAttribute("data-search-kind") || undefined) as ColumnSearchKind;
    if (kind !== undefined) column.className += " table-search-column-kind-" + kind;

    const input = document.createElement("input");
    input.className = "form-control";
    input.tabIndex = i * 3 + 1;
    column.appendChild(title);
    column.appendChild(input);

    let inputDates: [HTMLInputElement, HTMLInputElement] | undefined;
    let inputDateLabels: [HTMLLabelElement, HTMLLabelElement] | undefined;
    let dates: [Date | undefined, Date | undefined] | undefined;

    if (kind === "date") {
      dateIndexers[i] = createDateSearchIndexer();
      inputDates = [
        document.createElement("input"),
        document.createElement("input"),
      ];
      inputDateLabels = [
        document.createElement("label"),
        document.createElement("label"),
      ];
      inputDateLabels[0].textContent = t.search.date.min;
      inputDateLabels[1].textContent = t.search.date.max;
      dates = [
        undefined,
        undefined,
      ];
      if (supportsDateInput) {
        inputDateLabels[0].addEventListener("click", () => inputDates![0].focus());
        inputDateLabels[1].addEventListener("click", () => inputDates![1].focus());
      } else {
        inputDateLabels[0].addEventListener("click", () => {
          window.setTimeout(() => inputDates![0].click());
        });
        inputDateLabels[1].addEventListener("click", () => {
          window.setTimeout(() => inputDates![1].click());
        });
      }

      inputDates[0].className = "form-control";
      inputDates[1].className = "form-control";
      inputDates[0].tabIndex = i * 3 + 2;
      inputDates[1].tabIndex = i * 3 + 3;

      inputDates[0].addEventListener("input", onInputDate);
      inputDates[1].addEventListener("input", onInputDate);
      inputDates[0].addEventListener("change", onInputDate);
      inputDates[1].addEventListener("change", onInputDate);

      elementDatePickerContainer.addEventListener("click", onInputDate);

      if (supportsDateInput) {
        inputDates[0].type = "date";
        inputDates[1].type = "date";
      } else {
        // Use pickadate as a polyfill
        for (let j = 0; j < 2; j++) {
          enableDatePicker($(inputDates[j]), {
            container: elementDatePickerContainer,
            onSet: ({ select, clear }: { select?: number, clear?: null }) => {
              if (select !== undefined) {
                dates![j] = new Date(select);
              } else if (clear === null) {
                dates![j] = undefined;
              }
              onInputDate();
            },
          });
        }
      }
    } else if (kind === "input") {
      isForm = true;
      input.disabled = true;
    } else {
      input.autocomplete = "off";
      input.addEventListener("keypress", keyPress);
      input.addEventListener("keydown", keyDown);
      input.addEventListener("input", onInput);
    }

    title.addEventListener("click", () => input.focus());
    input.addEventListener("focus", () => focus(i));

    const elementCurrentQuery = document.createElement("div");
    elementCurrentQuery.className = "table-search-current-query";
    header.children[i].appendChild(elementCurrentQuery);

    columns.push({ index: i, element: column, kind, input, inputDates, inputDateLabels, dates, elementCurrentQuery, x: 0, width: 0 });
  }

  let currentVisible: number[] = [];
  let currentSelectedCount = 0;
  const rowSearchDelegate: RowSearchDelegate = {
    reset(isSearching) {
      body.className = isSearching ? "table-search-searching" : "";
      for (const row of currentVisible) {
        body.rows[row].style.display = "";
      }
      currentVisible = [];
      currentSelectedCount = 0;
      setSummary(0);
    },
    result(rowIndex) {
      const row = body.rows[rowIndex];
      row.style.display = "table-row";
      currentVisible.push(rowIndex);
      if (isForm) {
        const input = row.cells[0].children[0] as HTMLInputElement;
        if (input != null && input.checked) currentSelectedCount ++;
      }
    },
    finish() {
      const selection = table.querySelectorAll("input[type=\"checkbox\"]:checked");
      setSummary(selection.length - currentSelectedCount);
    },
  };

  let rowIndexer: RowIndexer | undefined = createRowIndexer(completers, dateIndexers, rowSearchDelegate);
  let rowSearcher: RowSearcher | undefined;

  const elementsCompletions: HTMLDivElement[] = [];
  const completions: string[] = [];
  for (let i = 0; i < completionCount; i++) {
    elementsCompletions[i] = document.createElement("div");
    elementCompletions.appendChild(elementsCompletions[i]);
    completions[i] = "";

    elementsCompletions[i].addEventListener("click", () => {
      applyCompletion(columns[activeIndex].input, completions[i]);
      columns[activeIndex].input.focus();
      onInput();
    });
  }
  let selectedCompletion = 0;
  let visibleCompletions = 0;

  let executeSearchTimeout: number | undefined;
  scheduleIndexing();

  table.insertAdjacentElement("beforebegin", searchContainer);

  return { unmount };

  function open(selectedIndex?: number) {
    document.getElementById("table-search-background")!.style.display = "block";
    isOpen = true;
    setClassName();

    if (selectedIndex !== undefined && columns[selectedIndex].kind === "input") selectedIndex++;

    elementColumns.innerHTML = "";
    let x = 0;
    for (const column of columns) {
      if (visible[column.index]) {
        if (selectedIndex === undefined && column.kind !== "input") selectedIndex = column.index;
        elementColumns.appendChild(column.element);
        const width = header.children[column.index].clientWidth;
        column.element.style.width = `${ width }px`;
        column.x = x;
        column.width = width;
        x += width;
      }
    }

    for (const column of columns) {
      if (!visible[column.index]) {
        elementColumns.appendChild(column.element);
        const width = parseInt(header.children[column.index].getAttribute("data-width")!, 10);
        column.element.style.width = `${ width }px`;
        column.x = x;
        column.width = width;
        x += width;
      }
    }

    if (selectedIndex !== undefined) {
      columns[selectedIndex].input.focus();
    }

    close = () => {
      document.getElementById("table-search-background")!.style.display = "none";
      isOpen = false;
      setClassName();
    };
  }

  function setClassName() {
    let className = "table-search";
    if (isOpen) {
      className += " table-search-open";
    }
    searchContainer.className = className;
  }

  function focus(index: number) {
    const column = columns[index];
    if (column === undefined) return;
    if (column.kind === "input") {
      // Column is disabled
      focus(index + 1);
      return;
    }

    const x2 = column.x + column.width;
    let scroll = 0;
    const tableWidth = table.parentElement!.getBoundingClientRect().width;
    if (x2 >= tableWidth) {
      scroll = Math.min(
        column.x + (column.width - tableWidth) / 2
      );
    }
    if (elementColumns.scrollTo) {
      elementColumns.scrollTo(scroll, 0);
    } else {
      elementColumns.scrollLeft = scroll;
    }

    elementCompletions.style.left = `${ column.x - elementColumns.scrollLeft - 1 }px`;
    elementCompletions.style.width = `${ column.width + 2 }px`;

    activeIndex = index;
    column.input.selectionStart = column.input.value.length;

    if (elementCompletions.children[1] != null && elementCompletions.children[1].tagName === "INPUT") {
      // Previous selected column was a date column. Remove two date inputs with their labels.
      elementCompletions.firstElementChild!.remove();
      elementCompletions.firstElementChild!.remove();
      elementCompletions.firstElementChild!.remove();
      elementCompletions.firstElementChild!.remove();
    }

    if (column.kind === "date") {
      elementCompletions.prepend(column.inputDateLabels![0], column.inputDates![0], column.inputDateLabels![1], column.inputDates![1]);
      elementCompletions.style.height = `114px`;
      if (supportsDateInput) {
        column.inputDates![0].focus();
      } else {
        column.input.blur();
      }
      return;
    }

    onInput();
  }

  function keyPress(e: KeyboardEvent) {
    if (e.key === "Enter") {
      if (visibleCompletions !== 0 && indexState === IndexState.Done) {
        applyCompletion(columns[activeIndex].input, completions[selectedCompletion]);
        onInput();
      } else if (close !== undefined) {
        close();
      }
      e.preventDefault();
    }
  }

  function keyDown(e: KeyboardEvent) {
    if (e.key === "ArrowUp") {
      if (selectedCompletion !== 0) {
        setSelectedCompletion(selectedCompletion - 1);
      }
      e.preventDefault();
    } else if (e.key === "ArrowDown") {
      if (selectedCompletion !== visibleCompletions - 1) {
        setSelectedCompletion(selectedCompletion + 1);
      }
      e.preventDefault();
    }
  }

  function onInput() {
    const column = columns[activeIndex];
    if (column === undefined || column.kind === "date") return;

    const [word] = cursorWordRange(column.input);
    const set = indexState === IndexState.Done ? completers[activeIndex].query(word) : new Set(["..."]);

    let i = 0;
    if (set) {
      for (const completion of set) {
        elementsCompletions[i].textContent = completion;
        completions[i] = completion;
        i++;
        if (i >= completionCount) break;
      }
    }
    elementCompletions.style.height = `${ i * 32 + 8 }px`;
    visibleCompletions = i;
    setSelectedCompletion(0);

    scheduleSearch();

    column.elementCurrentQuery.textContent = column.input.value;
    let className = "table-search-current-query";
    if (column.input.value) {
      className += " table-search-current-query-visible";
    }
    column.elementCurrentQuery.className = className;
  }

  function onInputDate() {
    const column = columns[activeIndex];
    if (column === undefined || column.kind !== "date") return;
    let from: Date | undefined;
    let to: Date | undefined;
    if (supportsDateInput) {
      from = column.inputDates![0].valueAsDate || undefined;
      to = column.inputDates![1].valueAsDate || undefined;
      from = from == null ? undefined : new Date(from);
      to = to == null ? undefined : new Date(to);
      if (from) from.setMinutes(from.getMinutes() + from.getTimezoneOffset());
      if (to) to.setMinutes(to.getMinutes() + to.getTimezoneOffset());
      column.dates![0] = from;
      column.dates![1] = to;
    } else {
      [from, to] = column.dates!;
    }

    let summary;
    if (from !== undefined && to !== undefined) {
      summary = formatDateShort(from) + " - " + formatDateShort(to);
    } else if (to !== undefined) {
      summary = t.search.date.till + " " + formatDateShort(to);
    } else if (from !== undefined) {
      summary = t.search.date.from + " " + formatDateShort(from);
    } else {
      summary = "";
    }

    column.input.value = summary;
    column.elementCurrentQuery.textContent = summary;
    let className = "table-search-current-query";
    if (summary) {
      className += " table-search-current-query-visible";
    }
    column.elementCurrentQuery.className = className;

    scheduleSearch();
  }

  function scheduleSearch() {
    if (executeSearchTimeout !== undefined) window.clearTimeout(executeSearchTimeout);
    executeSearchTimeout = window.setTimeout(executeSearch, 20);
  }

  function setSelectedCompletion(select: number) {
    elementsCompletions[selectedCompletion].className = "";
    selectedCompletion = select;
    elementsCompletions[select].className = "selected";
  }

  function unmount() {
    if (close) close();
    indexState = IndexState.Cancelled;
    rowIndexer = undefined;
    rowSearcher = undefined;
    if (onStreamingNewRow === streamingRowCallback) {
      onStreamingNewRow = undefined;
    }
  }

  function executeSearch() {
    if (rowSearcher !== undefined) {
      const queries = [];
      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        if (column.kind === "date") {
          queries[i] = column.dates;
        } else {
          queries[i] = column.input.value;
        }
      }
      rowSearcher.search(queries);
    }
  }

  function streamingRowCallback() {
    onStreamingNewRow = undefined;
    indexState = IndexState.Busy;
    scheduleIndexing();
  }
  function scheduleIndexing() {
    if ((window as any).requestIdleCallback) {
      scheduleIndexingLegacy(); // TODO: Use requestIdleCallback for scheduling
    } else {
      scheduleIndexingLegacy();
    }
  }
  function scheduleIndexingLegacy() {
    window.requestAnimationFrame(() => {
      const end = Date.now() + 7;
      while (Date.now() <= end && indexState === IndexState.Busy) {
        indexNextCell();
      }
      if (indexState === IndexState.Done) {
        onInput();
      }
      if (indexState === IndexState.Busy) {
        scheduleIndexingLegacy();
      }
      if (indexState === IndexState.AwaitNextRow) {
        onStreamingNewRow = streamingRowCallback;
      }
    });
  }

  function indexNextCell() {
    const tr = body.rows[indexCurrentRow];
    if (tr == null) {
      indexState = IndexState.Done;
      rowSearcher = rowIndexer!.finish();
      rowIndexer = undefined;
      return;
    } else if (tr.id === "content-streaming") {
      indexState = IndexState.AwaitNextRow;
      return;
    }

    const classes = tr.cells[0].className.split(" ");

    if (classes.indexOf("td-full-width") !== -1 || classes.indexOf("table-select-highlight-row") !== -1) {
      indexCurrentRow++;
      indexCurrentColumn = 0;
      return;
    }
    const cell = tr.cells[indexCurrentColumn];
    const dateIndexer = rowIndexer!.dateIndexers[indexCurrentColumn];
    if (dateIndexer !== undefined) {
      const date = cell.getAttribute("data-date");
      if (date !== null) dateIndexer.index(indexCurrentRow, date);
    } else {
      for (let i = 0; i < cell.childNodes.length; i++) {
        const node = cell.childNodes[i];
        let elements;
        if (node.nodeType === Node.TEXT_NODE) {
          elements = [node];
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as HTMLElement;
          if (element.getAttribute("data-html") === "true") {
            // Popup
            indexPopupElement.innerHTML = element.getAttribute("data-content") || "";
            elements = indexPopupElement.childNodes;
          } else {
            elements = element.childNodes;
          }
        }

        if (!elements) return;
        for (let j = 0; j < elements.length; j++) {
          const text = elements[j].textContent;
          if (text) {
            completers[indexCurrentColumn].index(text);
            rowIndexer!.index(indexCurrentRow, indexCurrentColumn, text);
          }
        }
      }
    }

    indexCurrentColumn++;
    if (indexCurrentColumn >= header.children.length - 1) {
      // Go to next row
      indexCurrentRow++;
      indexCurrentColumn = 0;
    }
  }

  function setSummary(count: number) {
    const elementSummary = body.rows[body.rows.length - 1];

    if (elementSummary.className !== "form-table-select-summary") return;

    elementSummary.style.display = count === 0 ? "" : "table-row";
    elementSummary.cells[0].textContent = count === 1 ? "Een geselecteerde rij is verborgen." : `${count} geselecteerde rijen zijn verborgen.`;
  }
}

function cursorWordRange(input: HTMLInputElement): [string, number, number] {
  const value = input.value;
  let cursor = input.selectionEnd;
  if (cursor === null) cursor = value.length;
  let start = cursor;
  let end = cursor;

  while (start > 0 && !characterEndsWord(value.charCodeAt(start - 1))) {
    start--;
  }
  while (end < value.length && !characterEndsWord(value.charCodeAt(end))) {
    end++;
  }
  const word = value.substring(start, end);
  return [word, start, end];
}

function applyCompletion(input: HTMLInputElement, completion: string) {
  const value = input.value;
  const [word, start, end] = cursorWordRange(input);
  let newValue = value.substring(0, start) + completion + value.substring(end);
  if (end === value.length) newValue += " ";
  input.value = newValue;
}
