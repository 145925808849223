// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import * as ReactDom from "react-dom";
import * as navigation from "../navigation/navigation";
import * as menu from "../menu";
import { host, Dispatch } from "../utils/state";
import * as array from "../utils/array";
import { translate } from "../translations/get";
import { State, Visibility, SocketState, Root, Item, setFilterOptionsFor, filtersToString, getItem, Tool, NoDashboardReason, Dashboard, getItemFromDashboard, ManageState, filtersVisible, UserFilters, emptyFilterOptions, FilterOptionsState, FilterOptionState, userFilterFields, SourceDetailsState, RedFlagDetailsState, filtersWithout, restrictDashboard } from "./model";
import * as store from "./store";
import * as protocol from "./protocol";
import { render } from "./view";
import * as action from "./action";
import { createSocket, Socket } from "./socket";
import { ManageInfo } from "./protocol";
import { errorMessages } from "./model/error";
import { personalize } from "./model/personalize";
import { emptyItemLayout } from "./model/layout";

let nextFilterRequestId = 0;

const initialFilters: UserFilters = { period: { type: "last-months", months: 12 }, fields: {} };
const initialState: Pick<State, Exclude<keyof State, "container">> = {
  manage: undefined,
  visibility: Visibility.Uninitialized,
  socketState: SocketState.Closed,
  dashboard: { type: "no-dashboard", reason: NoDashboardReason.Loading },
  activeTool: undefined,
  sourceDetails: undefined,
  redFlagDetails: undefined,
  path: [],
  rootLayout: emptyItemLayout,
  itemLayouts: {},
  filters: {
    current: initialFilters,
    string: filtersToString(initialFilters),
    optionKeys: "uninitialized",
    options: emptyFilterOptions(undefined),
    submenu: undefined,
  },
  drag: undefined,
  showHiddenItems: false,
  width: 1,
  windowHeight: 1,
  user: {
    preferences: {
      order: [],
      hidden: [],
    },
    shouldPush: false,
  },
};

// Used to remove the dashboard from the state
const resetStatePartial: Partial<State> = {
  dashboard: initialState.dashboard,
  activeTool: undefined,
  sourceDetails: undefined,
  path: [],
  rootLayout: emptyItemLayout,
  itemLayouts: {},
  filters: initialState.filters,
};

let dispatch: Dispatch<State>;
let otherDispatch: Dispatch<State> | undefined;
let getState: () => State;

let globalSocket: Socket;
let baseFilters: string | undefined;

export function resize() {
  if (!dispatch) return;
  const width = document.getElementById("dashboard-container")!.clientWidth;
  const windowHeight = window.innerHeight;
  dispatch(action.resize(width, windowHeight));
  if (otherDispatch) {
    otherDispatch(action.resize(width, windowHeight));
  }
}

// Initializes the dashboard. Creates the React app and websocket.
// Manages the stores containing the data for the graphs.
export function init(initManage?: ManageState, element: HTMLElement = document.getElementById("dashboard-container")!) {
  const manageInfo: ManageInfo | null = initManage === undefined ? null : {
    organisation: initManage.organisation,
    draft: initManage.edit,
  };

  const app = host<State>(element, { ...initialState, container: element }, render, onChange);
  const socket = createSocket({ changeSocketState, message: onMessage, reload: onReload });
  let lastDashboardFetch = 0;
  let nextMessageId = 0;
  let stores: store.Stores = store.createStores();

  if (initManage === undefined) {
    dispatch = app.dispatch;
    getState = app.getState;
    globalSocket = socket;
  } else {
    app.dispatch((state) => ({
      ...state,
      manage: initManage,
      visibility: Visibility.Visible,
    }));
    socket.setActive(true);
    otherDispatch = app.dispatch;
    resize();
  }

  return () => {
    socket.setActive(false);
    otherDispatch = undefined;
    ReactDom.unmountComponentAtNode(element);
  };

  function checkBaseFilters(newBaseFilters: string) {
    // If `baseFilters` changes, we must reset all stores.
    if (newBaseFilters !== baseFilters) {
      stores = store.createStores();
      baseFilters = newBaseFilters;
    }
  }

  function setLocation(state: State) {
    if (state.visibility === Visibility.Visible && initManage === undefined) {
      let title = "";
      if (state.dashboard.type === "dashboard") {
        if (state.path.length === 0) {
          title = translate(state.dashboard.root.title);
        } else {
          const item = state.dashboard.items[state.path[state.path.length - 1]];
          if (item !== undefined) title = translate(item.title);
        }
      }
      const hash = state.path.join("/");
      const current = document.location.hash.substring(0, 2) === "#!" ? document.location.hash.substring(2) : "";

      navigation.setDashboardPath(hash, title, hash === current ? "none" : "push");
    }
  }

  function onChange(state: State, old: State) {
    const pathChanged = !array.equal(old.path, state.path);
    const socketClosed = state.socketState !== old.socketState && state.socketState === SocketState.Closed;

    let shouldSetLocation = false;

    if (socketClosed) {
      // Invalidate the filteroptions
      const options: FilterOptionsState = {};
      for (const key of userFilterFields) {
        const current = state.filters.options[key];
        if (current !== undefined) {
          options[key] = {
            updateId: undefined,
            source: "",
            filters: "",
            timestamp: 0,
            list: undefined,
          };
        }
      }
      state = {
        ...state,
        filters: {
          ...state.filters,
          options,
        },
      };
    }

    if (socketClosed && state.filters.optionKeys === "loading") {
      state = {
        ...state,
        filters: {
          ...state.filters,
          optionKeys: "uninitialized",
        },
      };
    }

    if ((pathChanged || state.filters.string !== old.filters.string) && state.dashboard.type === "dashboard") {
      state = store.updateCharts(stores, messageId, state.dashboard.id, socket, manageInfo, true)(state);
      shouldSetLocation = true;
    }

    if (pathChanged && state.activeTool !== undefined && old.activeTool === state.activeTool) {
      state = { ...state, activeTool: undefined };
    }

    if (state.path.length === 0 && (state.activeTool === Tool.Source || state.activeTool === Tool.RedFlags)) {
      state = { ...state, activeTool: undefined };
    }

    if (userId !== menu.userId()) {
      userId = menu.userId();
      socket.setActive(false);
      onReload();
      socket.setActive(true);
      return;
    }

    if (state.visibility !== old.visibility || state.path !== old.path || state.dashboard !== old.dashboard) {
      if (state.visibility === Visibility.Visible && state.manage === undefined) {
        const breadcrumbs = [{ text: "Home", url: "/" }];
        if (state.dashboard.type === "dashboard") {
          let url = "/#!";
          for (const elem of state.path) {
            const item = state.dashboard.items[elem];
            url += elem;
            breadcrumbs.push({
              text: item === undefined ? "" : translate(item.title),
              url,
            });
            url += "/";
          }
        }
        menu.update({ breadcrumbs });
        navigation.setBodyClassName();
      }
    }

    if (state.dashboard !== old.dashboard || state.visibility !== old.visibility) shouldSetLocation = true;

    if (shouldSetLocation) {
      setLocation(state);
    }

    if (filtersVisible(state) && state.filters.optionKeys === "uninitialized" && state.dashboard.type === "dashboard") {
      // Find all keys that can be filtered on
      socket.send({
        type: "request-filter-keys",
        manageInfo,
        messageId: messageId(),
        dashboardId: state.dashboard.id,
      });
      state = {
        ...state,
        filters: {
          ...state.filters,
          optionKeys: "loading",
        },
      };
    }

    if (filtersVisible(state) && state.dashboard.type === "dashboard") {
      // Check which fields we should update
      const oldOptions = state.filters.options;
      const submenu = state.filters.submenu;
      if (submenu !== undefined) {
        const submenuOptions = oldOptions[submenu];
        const source = state.path.length === 0 ? "" : state.path[state.path.length - 1];
        // Current filters, without this key.
        const currentWithout = filtersWithout(state.filters.current, submenu);
        const filters = filtersToString(currentWithout);
        if (submenuOptions !== undefined && (submenuOptions.source !== source || submenuOptions.filters !== filters /*|| submenuOptions.timestamp < Date.now() - 2 * 60 * 1000*/)) {
          console.log(submenu, submenuOptions);
          const requestId = (nextFilterRequestId++).toString();
          socket.send({
            type: "request-filter-options",
            manageInfo,
            messageId: messageId(),
            source,
            requestId,
            dashboardId: state.dashboard.id,
            filters,

            client: submenu === "client",
            supplier: submenu === "supplier",
            service: submenu === "service",
            location: submenu === "location",
            category: submenu === "category",
          });
          const optionState: FilterOptionState = {
            updateId: requestId,
            source,
            filters,
            timestamp: Date.now(),
            list: undefined,
          };
          const optionsState: FilterOptionsState = {
            ...oldOptions,
            [submenu]: optionState,
          };
          state = {
            ...state,
            filters: {
              ...state.filters,
              options: optionsState,
            },
          };
        }
      }
    }

    // Check whether we need to reload source details
    if (state.activeTool === Tool.Source && state.dashboard.type === "dashboard") {
      if (state.sourceDetails === undefined) {
        socket.send({
          type: "request-source-details",
          manageInfo,
          messageId: messageId(),
          source: state.path[state.path.length - 1],
          dashboardId: state.dashboard.id,
          filters: state.filters.string,
        });
        state = {
          ...state,
          sourceDetails: { type: "loading" },
        };
      } else if (state.sourceDetails.type === "sources" && state.sourceDetails.selected !== undefined && state.sourceDetails.popup === undefined) {
        let sourceItemId;
        if (state.sourceDetails.audit.length !== 0) {
          sourceItemId = state.sourceDetails.audit[state.sourceDetails.selected].id;
        } else {
          sourceItemId = state.sourceDetails.at[state.sourceDetails.selected].id;
        }
        socket.send({
          type: "request-source-detail-popup",
          manageInfo,
          messageId: messageId(),
          source: state.path[state.path.length - 1],
          dashboardId: state.dashboard.id,
          filters: state.filters.string,
          sourceItemId,
        });
        const sourceDetails: SourceDetailsState = {
          ...state.sourceDetails,
          popup: { type: "loading" },
        };
        state = {
          ...state,
          sourceDetails,
        };
      }
    }

    // Check whether we need to reload red flag details
    if (state.activeTool === Tool.RedFlags && state.dashboard.type === "dashboard") {
      if (state.redFlagDetails === undefined) {
        socket.send({
          type: "request-red-flags",
          manageInfo,
          messageId: messageId(),
          source: state.path[state.path.length - 1],
          dashboardId: state.dashboard.id,
          filters: state.filters.string,
        });
        state = {
          ...state,
          redFlagDetails: { type: "loading" },
        };
      } else if (state.redFlagDetails.type === "redflags" && state.redFlagDetails.selected !== undefined && state.redFlagDetails.popup === undefined) {
        socket.send({
          type: "request-red-flag-popup",
          manageInfo,
          messageId: messageId(),
          source: state.path[state.path.length - 1],
          dashboardId: state.dashboard.id,
          requirement: state.redFlagDetails.redflags[state.redFlagDetails.selected].requirementId,
          filters: state.filters.string,
        });
        const redFlagDetails: RedFlagDetailsState = {
          ...state.redFlagDetails,
          popup: { type: "loading" },
        };
        state = {
          ...state,
          redFlagDetails,
        };
      }
    }

    if (old.visibility !== Visibility.Visible && state.visibility === Visibility.Visible) {
      requestDashboard();
    }

    // Save new user preferences
    if (state.user.shouldPush) {
      state = {
        ...state,
        user: {
          ...state.user,
          shouldPush: false,
        },
      };
      socket.send({
        type: "set-user-preferences",
        manageInfo,
        messageId: messageId(),
        order: state.user.preferences.order.join(","),
        hidden: state.user.preferences.hidden.join(","),
      });
    }

    if (manageInfo !== null && state.dashboard.type === "dashboard" && state.manage !== undefined && old.manage !== undefined) {
      if (state.manage.committedAction !== undefined && state.manage.committedAction !== old.manage.committedAction) {
        socket.send({
          type: "manage",
          manageInfo,
          messageId: messageId(),
          dashboardId: state.dashboard.id,
          action: state.manage.committedAction,
        });
      }

      if (state.manage.tool !== undefined && (old.manage.tool === undefined || old.manage.tool.type !== state.manage.tool.type)) {
        if (state.manage.tool.type === "edit-filters") {
          socket.send({
            type: "request-filter-options-for-configure",
            manageInfo,
            messageId: messageId(),
            dashboardId: state.dashboard.id,
          });
        }

        if (state.manage.tool.type === "edit-formula") {
          socket.send({
            type: "request-at-expression",
            manageInfo,
            messageId: messageId(),
            dashboardId: state.dashboard.id,
            source: state.path[state.path.length - 1],
            benchmarkId: state.manage.tool.benchmarkId === undefined ? null : state.manage.tool.benchmarkId,
          });
        }
      }
    }
    return state;
  }

  function onReload() {
    lastDashboardFetch = 0;
    // Socket was closed, remove all data of old dashboard
    stores = store.createStores();
    app.dispatch((state) => ({
      ...state,
      ...resetStatePartial,
    }));
    requestDashboard();
  }

  function changeSocketState(socketState: SocketState) {
    app.dispatch((state) => ({ ...state, socketState }));
    if (socketState === SocketState.Open) {
      requestDashboard();
    }
  }

  function onMessage(message: protocol.ServerMessage) {
    if (message.type === "provide-dashboard") {
      lastDashboardFetch = Date.now();
      checkBaseFilters(message.baseFilters);
      app.dispatch((state) => {
        let path = state.path;
        const { dashboard, source } = message;

        let manage = state.manage;
        if (manage !== undefined) {
          manage = { ...manage, committedAction: undefined, source: source === null ? undefined : source };
        }

        const user: State["user"] = message.preferences === null ? state.user : { preferences: message.preferences, shouldPush: false };
        if (!state.manage) {
          restrictDashboard(dashboard, message.compatible);
          personalize(dashboard, user.preferences);
        }

        stores = store.createStores();

        const oldDashboard = state.dashboard;

        state = { ...state, user, manage, dashboard };

        if (oldDashboard.type === "dashboard") {
          console.log(oldDashboard, dashboard);
        }
        if (oldDashboard.type === "dashboard" && oldDashboard.id !== dashboard.id && message.itemIdMapping === null) {
          path = [];
        } else {
          if (message.itemIdMapping !== null && oldDashboard.type === "dashboard") {
            // Note: itemIdMapping contains non-namespaced ids, whereas path contains the namespaced ids.
            // We thus need to add and remove the namespace from the ids.
            path = path.map((id) => {
              // Remove namespace from `id`
              const rawId = id.substring(oldDashboard.rawId.length + 1);
              // Add new namespace
              return dashboard.rawId + "_" + (message.itemIdMapping![rawId] || "");
            });
          }
          // Check if current path is valid with new dashboard
          let item: Root | Item | undefined = dashboard.root;
          for (let i = 0; i < path.length; i++) {
            const page = path[i];

            if (!directoryHasItem(item, page)) {
              path = path.slice(0, i);
              break;
            }

            item = getItem(state, page);
            if (item === undefined) {
              path = path.slice(0, i);
              break;
            }
          }
        }
        return action.updateLayout({ ...state, path, activeTool: state.activeTool === Tool.Personalize ? undefined : state.activeTool });
      });
      updateCharts(true);
    } else if (message.type === "dashboard-forbidden") {
      // Reset stores
      stores = store.createStores();
      baseFilters = "";
      app.dispatch((state) => ({
        ...state,
        ...resetStatePartial,
      }));
    } else if (message.type === "dashboard-not-found") {
      // Reset stores
      stores = store.createStores();
      baseFilters = "";
      app.dispatch((state) => ({
        ...state,
        ...resetStatePartial,
        dashboard: { type: "no-dashboard", reason: NoDashboardReason.NotFound },
      }));
    } else if (message.type === "provide-source") {
      checkBaseFilters(message.baseFilters);
      store.updateStore(stores, message);
      updateCharts(false);
    } else if (message.type === "provide-source-split") {
      checkBaseFilters(message.baseFilters);
      store.updateStoreSplit(stores, message);
      updateCharts(false);
    } else if (message.type === "provide-source-details") {
      app.dispatch((state) => {
        if (state.dashboard.type !== "dashboard" || message.filters !== state.filters.string || message.dashboardId !== state.dashboard.id) {
          return state;
        }
        const shouldSelect = window.innerWidth > 600 && (message.audit.length !== 0 || message.at.length !== 0);
        return {
          ...state,
          sourceDetails: { type: "sources", audit: message.audit, at: message.at, selected: shouldSelect ? 0 : undefined, popup: undefined, },
        };
      });
    } else if (message.type === "provide-source-detail-popup") {
      app.dispatch((state) => {
        if (state.sourceDetails === undefined || state.sourceDetails.type !== "sources" || state.sourceDetails.selected === undefined) return state;
        let sourceItemId;
        if (state.sourceDetails.audit.length !== 0) {
          sourceItemId = state.sourceDetails.audit[state.sourceDetails.selected].id;
        } else {
          sourceItemId = state.sourceDetails.at[state.sourceDetails.selected].id;
        }
        if (sourceItemId !== message.sourceItemId) return state;

        const sourceDetails: SourceDetailsState = {
          ...state.sourceDetails,
          popup: message.popup,
        };
        return {
          ...state,
          sourceDetails,
        };
      });
    } else if (message.type === "provide-red-flags") {
      app.dispatch((state) => {
        if (state.dashboard.type !== "dashboard" || message.filters !== state.filters.string || message.dashboardId !== state.dashboard.id) {
          return state;
        }
        const shouldSelect = window.innerWidth > 600 && message.list.length !== 0;
        const redFlagDetails: RedFlagDetailsState = { type: "redflags", redflags: message.list, selected: shouldSelect ? 0 : undefined, popup: undefined };
        return {
          ...state,
          redFlagDetails,
        };
      });
    } else if (message.type === "provide-red-flag-popup") {
      app.dispatch((state) => {
        if (state.dashboard.type !== "dashboard" || message.filters !== state.filters.string || message.dashboardId !== state.dashboard.id || state.redFlagDetails === undefined || state.redFlagDetails.type !== "redflags") {
          return state;
        }

        if (state.redFlagDetails.selected === undefined || state.redFlagDetails.redflags[state.redFlagDetails.selected].requirementId !== message.requirement) {
          return state;
        }

        const redFlagDetails: RedFlagDetailsState = {
          ...state.redFlagDetails,
          popup: message.popup,
        };

        return { ...state, redFlagDetails };
      });
    } else if (message.type === "provide-filter-options-for-configure") {
      app.dispatch(action.manage.provideFilterOptions(message.options));
    } else if (message.type === "provide-filter-keys") {
      app.dispatch((state) => {
        const options: FilterOptionsState = {};
        const fallback = emptyFilterOptions(message.keys);
        for (const key of message.keys) {
          options[key] = state.filters.options[key] || fallback[key];
        }
        return {
          ...state, filters: {
            ...state.filters,
            optionKeys: message.keys,
            options,
          },
        };
      });
    } else if (message.type === "provide-filter-options") {
      app.dispatch((state) => ({
        ...state,
        filters: {
          ...state.filters,
          options: setFilterOptionsFor(message.key, state.filters.current, state.filters.options || {}, message.options, message.requestId),
        },
      }));
    } else if (message.type === "provide-at-expression") {
      app.dispatch(action.manage.editAtFormula(app.dispatch, message.expression));
      app.dispatch(action.manage.editUnit(message.unit));
    } else if (message.type === "server-error") {
      const msg = translate(errorMessages)[message.error]; // || translate(errorMessages).unknown;
      if (msg === undefined || message.error === "unknown") return;
      alert(typeof msg === "function" ? msg(message.description) : msg);
      app.dispatch((state) => {
        if (state.manage === undefined) {
          return state;
        }
        return {
          ...state,
          manage: { ...state.manage, committedAction: undefined },
        };
      });
    } else {
      console.log("Unknown response type: " + (message as protocol.ServerMessage).type);
    }
  }

  function requestDashboard() {
    const now = Date.now();
    if (now - lastDashboardFetch <= 300) {
      // Dashboard was already requested in the last 300 milliseconds, ignore.
      return;
    }
    const state = app.getState();
    if (state.dashboard.type === "dashboard") {
      socket.send({ type: "verify-dashboard", manageInfo, messageId: messageId(), dashboardId: state.dashboard.id });
    } else {
      lastDashboardFetch = now;
      socket.send({ type: "request-dashboard", manageInfo, messageId: messageId() });
    }
  }

  function updateCharts(requestData: boolean) {
    const state = app.getState();
    if (state.dashboard.type !== "dashboard") return;
    app.dispatch(store.updateCharts(stores, messageId, state.dashboard.id, socket, manageInfo, requestData));
  }

  function messageId() {
    return nextMessageId++;
  }
}

let userId: string | null = null;

// Shows or hides the dashboard
export function setVisibility(isVisible: boolean) {
  dispatch((state) => {
    const { visibility } = state;

    if (isVisible && visibility !== Visibility.Visible) {
      return { ...state, visibility: Visibility.Visible };
    }
    if (!isVisible && visibility === Visibility.Visible) {
      return { ...state, visibility: Visibility.Hidden };
    }

    return state;
  });
  if (isVisible) setPath();
}
export function visible() {
  return getState().visibility === Visibility.Visible;
}

export function isRoot() {
  return getState().path.length === 0;
}

export function setAuth(isAuthenticated: boolean) {
  globalSocket.setActive(isAuthenticated);

  if (!isAuthenticated) {
    dispatch((state) => {
      return { ...state, dashboard: { type: "no-dashboard", reason: NoDashboardReason.Loading } };
    });
  }
}

export function setPath() {
  const path = location.hash.substring(0, 2) !== "#!" ? [] : location.hash.substring(2).split("/");
  const current = getState().path;

  if (array.equal(path, current)) return;

  dispatch((state) => {
    if (state.dashboard.type === "dashboard") {
      return { ...state, path: validatePath(state.dashboard, path) };
    } else {
      return { ...state, path: [] };
    }
  });
}

function directoryHasItem(directory: Root | Item, item: string) {
  if (directory.items === undefined) return false;
  return directory.items.indexOf(item) !== -1;
}

function validatePath(dashboard: Dashboard, path: string[]) {
  // Check if path is valid with the dashboard
  let item: Root | Item | undefined = dashboard.root;
  for (let i = 0; i < path.length; i++) {
    const page = path[i];

    if (!directoryHasItem(item, page)) {
      return path.slice(0, i);
    }

    item = getItemFromDashboard(dashboard, page);
    if (item === undefined) {
      return path.slice(0, i);
    }
  }
  return path;
}
