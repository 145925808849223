import { Dashboard, Root, Item } from "./item";

export interface UserPreferences {
  order: string[],
  hidden: string[],
}

export function updateOrder(preferences: UserPreferences, newOrder: string[], hidden: string[] | undefined): UserPreferences {
  return {
    order: [
      ...newOrder,
      ...preferences.order.filter((item) => newOrder.indexOf(item) === -1 && (hidden === undefined || hidden.indexOf(item) === -1)),
    ],
    hidden: hidden === undefined ? preferences.hidden : hidden,
  };
}

export function personalize(dashboard: Dashboard, preferences: UserPreferences) {
  handle(dashboard.root);
  for (const id of Object.keys(dashboard.items)) {
    handle(dashboard.items[id]);
  }

  function handle(item: Item | Root | undefined) {
    if (item === undefined || item.items === undefined || item.items.length <= 1) return;

    const found: string[] = [];
    const hidden: string[] = [];

    for (const originalId of preferences.order) {
      const id = getId(originalId);
      if (id !== undefined) {
        found.push(id);
      }
    }
    if (item.type === "root") {
      for (const originalId of preferences.hidden) {
        const id = getId(originalId);
        if (id !== undefined) {
          hidden.push(id);
        }
      }
    }

    item.items = [
      ...found,
      ...item.items.filter((id) => found.indexOf(id) === -1 && hidden.indexOf(id) === -1),
      ...hidden,
    ];
    if (item.type === "root") {
      item.firstHidden = item.items.length - hidden.length;
    }

    function getId(originalId: string) {
      for (const childId of item!.items!) {
        const child = dashboard.items[childId];
        if (child !== undefined && child.originalId === originalId) return child.id;
      }
      return undefined;
    }
  }
}
