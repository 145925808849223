import { Dashboard, SourceSplit, FilterOptions, DashboardSource, UserFilterKeys } from "../model";
import { UserPreferences } from "../model/personalize";

export interface ServerDashboardNotFound {
  type: "dashboard-not-found",
}

export interface ServerDashboardForbidden {
  type: "dashboard-forbidden",
}

export interface ServerProvideDashboard {
  type: "provide-dashboard",
  dashboard: Dashboard,
  baseFilters: string,
  compatible: { [id: string]: boolean },
  source: DashboardSource | null,
  itemIdMapping: { [id: string]: string } | null,
  preferences: UserPreferences | null,
}

// We have two interfaces for the response containing source data
// for graphs. `ServerProvideSourceSplit` contains the response
// of requests with the `split` option set. `ServerProvideSource`
// contains the response for other request.

export interface ServerProvideSourceSplit {
  type: "provide-source-split",

  // Repeat info from request
  source: string,
  baseFilters: string,
  filters: string,
  split: SourceSplit,

  // Provide values for all entities in this split.
  values: { id: string | null, label: string, value: number, parent: { id: string, label: string, } | null, }[],
  unit: string | undefined,
  decimals: number,
}

export interface ServerProvideSource {
  type: "provide-source",
  source: string,
  baseFilters: string,
  filters: string,

  value: number | undefined,
  count: number,
  unit: string | undefined,
  redflags: number | undefined,
  benchmark: number | undefined,
}

export interface ServerProvideSourceDetails {
  type: "provide-source-details",
  source: string,
  dashboardId: string,
  filters: string,

  audit: SourceDetail[],
  at: SourceDetail[],
}

export interface SourceDetail {
  id: string,
  name: string,
  value: number,
  location: string,
  service: string,
  date: string,
}

export interface ServerProvideSourceDetailPopup {
  type: "provide-source-detail-popup",
  source: string,
  sourceItemId: string,
  dashboardId: string,

  popup: SourceDetailPopup,
}

export type SourceDetailPopup = SourceDetailPopupAudit | SourceDetailPopupAt | SourceDetailPopupForbidden;
export interface SourceDetailPopupAudit {
  type: "audit",
  checklistType: string,
  failedRequirements: { code: string, name: string, weight: string, category: number, tags: [{ id: number, name: string}] }[],
  path: string,
}
export interface SourceDetailPopupAt {
  type: "at",
  questions: { code: string, question: string, answer: number, unit: string | undefined, decimals: number, path: string, tags: [{ id: number, name: string}] }[],
}
export interface SourceDetailPopupForbidden {
  type: "forbidden",
}

export interface ServerProvideRedFlags {
  type: "provide-red-flags",
  source: string,
  dashboardId: string,
  filters: string,
  list: RedFlagItem[],
}
export interface RedFlagItem {
  requirementId: string,
  code: string,
  name: string,
  count: number,
  locationCount: number,
  locationFirst: string,
  date: string,
}
export interface ServerProvideRedFlagPopup {
  type: "provide-red-flag-popup",
  source: string,
  dashboardId: string,
  filters: string,
  requirement: string,
  popup: RedFlagPopup,
}

export interface RedFlagPopup {
  type: "redflags",
  failedRequirements: { client: string, supplier: string, service: string, contractDescription: string, checklistDescription: string, location: string, date: string, notes: string, path: string }[],
  forbiddenRequirements: number, // Number of requirements which are not visible to the user
}

export interface ServerProvideFilterOptionsForConfigure {
  type: "provide-filter-options-for-configure",
  options: FilterOptions,
}

export interface ServerProvideFilterOptions {
  type: "provide-filter-options",
  requestId: string,
  key: UserFilterKeys,
  options: FilterOptions[UserFilterKeys],
}

export interface ServerProvideFilterKeys {
  type: "provide-filter-keys",
  keys: UserFilterKeys[],
}

export interface ServerProvideAtExpression {
  type: "provide-at-expression",
  expression: string,
  unit: string,
}

export interface ServerError {
  type: "server-error",
  error: ServerErrorReason,
  description?: string,
}
export type ServerErrorReason
  = "unknown"
  | "bad-request"
  | "manage-filter-own-organisation"
  | "manage-at-parse-error"
  | "manage-at-unresolved-reference";

export type ServerMessage = ServerDashboardNotFound | ServerDashboardForbidden | ServerProvideDashboard | ServerProvideSourceSplit | ServerProvideSource | ServerProvideSourceDetails | ServerProvideSourceDetailPopup | ServerProvideRedFlags | ServerProvideRedFlagPopup | ServerProvideFilterOptionsForConfigure | ServerProvideFilterOptions | ServerProvideFilterKeys | ServerProvideAtExpression | ServerError;

export enum ServerCloseCode {
  NotAuthenticated = 4201,
  OtherUser = 4202,
}
