import { Text } from "./model/chart";

export function withUnit(value: number, unit: string | undefined, decimals = 0) {
  const valueString = value.toFixed(decimals);
  if (unit === "" || unit === undefined) return valueString;
  return `${ valueString } ${ unit }`;
}

const canvasMeasureWidth = document.createElement("canvas").getContext("2d")!;
export function textWidth(text: string) {
  canvasMeasureWidth.font = "100px Lato";
  return canvasMeasureWidth.measureText(text).width * 0.01;
}

export function getText(content: string): Text {
  return {
    content,
    width: textWidth(content),
  };
}

export function textWidths(text: string): Float32Array {
  const length = text.length;
  const array = new Float32Array(length);

  array[0] = 0;
  for (let i = 1; i < length - 1; i++) {
    array[i] = textWidth(text.substring(0, i) + "...");
  }
  array[length - 1] = textWidth(text);

  return array;
}

export function scaleToFit(outerSize: number, elementSize: number, maxScale?: number) {
  const scale = outerSize / elementSize;
  if (maxScale !== undefined && scale > maxScale) return maxScale;
  return scale;
}

export function shortenToFit(text: string, sizes: Float32Array, maxSize: number) {
  const length = text.length;

  // Check whether the full string fits
  if (sizes[length - 1] <= maxSize) return text;

  // Preconditions:
  //  - sizes[0 .. length - 2] is sorted
  //  - maxSize > 0

  let i = 0;
  let j = length - 1;
  // Invariant: sizes[i] <= maxSize && maxSize < sizes[j]
  // Variant: j - i
  while (j - i > 1) {
    const mid = ((i + j) / 2) | 0;
    if (sizes[mid] > maxSize) {
      // mid is too large, go to left
      j = mid;
    } else {
      // mid fits, go to right
      i = mid;
    }
  }

  if (i === 0) return "";
  return text.substring(0, i) + "...";
}
