import { Translations } from "../../translations";
import { Refine } from "../../utils/type";
import * as protocol from "../protocol";
import { FilterOptions, TimePeriod } from "./filters";
import { Chart, SourceSplit, SourceSplitDate } from "./chart";
import { DashboardSource } from "./source";
import { State } from "./state";
import { ParseError, AtExpression, UnresolvedVar } from "./atformula";

export const filterKeys: (keyof FilterOptions)[] = ["client", "supplier", "location", "level", "contract", "checklist", "checklistKind", "category", "service", "requirement", "weight", "at", "tag"];
export const filterNames: Translations<{ [_ in keyof FilterOptions ]: string }> = {
  nl: {
    client: "Opdrachtgever",
    supplier: "Opdrachtnemer",
    location: "Locatie",
    level: "Niveau",
    contract: "Contract",
    checklist: "Checklist",
    checklistKind: "Checklist soort",
    service: "Dienst",
    requirement: "Eis",
    weight: "Gewicht van eis",
    at: "GDP",
    category: "Categorie",
    tag: "Tag",
  },
  en: {
    client: "Customer",
    supplier: "Supplier",
    location: "Location",
    level: "Level",
    contract: "Contract",
    checklist: "Checklist",
    checklistKind: "Checklist kind",
    service: "Service",
    requirement: "Requirement",
    weight: "Weight of requirement",
    at: "GDP",
    category: "Category",
    tag: "Tag",
  },
};

export interface ManageToolTitle {
  type: "title",
  title: Partial<Translations>,
}

export interface ManageAddItem {
  type: "add-item",
}

export interface ManageEditFilters {
  type: "edit-filters",
  benchmarkId: number | undefined,
  active: keyof FilterOptions | undefined,
  filters: ManageEditFilterOptions | undefined,
  counts: { [ K in keyof FilterOptions ]?: number },
}

export interface ManageEditNormTrend {
  type: "edit-norm-trend",
  norm: string,
  trend: SourceSplitDate | undefined,
}

export type ManageEditFilterOptions = {
  [ K in keyof FilterOptions ]: ManageEditFilterOption[]
};
export interface ManageEditFilterOption {
  label: string,
  value: string | null,
  selected: boolean,
}

export interface ManageEditAtFormula {
  type: "edit-formula",
  benchmarkId: number | undefined,
  // `expression` is `undefined` when the expression hasn't loaded yet. It is fetched using
  // a request-at-expression message.
  expression: string | undefined,
  unit: string | undefined,
  decimals: string,
  result: ParseError | AtExpression<UnresolvedVar> | undefined,
  timeout: number | undefined,
}

export interface ManageEditWeights {
  type: "edit-weights",
  unit: string,
  decimals: string,
  items: { id: string, title: Translations, weight: string }[],
}

export interface ManageCharts {
  type: "charts",
}

export interface ManageEditChart {
  type: "edit-chart",
  detailIndex: number | undefined,
  title: Partial<Translations> | undefined,
  chartType: Chart["type"],
  split: SourceSplit,
  min: string,
  max: string,
  transitionGreenOrange: string,
  transitionOrangeRed: string,
}

export interface ManageEditBenchmark {
  type: "edit-benchmark",
}

export interface ManageEditBenchmarkItem {
  type: "edit-benchmark-item",
  index: number,
  value: string | undefined,
  weight: string,
  period: TimePeriod,
}

export type ChartNumberKey = "min" | "max" | "transitionGreenOrange" | "transitionOrangeRed";
export function chartNumberKeys(type: string): ChartNumberKey[] {
  if (type === "number") {
    return [];
  } else if (type === "bar" || type === "trend") {
    return ["min", "max"];
  } else if (type === "traffic-light") {
    return ["transitionGreenOrange", "transitionOrangeRed"];
  } else {
    return [];
  }
}

export function chartNumberFields<T extends Chart>(chart: T): { key: ChartNumberKey, value: number | undefined }[] {
  const keys = chartNumberKeys(chart.type) as (ChartNumberKey & keyof T)[];
  return keys.map((key) => ({
    key,
    value: chart[key] as any,
  }));
}

export type ManageTool
  = ManageToolTitle | ManageAddItem | ManageEditFilters
  | ManageEditNormTrend | ManageEditAtFormula | ManageEditWeights
  | ManageCharts | ManageEditChart | ManageEditBenchmark | ManageEditBenchmarkItem;

export interface ManageState {
  organisation: string,
  edit: boolean,
  admin: boolean,
  source: DashboardSource | undefined,
  // An action that is send to the server.
  // When this field is not `undefined`, a change is pending
  // and the user interface should not accept any changes
  committedAction: protocol.ManageAction | undefined,
  tool: ManageTool | undefined,
}

export const doesManage = (state: State, tool?: ManageTool["type"]) => {
  if (state.manage === undefined) return false;
  if (state.manage.tool === undefined) return false;
  if (tool === undefined) return true;
  return state.manage.tool.type === tool;
};

export const manageTool = <T extends ManageTool["type"]>(state: State, tool: T): Refine<ManageTool, T> | undefined => {
  if (state.manage === undefined) return undefined;
  if (state.manage.tool === undefined) return undefined;
  if (state.manage.tool.type === tool) {
    return state.manage.tool as Refine<ManageTool, T>;
  } else {
    return undefined;
  }
};

