export const success = "#29b818";
export const info = "#18b5b8";
export const warning = "#c48b21";
export const danger = "#c43421";

export const norm = danger;
export const benchmark = info;
export const lightgray = "#ecf0f1";
export const lightlightgray = "#f2f8fa";
export const gray = "#bdc3c7";
