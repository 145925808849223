import { currentLocale } from "../menu";
import { Translations } from "./";

export function translate<T>(translations: Translations<T>): T {
  const locale = currentLocale();
  if (translations[locale] !== undefined) return translations[locale];
  if (translations["en"] !== undefined) return translations["en"];
  if (translations["nl"] !== undefined) return translations["nl"];
  return undefined!;
}
