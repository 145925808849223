import { TextTranslations } from "./interface";
import { nl } from "./locales/nl";
import { en } from "./locales/en";

export type Locale = "en" | "nl";
export type Translations<T = string> = { [_ in Locale]: T };

export let locale: Locale;
export let t: TextTranslations;

export function setLocale(newLocale: Locale) {
  locale = newLocale;
  if (locale === "nl") {
    t = nl;
  } else {
    t = en;
  }
}
