import * as React from "react";
import { State, Item } from "../model";
import { Dispatch } from "../../utils/state";
import { isMobile } from "../../navigation/navigation";
import * as action from "../action";
import { SourceDetail } from "../protocol";
import { withUnit } from "../utils";
import { SourceDetailPopup } from "../protocol/server";
import { t } from "../../translations";

export function SourceDetails({ page, state, details, unit, dispatch }: { page: Item, state: State, details: State["sourceDetails"], unit: string | undefined, dispatch: Dispatch<State> }) {
  if (details === undefined) {
    return null;
  }

  let subtitle;
  let type: "audit" | "at" = "audit";
  if (details.type === "loading") {
    subtitle = t.notices.loading;
  } else if (details.audit.length === 1) {
    subtitle = t.dashboard.sources.audit_one;
  } else if (details.audit.length !== 0) {
    subtitle = details.audit.length + " " + t.dashboard.sources.audits;
  } else if (details.at.length === 1) {
    subtitle = t.dashboard.sources.at_one;
    type = "at";
  } else if (details.at.length !== 0) {
    subtitle = details.at.length + " " + t.dashboard.sources.ats;
    type = "at";
  } else {
    subtitle = t.dashboard.sources.no_data;
  }

  let selectedItem = details.type !== "sources" || details.selected === undefined ? undefined
    : details.at.length !== 0 ? details.at[details.selected]
    : details.audit[details.selected];

  return (
    <div className="dashboard-source">
      <div className="dashboard-source-header">
        <div className="dashboard-tool-close layout-color-light" onClick={ () => dispatch(action.closeTool) }><span>x</span></div>
        <h3>{ t.dashboard.item.sources }</h3>
        <h4>{ subtitle }</h4>
      </div>
      <div className="dashboard-source-popup-wrapper">
        {
          selectedItem === undefined ? null : <Popup page={ page } state={ state } type={ type } item={ selectedItem } popup={ details.type === "sources" ? details.popup : undefined } unit={ unit } dispatch={ dispatch } />
        }
      </div>
      <div className="dashboard-source-list">
        { details.type === "sources" && group({ page, details, property: "audit", selected: details.selected, unit, dispatch }) }
        { details.type === "sources" && group({ page, details, property: "at", selected: details.selected, unit, dispatch }) }
      </div>
    </div>
  );
}

function group({ page, details, property, selected, unit, dispatch }: { page: Item, details: { audit: SourceDetail[], at: SourceDetail[] }, property: "audit" | "at", selected: number | undefined, unit: string | undefined, dispatch: Dispatch<State> }) {
  const sources = details[property];
  if (sources.length === 0) {
    return null;
  }
  return sources.map((item, index) => {
    let className = "dashboard-source-item";
    if (index === selected) className += " selected";
    return <div className={ className } key={ index } onClick={ () => dispatch(action.selectSourceDetailsItem(index)) }>
      <div className="dashboard-source-item-header">
        <div>{ item.name }</div>
        <div>{ withUnit(item.value, unit, page.decimals) }</div>
      </div>
      <div className="dashboard-source-item-body">
        <div>{ item.location }</div>
        <div>{ item.date }</div>
      </div>
      <div></div>
    </div>
  });
}

function Tag({ name }: { name: string }) {
  return <div className="tag">{ name }</div>;
}

function Popup({ page, state, type, item, popup, unit, dispatch }: { page: Item, state: State, type: "audit" | "at", item: SourceDetail, popup: SourceDetailPopup | { type: "loading" } | undefined, unit: string | undefined, dispatch: Dispatch<State> }) {
  let more = null;

  if (popup !== undefined) {
    if (popup.type === "loading") {
      more = <div className="dashboard-source-popup-message"><br /><br />{ t.dashboard.sources.loading_more_data }<br /><br /></div>
    } else if (popup.type === "forbidden") {
      more = <div className="dashboard-source-popup-message"><br /><br />{ type === "audit" ? t.dashboard.sources.no_permission_audit : t.dashboard.sources.no_permission_at }<br /><br /></div>
    } else if (popup.type === "audit") {
      more = <div>
        <div className="action-popup-header">{ t.dashboard.sources.audit_type }</div>
        <div className="action-popup-field">{ popup.checklistType }</div>
        <h3>{ t.dashboard.sources.audit_rejected_requirements }</h3>
        { popup.failedRequirements.length !== 0 ? null : <div className="dashboard-source-popup-message">{ t.dashboard.sources.audit_no_rejected_requirements }<br /></div> }
        { popup.failedRequirements.map((requirement, index) =>
          <div className="dashboard-source-popup-requirement" key={ index }>
            <div className="dashboard-source-popup-requirement-code">{ requirement.code }</div>
            <div className="dashboard-source-popup-requirement-name">{ requirement.name }</div>
            <div className="dashboard-source-popup-requirement-weight">{ t.dashboard.sources.audit_weight }: { requirement.weight }</div>
            <div className="dashboard-source-popup-requirement-tags">{ requirement.tags.map(Tag) }</div>
          </div>
        )}
        <br />
        <a href={ popup.path } className="btn btn-primary">{ t.dashboard.sources.go_to_measurement }</a>
      </div>;
    } else if (popup.type === "at") {
      more = <div>
        <h3>{ t.dashboard.sources.at_questions }</h3>
        {
          popup.questions.map((question, index) =>
            <div className="dashboard-source-popup-requirement" key={ index }>
              <div className="dashboard-source-popup-requirement-code">{ question.code }</div>
              <div className="dashboard-source-popup-requirement-name">{ question.question }</div>
              <div className="dashboard-source-popup-requirement-weight">{ t.dashboard.sources.at_value }: { withUnit(question.answer, question.unit, question.decimals) }</div>
              <div className="dashboard-source-popup-requirement-tags">{ question.tags.map(Tag) }</div>
              { question.path === "" ? null :
                <a href={ question.path } className="btn btn-primary">{ t.dashboard.sources.at_show_history }</a>
              }
            </div>
          )
        }
        <br />
      </div>;
    }
  }

  return <div className="dashboard-source-popup" style={{ height: (state.windowHeight - (isMobile() ? 0 : 91) - 65) + "px" }}>
    <div>
      <div className="action-popup-header">{ type === "audit" ? t.dashboard.sources.audit : t.dashboard.sources.at }</div>
      <div className="action-popup-field">{ item.name }</div>
      <div className="action-popup-header">{ t.dashboard.sources.location }</div>
      <div className="action-popup-field">{ item.location }</div>
      <div className="action-popup-header">{ t.dashboard.sources.date }</div>
      <div className="action-popup-field">{ item.date }</div>
      <div className="action-popup-header">{ t.dashboard.sources.score }</div>
      <div className="action-popup-field">{ withUnit(item.value, unit, page.decimals) }</div>

      { more }

      <a href="javascript:;" className="btn btn-default" onClick={ () => dispatch(action.selectSourceDetailsItem(undefined)) }>{ t.actions.close }</a>
    </div>
  </div>;
}
