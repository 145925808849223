// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import * as React from "react";
import * as ReactDOM from "react-dom";

export type Dispatch<T> = (action: Action<T>, sync?: boolean) => void;
export type Action<T> = (state: T) => T;

export function host<T>(element: HTMLElement, state: T, renderer: (dispatch: Dispatch<T>) => (state: T) => React.ReactElement<{}>, onChange?: (state: T, old: T) => T | void) {
  let willRender = false;
  const dispatch: Dispatch<T> = (action, sync) => {
    const old = state;
    state = action(state);

    if (state !== old) {
      if (onChange !== undefined) {
        const updated = onChange(state, old);
        if (updated !== undefined) {
          state = updated;
        }
      }
      if (sync) {
        render();
      } else if (!willRender) {
        window.requestAnimationFrame(render);
        willRender = true;
      }
    }
  };

  const view = renderer(dispatch);

  window.requestAnimationFrame(render);
  willRender = true;

  return { dispatch, getState };

  function render() {
    willRender = false;
    ReactDOM.render(view(state), element);
  }

  function getState() {
    return state;
  }
}
