import { Filters, FieldFilters, UserFilters, TimePeriod } from "./filters";
import { AtFormula } from "./atformula";

export interface DashboardSource {
  sources: { [id: string]: DashboardItemSource },
  benchmarks: { [id: string]: DashboardBenchmarkSource | undefined },
}

export type DashboardItemSource
  = DashboardItemSourceDirectory
  | DashboardItemSourceAudit
  | DashboardItemSourceAt;

export interface DashboardItemSourceDirectory {
  type: "directory",
  weights: { [id: string]: number },
  unit: string,
}

export interface DashboardItemSourceAudit {
  type: "audit",
  filters: Filters,
}

export interface DashboardItemSourceAt {
  type: "at",
  filters: Filters,
  formula: AtFormula,
  unit: string,
}

export interface DashboardBenchmarkSource {
  sources: DashboardBenchmarkSourceItem[],
}

export interface DashboardBenchmarkSourceItem {
  weight: number,
  source: DashboardItemSourceAudit | DashboardItemSourceAt | number,
  period: TimePeriod,
}

export function benchmarkPropagatedFilters(filters: UserFilters): Filters {
  if (filters.fields === undefined) return filters;

  const fields: FieldFilters = {
    category: filters.fields.category,
    service: filters.fields.service,
  };
  return { fields };
}
