import * as menu from "../menu";
import { isIE } from "../navigation/navigation";
import * as render from "./render";
import "./spreadsheet";

let container: HTMLElement;
let iframe: HTMLIFrameElement;
let iframeHidden: HTMLIFrameElement;
let currentURL: string | undefined;
let suggestedFileName: string;

function release() {
  if (currentURL !== undefined) {
    URL.revokeObjectURL(currentURL);
    currentURL = undefined;
  }
}

export function initialize() {
  container = document.getElementById("export-container")!;
  iframe = document.getElementById("export-iframe") as HTMLIFrameElement;
  iframeHidden = document.getElementById("export-hidden-iframe") as HTMLIFrameElement;

  iframeHidden.onload = () => {
    const colors = menu.getState().colors;
    const pdf = render.renderPdf(iframeHidden.contentWindow!.document.body, colors[1], colors[0]);
    if (iframeHidden.src === "about:blank") return;
    release();

    const blob: Blob = pdf.output("blob");
    currentURL = URL.createObjectURL(blob);
    iframe.src = currentURL;
  };

  render.onload();
}

function showExportForm(fileName: string, url?: string) {
  if (isIE) {
    alert("Uw browser ondersteunt de exporteer-functie niet. Update uw browser of gebruik een andere browser.\nYour browser does not support the export functionality. Update your browser or use a different browser.");
    return;
  }

  suggestedFileName = fileName.replace(/ /g, "-").replace(/[^a-zA-Z0-9-_]/g, "");
  container.style.display = "block";
  iframeHidden.src = getPrintUrl(url);
}
(window as any).fpmShowExportForm = showExportForm;

function print() {
  iframe.contentWindow!.print();
}
(window as any).fpmExportPrint = print;

function downloadPdf() {
  if (currentURL !== undefined) {
    const link = document.createElement("a");
    link.href = currentURL;
    link.download = suggestedFileName + ".pdf";
    link.click();
  }
}
(window as any).fpmExportDownloadPdf = downloadPdf;

function close() {
  iframeHidden.src = "about:blank";
  iframe.src = "about:blank;";
  container.style.display = "none";
  release();
}
(window as any).fpmExportClose = close;

function getPrintUrl(url = window.location.href) {
  url = url.split("#")[0];
  if (url.indexOf("?") === -1) {
    url += "?";
  } else {
    url += "&";
  }
  url += "export_print=true";
  return url;
}
