export interface AtFormula {
  questions: string[],
  expression: AtExpression<string>,
}

export type AtExpression<V> = AtExprConstant | AtExprVariable<V> | AtExprBinary<V> | AtExprPrefixUnary<V>;

export function map<V, U>(expression: AtExpression<V>, fn: (item: V) => U): AtExpression<U> {
  if (expression.type === "constant") {
    return expression;
  } else if (expression.type === "variable") {
    return {
      type: "variable",
      reference: fn(expression.reference),
    };
  } else if (expression.type === "prefix-unary") {
    return {
      type: "prefix-unary",
      operator: expression.operator,
      operand: map(expression.operand, fn),
    };
  } else {
    return {
      type: "binary",
      operator: expression.operator,
      left: map(expression.left, fn),
      right: map(expression.right, fn),
    };
  }
}

export interface AtExprConstant {
  type: "constant",
  value: number,
}

export interface AtExprVariable<V> {
  type: "variable",
  reference: V,
}

export interface AtExprBinary<V> {
  type: "binary",
  left: AtExpression<V>,
  right: AtExpression<V>,
  operator: "+" | "-" | "*" | "/",
}

export interface AtExprPrefixUnary<V> {
  type: "prefix-unary",
  operand: AtExpression<V>,
  operator: "-",
}

export interface ParseError {
  type: "parse-error",
  position: number,
  expected: string[],
}

const token0 = getToken("0");
const token9 = getToken("9");
const tokenA = getToken("a");
const tokenZ = getToken("z");
const tokenCapitalA = getToken("A");
const tokenCapitalZ = getToken("Z");
const tokenAdd = getToken("+");
const tokenMinus = getToken("-");
const tokenMultiply = getToken("*");
const tokenDivide = getToken("/");
const tokenDot = getToken(".");
const tokenParenthesisOpen = getToken("(");
const tokenParenthesisClose = getToken(")");
const tokenSpace = getToken(" ");
const tokenSpaceTab = getToken("\t");
const tokenSpaceNewline = getToken("\r");

function getToken(str: string) {
  return str.charCodeAt(0);
}

type Fail
  = {
    type: "fail",
    expected: string | number,
  }
  | {
    type: "fail-list",
    list: Fail[],
  }
  | {
    type: "fail-at-index",
    index: number,
    fail: Fail,
  };
type Result<T> = { type: "ok", value: T } | Fail;
type Parser<T> = () => Result<T>;

const expectedDigit: Fail = { type: "fail", expected: "0-9" };
const expectedLetter: Fail = { type: "fail", expected: "A-Z" };

function ok<T>(value: T): Result<T> {
  return { type: "ok", value };
}

export interface UnresolvedVar {
  category: number,
  question: number,
}

export function format(source: string): ParseError | string {
  const ast = parseUnresolved(source);
  if (ast.type === "parse-error") return ast;
  return showUnresolved(ast);
}

export function showVariable({ category, question }: UnresolvedVar) {
  return categoryCode(category) + question.toFixed(0);
}

export function showUnresolved(expression: AtExpression<UnresolvedVar>, parent?: AtExpression<UnresolvedVar>): string {
  if (expression.type === "prefix-unary") {
    return expression.operator + showUnresolved(expression.operand, expression);
  } else if (expression.type === "constant") {
    let str = expression.value.toFixed(20);
    for (let i = str.length - 1; i >= 0; i--) {
      const char = str.charAt(i);
      if (char === ".") {
        i--;
      }
      if (char !== "0") {
        str = str.substring(0, i + 1);
        break;
      }
    }
    return str;
  } else if (expression.type === "variable") {
    return showVariable(expression.reference);
  } else {
    const needsParentheses = parent !== undefined && expressionNeedsParentheses(expression, parent);
    const left = showUnresolved(expression.left, expression);
    const right = showUnresolved(expression.right, expression);
    const str = precedenceGroup(expression.operator) === "*"
      ? left + expression.operator + right // For a multiplicative operator, don't add spaces
      : `${ left } ${ expression.operator } ${ right }`;
    if (needsParentheses) {
      return "(" + str + ")";
    } else {
      return str;
    }
  }
}

function expressionNeedsParentheses<T>(expression: AtExprBinary<T>, parent: AtExpression<T>) {
  if (parent.type !== "binary") return true;
  const expressionGroup = precedenceGroup(expression.operator);
  const parentGroup = precedenceGroup(parent.operator);
  if (expressionGroup === parentGroup) {
    // Parentheses only needed when `expression` is the right child,
    // e.g. (1 - 1) - 1 can be written without parentheses,
    // but 1 - (1 - 1) needs them
    return expression === parent.right;
  } else {
    // Parentheses needed when `expression` is a multiplicative operation,
    // e.g. (1 * 2) + 3 can be written without parentheses,
    // but (1 + 2) * 3 needs them
    return expressionGroup !== "*";
  }
}

function precedenceGroup(operator: "*" | "+" | "/" | "-"): "*" | "+" {
  if (operator === "/") return "*";
  if (operator === "-") return "+";
  return operator;
}

function categoryCode(index: number) {
  if (index === -1) return "??REF??";
  let code = "";
  while (index !== 0) {
    index--;
    const rest = index % 26;
    index = Math.floor(index / 26);
    code = String.fromCharCode(tokenCapitalA + rest) + code;
  }
  return code;
}

export function parseUnresolved(source: string): AtExpression<UnresolvedVar> | ParseError {
  let token = source.charCodeAt(0);
  let index = 0;

  spaces();
  return fromResult(pAddition());

  function fromResult<T>(result: Result<T>): T | ParseError {
    if (result.type === "ok") {
      if (index !== source.length) {
        return {
          type: "parse-error",
          position: index,
          expected: ["EOF"],
        };
      }
      return result.value;
    }
    return toParseError(result, index);
  }

  function toParseError(result: Fail, position: number): ParseError {
    if (result.type === "fail") {
      const error: ParseError = {
        type: "parse-error",
        position,
        expected: [typeof result.expected === "string" ? result.expected : String.fromCharCode(result.expected)],
      };
      return error;
    } else if (result.type === "fail-list") {
      let error: ParseError | undefined;
      for (const fail of result.list) {
        const current = toParseError(fail, position);
        if (error === undefined || error.position < current.position) {
          error = current;
        } else if (error.position === current.position) {
          error.expected = [
            ...error.expected,
            // Remove duplicate items
            ...current.expected.filter((value) => error!.expected.indexOf(value) === -1),
          ];
        }
      }
      if (error === undefined) {
        throw new Error("Parser failure, empty 'or'.");
      }
      return error;
    } else {
      return toParseError(result.fail, result.index);
    }
  }

  // Parses a number
  function pConstant(): Result<AtExprConstant> {
    const int = many(pDigit);
    let value = reduceNumbers(int, 10);

    if (!pToken(tokenDot)) {
      if (int.length !== 0) {
        return ok<AtExprConstant>({ type: "constant", value });
      }
      return expectedDigit;
    }
    const decimal = many(pDigit);
    if (int.length === 0 && decimal.length === 0) return expectedDigit;

    let factor = 0.1;
    for (const part of decimal) {
      value += part * factor;
      factor /= 10;
    }
    return ok<AtExprConstant>({ type: "constant", value });
  }

  function pVariable(): Result<AtExprVariable<UnresolvedVar>> {
    const letters = some(pLetter);
    if (letters.type !== "ok") return letters;
    const digits = some(pDigit);
    if (digits.type !== "ok") return digits;
    const letterValue = reduceNumbers(letters.value, 26);
    const digitValue = reduceNumbers(digits.value, 10);
    return ok<AtExprVariable<UnresolvedVar>>({ type: "variable", reference: { category: letterValue, question: digitValue }});
  }

  function pParenthesized(): Result<AtExpression<UnresolvedVar>> {
    const open = pToken(tokenParenthesisOpen);
    if (open.type !== "ok") return open;
    const result = pAddition();
    if (result.type !== "ok") return result;
    const close = pToken(tokenParenthesisClose);
    if (close.type !== "ok") return close;
    return result;
  }

  function pSimple(): Result<AtExpression<UnresolvedVar>> {
    return orGreedy<AtExpression<UnresolvedVar>>(pConstant, pVariable, pParenthesized);
  }

  function pUnary(): Result<AtExpression<UnresolvedVar>> {
    if (pToken(tokenMinus).type === "ok") {
      spaces();
      const operand = pUnary();
      spaces();
      if (operand.type !== "ok") return operand;
      return ok<AtExprPrefixUnary<UnresolvedVar>>({ type: "prefix-unary", operator: "-", operand: operand.value });
    }
    return pSimple();
  }

  function pBinary(pSub: Parser<AtExpression<UnresolvedVar>>, token1: number, operator1: "*" | "+", token2: number, operator2: "/" | "-"): Result<AtExpression<UnresolvedVar>> {
    const first = pSub();
    if (first.type !== "ok") return first;
    let expression = first.value;

    while (true) {
      spaces();
      let operator: "*" | "/" | "+" | "-" | undefined;

      if (token === token1) {
        operator = operator1;
      } else if (token === token2) {
        operator = operator2;
      } else {
        break;
      }

      next();

      spaces();

      const result = pSub();
      if (result.type !== "ok") return result;
      expression = {
        type: "binary",
        operator,
        left: expression,
        right: result.value,
      };
    }

    return ok(expression);
  }

  function pMultiply() {
    return pBinary(pUnary, tokenMultiply, "*", tokenDivide, "/");
  }
  function pAddition() {
    return pBinary(pMultiply, tokenAdd, "+", tokenMinus, "-");
  }

  function next() {
    index++;
    token = source.charCodeAt(index);
  }

  function orGreedy<T>(...parsers: Parser<T>[]): Result<T> {
    const oldIndex = index;
    const failures: Fail[] = [];
    for (const p of parsers) {
      const result = p();
      if (result.type === "ok") return result;
      if (index !== oldIndex) {
        // `index` has changed, so `p` consumed some input. As this is a greedy variant of 'or',
        // we consider this as a failure.
        return result;
      }
      failures.push(result);
    }
    return { type: "fail-list", list: failures };
  }

  function pTry<T>(parser: Parser<T>): Result<T> {
    const oldIndex = index;
    const oldToken = token;
    const result = parser();
    if (result.type !== "ok") {
      const fail: Fail = {
        type: "fail-at-index",
        index,
        fail: result,
      };
      index = oldIndex;
      token = oldToken;
      return fail;
    }
    return result;
  }

  function pToken(char: number): Result<number> {
    if (token === char) {
      next();
      return { type: "ok", value: char };
    } else {
      return { type: "fail", expected: char };
    }
  }

  function pDigit(): Result<number> {
    if (token >= token0 && token <= token9) {
      const value = token - token0;
      next();
      return { type: "ok", value };
    } else {
      return expectedDigit;
    }
  }
  function pLetter(): Result<number> {
    if (token >= tokenA && token <= tokenZ) {
      const value = token - tokenA + 1;
      next();
      return { type: "ok", value };
    } else if (token >= tokenCapitalA && token <= tokenCapitalZ) {
      const value = token - tokenCapitalA + 1;
      next();
      return { type: "ok", value };
    } else {
      return expectedLetter;
    }
  }

  function spaces() {
    while (token === tokenSpace || token === tokenSpaceTab || token === tokenSpaceNewline) {
      next();
    }
  }

  function many<T>(p: Parser<T>) {
    const values: T[] = [];
    while (true) {
      const result = p();
      if (result.type === "ok") {
        values.push(result.value);
      } else {
        break;
      }
    }
    return values;
  }
  function some<T>(p: Parser<T>): Result<T[]> {
    const head = p();
    if (head.type !== "ok") return head;
    const tail = many(p);
    return { type: "ok", value: [head.value, ...tail] };
  }

  function reduceNumbers(numbers: number[], factor: number) {
    let result = 0;
    for (const part of numbers) {
      result = result * factor + part;
    }
    return result;
  }
}
