import { TextTranslations } from "../interface";

export let nl: TextTranslations = {
  menu: {
    menu: "Menu",
    logout: "Uitloggen",
    copyright: "Copyright",
  },
  actions: {
    ok: "Ok",
    back: "Terug",
    close: "Sluiten",
    show: "Bekijk",
    save: "Opslaan",
    cancel: "Annuleren",
    select: "Selecteer",
    edit: "Bewerk",
    delete: "Verwijder",
  },
  notices: {
    loading: "Laden...",
    connecting: "Verbinden...",
    no_connection: "Geen verbinding",
    change_is_being_applied: "De wijziging wordt toegepast...",
    dashboard_no_permission: "U heeft (nog) geen rechten om het dashboard te bekijken.",
    dashboard_manage_no_dashboard: "Uw organisatie heeft (nog) geen dashboard. Druk op 'bewerk' om een dashboard aan te maken.",
  },
  languages: {
    en: "Engels",
    nl: "Nederlands",
  },
  dashboard: {
    time_period: {
      short: {
        last_months: {
          12: "12 maanden",
          6: "6 maanden",
          3: "3 maanden",
        },
        custom: "Aangepast",
      },
      full: {
        last_months: {
          12: "Laatste 12 maanden",
          6: "Laatste 6 maanden",
          3: "Laatste 3 maanden",
        },
        custom: "Aangepast",
      },
    },
    months: {
      short: ["Jan", "Feb", "Mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Sept", "Okt", "Nov", "Dec"],
      full: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
    },
    item: {
      trend: "Trend",
      benchmark: "Benchmark",
      norm: "Norm",
      sources: "Bronnen",
      redflags: "Red flags",
      no_data: "Geen gegevens te tonen",
    },
    chart_type: {
      number: "Getal",
      traffic_light: "Stoplicht",
      bar: "Staafdiagram",
      trend: "Trendgrafiek",
    },
    chart_date_period: {
      month: "Maand",
      quarter: "Kwartaal",
      year: "Jaar",
    },
    personalize: {
      title: "Personaliseren",
      description: "Versleep de items om de volgorde aan te passen. Een item kan versleept worden door er op te klikken of tappen, de muisknop ingedrukt te houden of de vinger op het scherm te houden en het te verslepen naar de nieuwe plek.",
      delete: "Verwijder al mijn voorkeuren",
    },
    filters: {
      filters: "Filters",
      no_filters: "Er kunnen nu geen filters ingesteld worden. Als er gegevens van meerdere locaties, diensten of organisaties in het dashboard staan, kunt u hier filters kiezen.",
      no_options_available: "Geen opties beschikbaar",
      client: "Opdrachtgever",
      supplier: "Opdrachtnemer",
      service: "Dienst",
      location: "Locatie",
      category: "Audit categorie",
      remove: "Verwijder filters",
    },
    sources: {
      audit: "Audit",
      at: "GDP",
      location: "Locatie",
      date: "Datum",
      score: "Score",
      audit_one: "1 audit",
      audits: "audits",
      at_one: "1 GDP",
      ats: "GDPs",
      no_data: "Er zijn geen gegevens voor deze grafiek, met de huidige filters",
      go_to_measurement: "Ga naar meting",
      audit_type: "Soort",
      audit_no_rejected_requirements: "Geen afgekeurde eisen.",
      audit_rejected_requirements: "Afgekeurde eisen",
      audit_weight: "Weging",

      at_questions: "GDP vragen",
      at_value: "Waarde",
      at_show_history: "Toon geschiedenis",

      loading_more_data: "Meer gegevens worden ingeladen...",
      no_permission_audit: "Je hebt geen rechten om deze audit te zien.",
      no_permission_at: "Je hebt geen rechten om deze GDP te zien.",
    },
    redflags: {
      critical_requirement_one: "1 kritieke eis afgekeurd",
      critical_requirements: "kritieke eisen afgekeurd",
      critical_requirements_none: "Geen kritieke eisen afgekeurd",
      locations: "Locaties",
      requirement: "Eis",
      count_red_flags: "Aantal red flags",
      loading_more_data: "Meer gegevens worden ingeladen...",
      no_permission_one: "Deze eis is afgekeurd in een meting waar je geen rechten voor hebt.",
      no_permission_multiple: "Deze eis is afgekeurd in metingen waar je geen rechten voor hebt.",
    },
  },
  dashboard_manage: {
    actions: {
      edit_motto: "Wijzig motto",
      edit_title: "Wijzig titel van item",
      edit_filters: "Wijzig filters voor bron van item",
      edit_unit_weights: "Wijzig eenheid en gewichten van items in map",
      edit_at_formula: "Wijzig GDP-formule van item",
      edit_charts: "Wijzig grafieken van item",
      edit_norm_trend: "Wijzig norm en trend",
      delete_item: "Verwijder huidig item",
      set_benchmark: "Stel benchmark in",
      add_item: "Voeg item toe",
      directory_layout_fixed: "Layout: alle items zelfde grootte",
      directory_layout_fluent: "Layout: dynamische groottes",
    },
    tools: {
      title: {
        caption: "Titel aanpassen",
      },
      add_item: {
        caption: "Item toevoegen",
        description: "Kies de bron voor dit item:",
        directory: "Map",
        audit: "Audit",
        at: "GDP",
      },
      edit_weights: {
        caption: "Gewichten van items in deze map aanpassen",
        unit: "Eenheid",
        decimals: "Aantal decimalen",
      },
      charts: {
        caption: "Grafieken beheren",
        first_as_default: "Gebruik standaard grafiek als eerste detail grafiek",
        no_detail_chart: "Toon geen detail grafieken",
      },
      edit_chart: {
        caption: "Grafiek",
        min: "Minimum",
        max: "Maximum",
        transition_green_orange: "Grens groen-oranje",
        transition_orange_red: "Grens oranje-rood",
        chart_type: "Grafiek type",
        split_on: "Splits op",
        info: "Decimale getallen kunnen met een punt ingevuld worden, zoals 7.5.",
      },
      edit_filters: {
        item_caption: "Bewerk bronnen voor item",
        benchmark_caption: "Bewerk filters voor benchmark",
        all: "alle",
        selected: "geselecteerd",
        choose_filters_for: "Stel filters in voor",
        info_no_selection: "Als u niks selecteert, wordt er hierop niet gefilterd.",
      },
      edit_formula: {
        caption: "Bewerk GDP-formule voor item",
        expression_may_contain: "De expressie mag bestaan uit:",
        expression_number: "Getallen",
        expression_questions: "GDP vragen (voorbeeld: A4)",
        expression_arithmetics: "Som, vermenigvuldiging, deling en verschil (voorbeelden: A1 - 1, 2 * B3)",
        expression_parentheses: "Haakjes (voorbeeld: (A1 + 3) * 2)",
        valid_expression: "Geldige expressie",
        parse_error: "Formule kon niet gelezen worden",
        expected_on_position_left: "Op positie",
        expected_on_position_right: "werd verwacht:",
        eof: "Einde van de invoer",
      },
      edit_norm_trend: {
        norm_caption: "Norm",
        trend_caption: "Trend",
        trend_description: "De trend wordt gebasseerd op de waarde van de huidige periode en de vorige periode. Deze periode is in te stellen als een maand, kwartaal of jaar.",
        no_trend: "Toon geen trend",
      },
      set_benchmark: {
        caption: "Benchmark instellen",
        no_benchmark: "Er is nog geen benchmark ingesteld.",
        add_benchmark_source: "Voeg extra bron toe aan benchmark",
        add_audit_source: "Voeg audit bron toe",
        add_at_source: "Voeg GDP bron toe",
        add_constant_source: "Voeg constante waarde toe",
        audit: "Audit",
        at: "GDP",
        constant: "Constante waarde",
        edit_weight_period: "Wijzig weging en periode",
        edit_filters: "Wijzig filters",
        edit_at_formula: "Wijzig GDP formule",
      },
      edit_benchmark_item: {
        caption: "Benchmark aanpassen",
        value: "Waarde",
        weight: "Weging",
        period: "Periode",
      },
    },
  },
  export: {},
  search: {
    date: {
      min: "Tussen",
      max: "en",
      from: "Vanaf",
      till: "Tot",
    },
  },
};
