export function equal<T>(a: T[] | undefined | null, b: T[] | undefined | null) {
  if (a === b) return true;
  if (a == null) return false;
  if (b == null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function modifyAt<T>(a: T[], index: number, fn: (item: T) => T) {
  return a.map((x, i) => i === index ? fn(x) : x);
}

export function countWhere<T>(xs: T[], where: (x: T) => boolean): number {
  let count = 0;
  for (const x of xs) {
    if (where(x)) count++;
  }
  return count;
}

export function objectFromList<T, U extends string, V>(array: T[], key: (item: T) => U, value: (item: T) => V): { [_ in U]: V } {
  const obj: { [_ in U]?: V } = {};
  for (const item of array) {
    obj[key(item)] = value(item);
  }
  return obj as { [_ in U]: V };
}

export function removeDuplicates<T extends number | string>(list: T[]) {
  const result: T[] = [];
  let previous: T | undefined;
  for (const item of [...list].sort()) {
    if (previous === item) continue;
    previous = item;
    result.push(item);
  }
  return result;
}
