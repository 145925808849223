import { Completer, forEachWord } from "./search-completions";
import { DateSearchIndexers, DateSearchers } from "./search-date";

export interface RowIndexer {
  dateIndexers: DateSearchIndexers,
  index(row: number, column: number, content: string): void,
  finish(): RowSearcher,
}

export interface RowSearcher {
  search(queries: (string | [Date | undefined, Date | undefined] | undefined)[]): void,
  unmount(): void,
}

export interface RowSearchDelegate {
  reset(isSearching: boolean): void,
  result(index: number): void,
  finish(): void,
}

export function createRowIndexer(completers: Completer[], dateIndexers: DateSearchIndexers, delegate: RowSearchDelegate): RowIndexer {
  const columns = completers.length;
  const maps: Map<string, number[]>[] = [];
  for (let i = 0; i < columns; i++) {
    maps[i] = new Map();
  }

  return { index, dateIndexers, finish };

  function index(row: number, column: number, text: string) {
    forEachWord(text, (word) => {
      const map = maps[column];
      const list = map.get(word);
      if (list === undefined) {
        map.set(word, [row]);
      } else if (list[list.length - 1] !== row) {
        list.push(row);
      }
    });
  }

  function finish() {
    const binaryMaps: Map<string, Uint32Array>[] = [];
    const dateSearchers: DateSearchers = {};
    for (let i = 0; i < columns; i++) {
      binaryMaps[i] = new Map();
      if (dateIndexers[i] === undefined) {
        for (const [key, list] of maps[i]) {
          binaryMaps[i].set(key, new Uint32Array(list));
        }
      } else {
        dateSearchers[i] = dateIndexers[i]!.finish();
      }
    }
    return createRowSearcher(completers, binaryMaps, dateSearchers, delegate);
  }
}

const maxInt = 2 ** 31 - 1;

export function createRowSearcher(completers: Completer[], maps: Map<string, Uint32Array>[], dateSearchers: DateSearchers, delegate: RowSearchDelegate): RowSearcher {
  // Two dimensional list represents a conjunction (AND / intersection) of disjunctions (OR / union)
  // The search computes the intersection of the unions, in a streaming way.
  let currentQuery: Uint32Array[][] | undefined;

  // Current progress of the search
  let currentRow = 0;
  // Indices for all Uint32Arrays in currentQuery
  let indices: number[][] = [];
  let isScheduled = false;
  let isBusy = false;
  let startTime = 0;

  // The first results are delayed, until there are enough results to fill the table.
  // This prevents flickering for the user.
  let shouldDelay = true;
  let delayedResults: number[] = [];

  return { search, unmount };

  function unmount() {
    currentQuery = undefined;
    isBusy = false;
  }

  function search(queries: (string | [Date | undefined, Date | undefined] | undefined)[]) {
    let isSearching = false;
    for (let column = 0; column < queries.length; column++) {
      const q = queries[column];

      if (!(q === "" || q === undefined || (typeof q !== "string" && q[0] === undefined && q[1] === undefined))) {
        isSearching = true;
        break;
      }
    }

    if (!isSearching) {
      currentQuery = undefined;
      isBusy = false;
      delegate.reset(false);
      return;
    }

    currentQuery = [];
    indices = [];

    for (let column = 0; column < queries.length; column++) {
      const query = queries[column];
      if (query === undefined) continue;
      if (typeof query !== "string") {
        if (query[0] === undefined && query[1] === undefined) continue;
        const dateSearcher = dateSearchers[column];
        if (dateSearcher === undefined) continue;
        const list = [dateSearcher.search(query[0], query[1])];
        const listIndices = [0];
        currentQuery.push(list);
        indices.push(listIndices);
        continue;
      }

      forEachWord(query, (text) => {
        if (text === "") return;

        const list: Uint32Array[] = [];
        const listIndices: number[] = [];
        currentQuery!.push(list);
        indices.push(listIndices);

        const words = completers[column].query(text);
        if (words === undefined) return;
        for (const word of words) {
          const array = maps[column].get(word);
          if (array !== undefined) {
            list.push(array);
            listIndices.push(0);
          }
        }
      });
    }

    isBusy = true;
    currentRow = 0;
    shouldDelay = true;
    delayedResults = [];
    startTime = Date.now();
    schedule();
  }

  function schedule() {
    if (isScheduled) return;
    requestAnimationFrame(work);
    isScheduled = true;
  }

  function work() {
    isScheduled = false;
    const end = Date.now() + 7;

    // If results are currently delayed, push them if we have enough or if we spent too much time or if we are finished
    if (shouldDelay && (delayedResults.length >= 10 || Date.now() - startTime >= 300 || !isBusy)) {
      shouldDelay = false;
      delegate.reset(true);
      for (let i = 0; i < delayedResults.length; i++) {
        delegate.result(delayedResults[i]);
      }
      if (!isBusy) {
        delegate.finish();
      }
    }

    while (isBusy && Date.now() <= end) {
      step();
    }

    if (isBusy || shouldDelay) schedule();
  }

  function step() {
    if (currentQuery === undefined) return;

    let next = 0;
    let isMatch = true; // Is `currentRow` a match?

    for (let i = 0; i < currentQuery.length; i++) {
      const disjunction = currentQuery[i];
      let min = maxInt;
      // Did some set in this disjunction match with 'currentRow'?
      let someMatch = false;

      for (let j = 0; j < disjunction.length; j++) {
        const set = disjunction[j];
        let index = indices[i][j];

        // Skip all rows before `currentRow`
        while (index < set.length && set[index] < currentRow) index++;

        // Check `currentRow`
        if (index < set.length && set[index] === currentRow) {
          index++;
          someMatch = true;
        }

        indices[i][j] = index;

        if (index !== set.length && set[index] < min) min = set[index];
      }

      if (!someMatch) isMatch = false;

      // Rows before `min` do not match this disjunction, so we must skip those.
      if (min > next) next = min;
    }

    if (isMatch) {
      if (shouldDelay) {
        delayedResults.push(currentRow);
      } else {
        delegate.result(currentRow);
      }
    }

    currentRow = next;
    if (next === maxInt) {
      isBusy = false;
      if (!shouldDelay) delegate.finish();
    }
  }
}
