import { Dispatch } from "../utils/state";
import { SocketState } from "./model";
import * as protocol from "./protocol";

export interface SocketDelegate {
  changeSocketState(state: SocketState): void,
  message(message: protocol.ServerMessage): void,
  reload(hard: boolean): void,
}

export interface Socket {
  send(message: protocol.ClientMessage): void,
  setActive(active: boolean): void,
}

export function createSocket(delegate: SocketDelegate): Socket {
  let socket: WebSocket | undefined;
  let connectFails = 0;
  let queue: protocol.ClientMessage[] = [];
  let isActive = false;

  return { send, setActive };

  // Connect or reconnect to the server.
  function connect() {
    if (!isActive) return;

    if (socket !== undefined) {
      if (socket.readyState !== WebSocket.CLOSED) return;
      // Remove event listeners from old socket
      release();
    }

    socket = new WebSocket((location.protocol === "https:" ? "wss://" : "ws://") + location.hostname + "/socket");

    // Attach event listeners
    socket.addEventListener("open", onOpen);
    socket.addEventListener("message", onMessage);
    socket.addEventListener("error", onError);
    socket.addEventListener("close", onClose);

    // Notify delegate of state change
    delegate.changeSocketState(SocketState.Connecting);
  }

  function release() {
    if (socket === undefined) return;

    // Remove event listeners to prevent memory leaks
    socket.removeEventListener("open", onOpen);
    socket.removeEventListener("message", onMessage);
    socket.removeEventListener("error", onError);
    socket.removeEventListener("close", onClose);

    socket = undefined;
  }

  // Send a message to the server
  function send(message: protocol.ClientMessage) {
    if (socket !== undefined && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      queue.push(message);
    }
  }

  function onOpen(event: Event) {
    connectFails = 0;
    delegate.changeSocketState(SocketState.Open);

    const oldQueue = queue;
    queue = [];
    for (const message of oldQueue) {
      send(message);
    }
  }

  function onClose(event: CloseEvent) {
    if (event.code === protocol.ServerCloseCode.NotAuthenticated) {
      delegate.reload(true);
      return;
    } else if (event.code === protocol.ServerCloseCode.OtherUser) {
      delegate.reload(false);
    }

    // Ignore `close` event if the socket is not active
    if (!isActive) {
      release();
      return;
    }

    delegate.changeSocketState(SocketState.Closed);
    // Try to reconnect.
    // If reconnect fails, we add a delay of 100 * 2 ^ connectFails ms
    // After several attempts, we restrict this delay to 5 seconds.
    setTimeout(connect, Math.min(100 * (1 << connectFails), 5000));
    connectFails++;
  }

  function onError(event: Event) {
    console.log("Socket error", event);
  }

  function onMessage(event: MessageEvent) {
    delegate.message(JSON.parse(event.data));
  }

  function setActive(active: boolean) {
    if (isActive === active) return;

    isActive = active;

    if (isActive) {
      connect();
    } else if (socket !== undefined) {
      socket.close();
    }
  }
}
