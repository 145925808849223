// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import * as menu from "../menu";
import * as htmlUtils from "../utils/html";
import * as dashboard from "../dashboard";
import * as tableSearch from "../table/search";
import * as exportSpreadsheet from "../export/spreadsheet";
import { clickMobileTableRow, actionPopupClose } from "./mobile";

let onRender: () => void;
let onRenderElement: (element: HTMLElement) => void;
let bodyClassName = "";

export const isIE = window.navigator.userAgent.indexOf("Trident/") !== -1;

export function init() {
  bodyClassName = document.body.className;
}

export function onload(callbackOnRender: () => void, callbackOnRenderElement: (element: HTMLElement) => void) {
  onRender = callbackOnRender;
  onRenderElement = callbackOnRenderElement;
  if ((window as any).fpmShouldReload) {
    visit(location.href, undefined, undefined, false);
  }
  if (historyEnabled()) {
    // Attach event listeners
    // We listen on `window` instead of all links, forms and buttons.
    // We use event propagation to find the clicked link or form.
    document.body.addEventListener("click", onClick, false);
    window.addEventListener("submit", onSubmit, false);
    window.addEventListener("popstate", onPopState);

    // We re-implemented some functionality of jquery-ujs, as it didn't work
    // with our custom navigation.

    // Handling links with a `data-method` attribute in implemented
    // in `onClick`, so we don't have to rely on jquery-ujs
    ($ as any).rails.handleMethod = ($element: JQuery) => {
      const element = $element[0];
      onClickMethod(element as HTMLAnchorElement, element.getAttribute("data-method")!);
    };
    // We implemented the `confirm` action ourselves in `handleConfirm`, so we don't have to
    // rely on jquery-ujs here.
    $("body").on("confirm", (e) => {
      e.result = false;
    });
  }
  resize();
}

// Disable if current page is rendered with POST.
// A refresh will refresh a page with POST, which is probably not desired
function historyEnabled() {
  // Browser should support history API
  return window.history !== undefined
    && history.pushState !== undefined
    // It should support FormData
    && typeof FormData !== "undefined"
    // The current request should be done with GET
    && (window as any).fpmVerbIsGet === true;
}

function isInternalLink(link: string) {
  const js = "javascript:";

  // A link is internal if it does not start with `javascript:`
  return link.substring(0, js.length) !== js
    // and it doesn't start with a hash
    && link.charAt(0) !== "#"
    // and it is not cross domain
    && !($ as any).rails.isCrossDomain(link);
}

// Called when the window resizes
export function resize() {
  setBodyClassName();
  const mobile = isMobile();
  if (!mobile) actionPopupClose();

  if (menu.getState().isMobile !== mobile) {
    menu.update({ isMobile: mobile }, true);
  }
}

// Fallback to normal behavior when something fails
let didFail = false;

// Shows a confirm box if the specified element has a `data-confirm`
// attribute. Returns `false` if the user pressed no, `true` otherwise.
function handleConfirm(element: HTMLElement) {
  const message = element.getAttribute("data-confirm");
  return message === null || window.confirm(message);
}

function onClick(e: MouseEvent) {
  // We cannot return immediately if `didFail` is true,
  // since we need to handle links with `data-method`.
  if (e.defaultPrevented) return;

  // Users wants to open the page in a new tab, so we do not need to do anything here.
  if (e.ctrlKey || e.metaKey) return;

  if (loading) {
    e.preventDefault();
    e.stopPropagation();
  }

  // Check if the user clicked on a link.
  const element = htmlUtils.findParent(e.target as HTMLElement, "A") as HTMLAnchorElement | undefined;
  if (element !== undefined && element.hasAttribute("href")) {
    if (!handleConfirm(element)) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    // Default to normal behavior for external links
    if (!isInternalLink(element.getAttribute("href")!)) return;
    if (element.hasAttribute("data-no-partial")) return;

    const method = element.getAttribute("data-method") || "get";

    if (method === "get") {
      if (!didFail) {
        visit(element.href);

        e.preventDefault();
      }
    } else {
      onClickMethod(element, method);
      e.preventDefault();
      e.stopPropagation();
    }
    return;
  }

  if (didFail) return;

  // Check if the user pressed the submit button of a form
  const submit = (htmlUtils.findParent(e.target as HTMLElement, "BUTTON") || htmlUtils.findParent(e.target as HTMLElement, "INPUT")) as HTMLButtonElement | HTMLInputElement | undefined;
  if (submit && (submit.getAttribute("type") || "").toUpperCase() === "SUBMIT") {
    const form = htmlUtils.findParent(submit, "FORM") as HTMLFormElement | undefined;
    if (form !== undefined) {
      // Note that we need to pass the submit button, since the submit button should be a part of the query.
      submitForm(form, submit);
      e.preventDefault();
      e.stopPropagation();
    }
  }

  const td = htmlUtils.findParent(e.target as HTMLElement, "TD");
  if (td !== undefined && (e.target as HTMLElement).tagName !== "INPUT") {
    if (td.className.split(" ").indexOf("td-full-width") === -1) {
      const action = clickMobileTableRow(td.parentElement as HTMLTableRowElement);
      if (!action && td.parentElement!.className.split(" ").indexOf("table-select-row") !== -1) {
        const input = td.parentElement!.getElementsByTagName("input")[0];
        if (input !== undefined) input.click();
      }
    }
  }
}

// Handles a submit event. Checks for a form and submits is with `submitForm`.
function onSubmit(e: Event) {
  if (didFail) return;

  const element = htmlUtils.findParent(e.target as HTMLElement, "FORM") as HTMLFormElement | undefined;
  if (element !== undefined) {
    submitForm(element);
    e.preventDefault();
    e.stopPropagation();
  }
}

let fakeForm: HTMLFormElement | undefined;

// Handles a click on a link with a `data-method` attribute.
// This will create a form with the specified method and submit it.
function onClickMethod(element: HTMLAnchorElement, method: string) {
  if (fakeForm !== undefined) {
    fakeForm.parentNode!.removeChild(fakeForm);
  }

  const form = document.createElement("form");
  form.action = element.href;

  form.target = element.target;

  // Note that we always submit the form with POST,
  // and add an input which sets _method to the specified method.
  form.method = "POST";
  htmlUtils.addInput(form, "_method", method);

  // If needed, add the csrf token to the form.
  // This adds a protection for cross-site scripting.
  const csrfParam = ($ as any).rails.csrfParam();
  const csrfToken = ($ as any).rails.csrfToken();

  if (csrfParam !== undefined && csrfToken !== undefined && !($ as any).rails.isCrossDomain(element.href)) {
    htmlUtils.addInput(form, csrfParam, csrfToken);
  }

  // Add form to document. Some browsers require this for calling `form.submit()`.
  form.style.display = "none";
  document.body.appendChild(form);
  fakeForm = form;

  // If something has failed, we default to normal behavior.
  if (didFail) {
    form.submit();
  } else {
    // Otherwise, we will dynamically load this request.
    submitForm(form);
  }
}

// Submits a form. It tries to use XHR for navigation. Optionally a button can be specified,
// the button will be passed in the query.
function submitForm(element: HTMLFormElement, button?: HTMLButtonElement | HTMLInputElement) {
  if (loading) return;
  if (didFail || !isInternalLink(element.action) || element.action === "/users/sign_out" || element.action === getRoot() + "/users/sign_out" || element.getAttribute("data-partial") === "false") {
    // If the form links to an external url, we should default to normal navigation.
    // If something failed before, we will also default to the normal navigation.
    if (button !== undefined) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = button.name;
      input.value = button.value;
      element.append(input);
      element.submit();
      element.removeChild(input);
    } else {
      element.submit();
    }
    return;
  }

  if ((element.getAttribute("method") || "GET").toUpperCase() === "GET") {
    // In case of a GET form, we should serialize the form to a query string
    let url = element.action;
    if (url.indexOf("?") === -1) {
      url += "?";
    } else {
      url += "&";
    }

    const query = $(element).serializeArray();
    if (button && button.name) {
      // We must manually add the submit button
      query.push({ name: button.name, value: button.value });
    }

    url += jQuery.param(query);

    // Url is constructed, navigate to that url
    visit(url);
  } else {
    // POST form
    visit(element.action, element, button);
  }
}

// Invoked when the user pressed the back-button
function onPopState(e: PopStateEvent) {
  if (didFail) return;

  let path = document.location.pathname + document.location.search;
  if (document.location.hash !== "") {
    path += "#" + document.location.hash;
  }
  visit(path, undefined, undefined, false);
}

let xhr: XMLHttpRequest | undefined;

// Cancels the pending XMLHttpRequest
function cancel() {
  if (xhr !== undefined) {
    xhr.abort();
    xhr = undefined;
  }
}

export function setDashboardPath(hash: string, title: string, action: "push" | "replace" | "none") {
  const path = "/#!" + hash;
  if (action === "push") {
    history.pushState({}, title, path);
  } else if (action === "replace") {
    history.replaceState({}, title, path);
  }
  document.title = title;
  dashboard.setPath();
}

function getRoot() {
  return location.protocol + "//" + location.host;
}

// Visits a URL. If a form is passed, the content of that form is passed as the body of a POST request.
// If the formButton is passed, this button is added to the form data.
export function visit(path: string, form?: HTMLFormElement, formButton?: HTMLButtonElement | HTMLInputElement, pushHistory = true) {
  cancel();

  let hash = "";
  const hashIndex = path.indexOf("#");
  if (hashIndex !== -1) {
    hash = path.substring(hashIndex + 1);
    path = path.substring(0, hashIndex);
  }

  const root = getRoot();
  if (path.substring(0, root.length) === root) {
    path = path.substring(root.length);
  }

  if (path === "/" && menu.isLoggedIn() && hash.substring(0, 1) === "!") {
    setDashboardPath(hash.substring(1), "Home", pushHistory ? "push" : "none");
    return;
  }

  const method = (form !== undefined && form.method) || "get";
  const data = form === undefined ? undefined : new FormData(form);

  if (data !== undefined && formButton !== undefined) {
    data.append(formButton.name, formButton.value);
  } else if (isIE && data !== undefined) {
    // IE10 and IE11 support FormData, but fail when the last input
    // of a form is a checkbox and is unchecked...
    // See https://blog.yorkxin.org/2014/02/06/ajax-with-formdata-is-broken-on-ie10-ie11
    // A simple fix is to set an additional field to the form
    data.append("_ie_fix", "");
  }

  if (!isIE) {
    // Safari had a bug, which caused that no request could be made with unselected file inputs.
    // See https://trac.webkit.org/changeset/230963/webkit
    // To prevent this issue, we will override those fields to an empty string.
    // However, this workaround does not work in Internet Explorer.
    if (form !== undefined && data !== undefined) {
      const files = form.querySelectorAll('input[type="file"]');
      for (let i = 0; i < files.length; i++) {
        const file = files[i] as HTMLInputElement;
        if (file.files === null || file.files.length === 0) {
          data.set(file.name, "");
        }
      }
    }
  }

  // Show that the page is loading
  startLoading();

  let lastIndex = 0;
  let streamElement: HTMLElement | null = null;

  // Initialize XHR and attach event listeners
  xhr = new XMLHttpRequest();
  xhr.addEventListener("readystatechange", onData);
  xhr.addEventListener("error", error);

  let redirectedPath: string = path;

  xhr.open(method, path);
  // Pass a header such that the server knows that the page should be rendered partially.
  xhr.setRequestHeader("X-RENDER-PARTIAL", "true");
  xhr.send(data);

  // Invoked when the XHR is in progress or finished
  function onData() {
    if (xhr!.readyState < 3) return;
    const finished = xhr!.readyState === 4;

    if (xhr!.status !== 200) {
      error();
      return;
    }

    if (lastIndex > 0) {
      addStreamingContent(finished);
      return;
    }

    const text = xhr!.responseText;
    const split = text.indexOf("<<//>>");

    if (split === -1) {
      if (finished) error();
      return;
    }

    lastIndex = split + 6;

    const main = text.substring(0, split);

    // Parse response
    const element = document.createElement("div");
    element.innerHTML = main;

    // Find tags
    const elements: { [id: string]: HTMLElement } = {};
    for (let i = 0; i < element.children.length; i++) {
      const child = element.children[i];
      elements[child.id] = child as HTMLElement;
    }

    // Verify that all required fields are passed in the output
    for (const field of ["url", "title", "content", "body-top", "head", "menu", "body-class"]) {
      if (get(field) === undefined) {
        redirectedPath = xhr!.responseURL;
        error();
        return;
      }
    }

    // Update content
    setContent(
      pushHistory,
      get("url").textContent!,
      get("title").textContent!,
      get("content").childNodes,
      get("body-top").childNodes,
      get("head").childNodes,
      get("menu").textContent!,
      get("body-class").textContent!,
    );

    // The DOM element where the content is streamed.
    // Streaming can be provided in Rails by the `table_rows` helper.
    // Additional elements are added in front of this element.
    streamElement = document.getElementById("content-streaming");

    // Consume additional streamed content if possible.
    addStreamingContent(finished);

    function get(key: string) {
      return elements[`partial-${ key }`];
    }
  }

  // Invoked when the XHR fails or when the XHR succeeds with invalid content.
  function error() {
    if (!loading) return;
    stopLoading();

    redirectedPath = xhr!.responseURL || path;

    didFail = true;

    // Fallback to normal navigation

    if (form && (redirectedPath === path || redirectedPath === getRoot() + path)) {
      if (formButton) {
        formButton.click();
      } else {
        form.submit();
      }
    } else {
      window.location.assign(redirectedPath);
    }
  }

  // Consumes streamed content.
  // Chunks are seperated by <<//>>.
  function addStreamingContent(finished: boolean) {
    if (streamElement === null || streamElement.parentElement === null) return;

    let split = indexOf();

    let didInsert = false;

    while (split !== -1) {
      // Get the current chunk.
      const text = xhr!.responseText.substring(lastIndex, split);
      lastIndex = split + 6;

      // Parse it.
      const el = document.createElement(streamElement.parentElement.tagName);
      el.innerHTML = text;

      // Add before the `streamElement`.
      while (el.childNodes.length !== 0) {
        didInsert = true;
        const node = el.childNodes[0];
        streamElement.parentElement.insertBefore(node, streamElement);
        onRenderElement(node as HTMLElement);
      }

      split = indexOf();
    }

    if (finished) {
      const parent = streamElement.parentElement;
      if (parent) parent.removeChild(streamElement);
    }

    if ((didInsert || finished) && tableSearch.onStreamingNewRow) {
      tableSearch.onStreamingNewRow();
    }
    if (finished && exportSpreadsheet.exportSpreadsheetCallback) {
      exportSpreadsheet.exportSpreadsheetCallback();
    }

    function indexOf() {
      const index = xhr!.responseText.indexOf("<<//>>", lastIndex);
      if (index === -1 && finished && lastIndex < xhr!.responseText.length) {
        return xhr!.responseText.length;
      }
      return index;
    }
  }
}

let loading = false;

function startLoading() {
  // Add `dirty` class that is used by css to gray out the page
  loading = true;
  setBodyClassName();
}

function stopLoading() {
  // Remove `dirty` class
  loading = false;
  setBodyClassName();
  actionPopupClose();
}

export function setBodyClassName() {
  let className = bodyClassName;
  if (loading) {
    className += " dirty";
  }
  if (isMobile()) {
    className += " mobile";
  }
  if (dashboard.visible()) {
    className += " page-dashboard";
    if (dashboard.isRoot()) {
      className += " page-dashboard-root";
    }
  }
  if (isIE) {
    className += " browser-internet-explorer";
  }
  document.body.className = className;
}

function setContent(pushHistory: boolean, url: string, title: string, content: NodeList,
  bodyTop: NodeList, head: NodeList, menuState: string, newBodyClassName: string) {
  window.scrollTo({ top: 0 });

  // Set body className
  // The new class name will be propagated by `stopLoading`.
  bodyClassName = newBodyClassName;

  if (pushHistory) {
    // Add to browser history
    history.pushState({}, title, url);
  }

  // Set title
  document.title = title;

  // Set main content
  replaceChildren(document.getElementById("main")!, content);

  // Set body top
  replaceChildren(document.getElementById("body-top")!, bodyTop);

  // Clear head
  const headStart = document.getElementById("head-custom-start")!;
  const headEnd = document.getElementById("head-custom-end")!;

  let headChild = headStart.nextSibling;
  while (headChild !== null && headChild !== headEnd) {
    const next = headChild.nextSibling;
    document.head.removeChild(headChild);
    headChild = next;
  }

  // Add children to head
  for (const child of nodeListToArray(head)) {
    document.head.insertBefore(child, headEnd);
  }

  // Update menu
  menu.update({ ...JSON.parse(menuState), languageDropdownVisible: false });

  onRender();
  onRenderElement(document.getElementById("main")!);

  stopLoading();
}

// Replace all children of an HTML element
function replaceChildren(element: HTMLElement, children: NodeList) {
  element.innerHTML = "";
  appendChildren(element, children);
}

// Append a list of nodes to an element
function appendChildren(element: HTMLElement, children: NodeList) {
  for (const child of nodeListToArray(children)) {
    element.appendChild(child);
  }
}

// Converts a NodeList to an array of Nodes.
function nodeListToArray(children: NodeList) {
  const array: Node[] = [];
  for (let i = 0; i < children.length; i++) {
    array.push(children[i]);
  }
  return array;
}

// Returns whether the page is shown on a small screen.
export function isMobile() {
  return document.documentElement.clientWidth <= 1024;
}

// Expose `submitForm` for use in views.
(window as any).navigationSubmitForm = submitForm;
// Expose `actionPopupClose`
(window as any).navigationCloseActionPopup = actionPopupClose;
