import * as protocol from "../protocol";
import { SourceDetailPopup, RedFlagPopup } from "../protocol/server";
import { Dashboard, getItemFromDashboard, Item, Root } from "./item";
import { ItemLayout } from "./layout";
import { FilterState, FieldFilters, UserFilterKeys } from "./filters";
import { ManageState } from "./manage";
import { UserPreferences } from "./personalize";

export type Locale = "nl" | "en";
export const locales: Locale[] = ["nl", "en"];
export function localesWith(locale: Locale) {
  return [locale, ...locales.filter((l) => l !== locale)];
}

export function filtersVisible(state: State, page?: Item | Root) {
  return state.activeTool === Tool.Filter || filtersForcedVisible(state, page);
}

export function filtersForcedVisible(state: State, page: Item | Root | undefined = getCurrentItem(state)) {
  if (state.filters.current.fields === undefined) return false;
  const { fields } = state.filters.current;
  const detailChart = page !== undefined && page.type === "item" && page.selectedDetailChart !== undefined ? page.detailCharts[page.selectedDetailChart] : undefined;
  const chart = detailChart === undefined ? undefined : detailChart.chart;

  return hasFilter("client") || hasFilter("supplier") || hasFilter("location") || hasFilter("service") || hasFilter("category");

  function hasFilter(key: UserFilterKeys) {
    if (chart !== undefined && chart.type === "bar" && chart.split === key) {
      return false;
    }
    return fields[key] !== undefined;
  }
}

export enum Visibility {
  Uninitialized,
  Hidden,
  Visible,
}

export enum SocketState {
  Connecting,
  Open,
  Closed,
}

export enum NoDashboardReason {
  Loading,
  NoPermission,
  NotFound,
}

export enum Tool {
  Filter,
  TimePeriod,
  Source,
  Export,
  Personalize,
  RedFlags,
}

export interface DragState {
  // Index of the item that we are dragging
  selected: number,

  // Index of the item that is hovered
  hover: { index: number, side: DragSide } | undefined,

  touch: {
    identifier: number,
    startX: number,
    startY: number,
    currentX: number,
    currentY: number,
    timeout: number | undefined,
  } | undefined,
}

// Checks whether the dragged item will be hidden at the current position
export function dragToHidden(state: State) {
  if (state.dashboard.type !== "dashboard" || state.path.length !== 0 || state.drag === undefined || state.drag.hover === undefined) return false;
  const { index, side } = state.drag.hover;
  const { firstHidden } = state.dashboard.root;
  return index > firstHidden || index === firstHidden && side === DragSide.Right;
}

export function isDragging(state: State) {
  if (state.drag === undefined) return false;
  if (state.drag.touch === undefined) return true;
  return state.drag.touch.timeout === undefined;
}

export enum DragSide {
  Left,
  Right,
}

export interface SourceDetailsState {
  type: "sources",
  audit: protocol.SourceDetail[],
  at: protocol.SourceDetail[],
  selected: number | undefined,
  popup: { type: "loading" } | SourceDetailPopup | undefined,
}

export interface RedFlagDetailsState {
  type: "redflags",
  redflags: protocol.RedFlagItem[],
  selected: number | undefined,
  popup: { type: "loading" } | RedFlagPopup | undefined,
}

export interface State {
  manage: ManageState | undefined,
  container: HTMLElement,
  visibility: Visibility,
  socketState: SocketState,
  dashboard: Dashboard | { type: "no-dashboard", reason: NoDashboardReason },
  activeTool: Tool | undefined,
  sourceDetails: { type: "loading" } | SourceDetailsState | undefined,
  redFlagDetails: { type: "loading" } | RedFlagDetailsState | undefined,
  path: string[],
  rootLayout: ItemLayout,
  itemLayouts: { [id: string]: ItemLayout },
  filters: FilterState,
  drag: DragState | undefined,
  showHiddenItems: boolean,
  width: number,
  windowHeight: number,
  user: {
    preferences: UserPreferences,
    shouldPush: boolean,
  },
}

export function getItem(state: State, id: string | undefined) {
  if (state.dashboard.type !== "dashboard") return undefined;
  return getItemFromDashboard(state.dashboard, id);
}

export function getCurrentItem(state: State) {
  if (state.dashboard.type !== "dashboard") return undefined;
  if (state.path.length === 0) return state.dashboard.root;
  const last = state.path[state.path.length - 1];
  return getItemFromDashboard(state.dashboard, last);
}

export const getSourceForItem = (state: State, item: Item) => {
  if (state.manage === undefined || state.manage.source === undefined) return undefined;
  return state.manage.source.sources[item.id];
};

export const getBenchmarkSourceForItem = (state: State, item: Item) => {
  if (state.manage === undefined || state.manage.source === undefined) return undefined;
  return state.manage.source.benchmarks[item.id];
};
