import * as React from "react";
import { Dispatch } from "../../utils/state";
import { State, Item, Chart, ChartState, Filters, Tool, RedFlagVisibility, getTrendSign } from "../model";
import * as action from "../action";
// Charts
import { NumberPicture } from "./numberchart";
import { BarPicture } from "./barchart";
import { TrafficLightPicture } from "./trafficlightchart";
import { translate } from "../../translations/get";
import { withUnit } from "../utils";
import { Glyphicon } from "../../utils/view";
import { TrendPicture } from "./trendchart";
import { t } from "../../translations";

export function ItemComponent({ dragging, draggable, index, item, x, dx, y, width, height, filters, dispatch }: { dragging: boolean, draggable: boolean, index: number, item: Item | undefined, x: number, dx: number, y: number, width: number, height: number, filters: Filters, dispatch: Dispatch<State> }) {
  if (item === undefined) {
    // Should never apply
    return <div></div>;
  }

  const headerHeight = Math.floor(Math.min(40, height / 6));
  const footerHeight = headerHeight;
  const bodyHeight = height - headerHeight - footerHeight;

  let className = "dashboard-chart";
  if (dragging) {
    className += " dashboard-chart-dragging";
  }

  const trend = getTrendSign(item);

  return (
    <div
      className={ className }
      onClick={ () => dispatch(action.openItem(item)) }
      style={{ left: x + "px", marginLeft: dx + "px", top: y + "px", width: width + "px", height: height + "px" }}
      draggable={ draggable && action.drag.dragApi !== "touch" }
      onDragStart={ (e) => {
        if (draggable) {
          // Firefox requires that you set some data.
          e.dataTransfer.setData('text', '');
          dispatch(action.drag.dragStart(index));
        }
      } }
      onDragEnd={ (e) => {
        e.preventDefault();
        dispatch(action.drag.dragCancel)
      } }
      onTouchStart={ (e) => dispatch(action.drag.touchStart(index, e, dispatch)) }
      onTouchMove={ (e) => dispatch(action.drag.touchMove(e)) }
      onTouchEnd={ (e) => dispatch(action.drag.touchEnd(e, true)) }
      onTouchCancel={ (e) => dispatch(action.drag.touchEnd(e, false)) } >

      <div className="dashboard-chart-header" style={{ height: headerHeight + "px", paddingTop: ((headerHeight - 20) / 2) + "px" }}>
      { 
        draggable
          ? <div className="dashboard-chart-header-icon" onClick={ (e) => { e.stopPropagation(); dispatch(action.drag.click); } }>
              <Glyphicon icon="move" />
            </div>
          : null
      }
        <h3>{ translate(item.title) }</h3>
      </div>
      <div className="dashboard-chart-body" style={{ height: bodyHeight + "px" }}>
        <ChartComponent item={ item } chart={ item.chart } width={ width } height={ bodyHeight } filters={ filters } isDetailChart={ false } dispatch={ dispatch } />
        <ChartMessage chart={ item.chart } top={ headerHeight + "px" } bottom={ footerHeight + "px"} />
      </div>
      <div className="dashboard-chart-footer" style={{ height: footerHeight + "px" }}>
        {
          item.norm !== undefined ? <div>{ t.dashboard.item.norm }: <span className={ "dashboard-value-" + (item.value === undefined ? "info" : (item.value > item.norm ? "success" : "danger")) }>{ withUnit(item.norm, item.chart.unit, item.decimals) }</span></div> : null
        }
        {
          item.hasBenchmark ? <div>{ t.dashboard.item.benchmark }: <span className={ "dashboard-value-" + (item.value === undefined || item.benchmark === undefined ? "info" : (item.value > item.benchmark ? "success" : "danger")) }>{ item.benchmark === undefined ? "-" : withUnit(item.benchmark, item.chart.unit, item.decimals) }</span></div> : null
        }
        { item.trendPeriod === undefined ? null :
          <div className="dashboard-chart-footer-small">
            <span className={ "dashboard-value-" + (trend === undefined || trend === 0 ? "info" : trend === 1 ? "success" : "danger") }>
              { item.trend === undefined ? <Glyphicon icon="record" /> : (item.trend > 0 ? " +" : " ") + withUnit(item.trend, item.chart.unit, item.decimals) }
            </span>
          </div>
        }
        { item.redFlagVisibility === RedFlagVisibility.Hidden ? null :
          <div className="dashboard-chart-footer-small">
            <span className={ "dashboard-value-" + (item.redflags === 0 ? "success" : item.redflags === undefined ? "info" : "danger") }>
              <Glyphicon icon="flag" />{ item.redflags === undefined || item.redflags === 0 || item.redFlagVisibility === RedFlagVisibility.Boolean ? "" : " " + item.redflags }
            </span>
          </div>
        }
      </div>
    </div>
  );
}

export function ChartComponent({ item, chart, width, height, filters, isDetailChart, dispatch }: { item: Item, chart: Chart, width: number, height: number, filters: Filters, isDetailChart: boolean, dispatch: Dispatch<State> }) {
  switch (chart.type) {
    case "number":
      return <NumberPicture item={ item } chart={ chart } width={ width } height={ height } />;
    case "bar":
      return <BarPicture item={ item } chart={ chart } width={ width } height={ height } filters={ filters } isDetailChart={ isDetailChart } dispatch={ dispatch } />;
    case "traffic-light":
      return <TrafficLightPicture chart={ chart } width={ width } height={ height } />;
    case "trend":
      return <TrendPicture item={ item } chart={ chart } width={ width } height={ height } isDetailChart={ isDetailChart } dispatch={ dispatch } />
    default:
      return <div></div>;
  }
}

export function ChartMessage({ chart, top, bottom }: { chart: Chart, top: string, bottom: string }) {
  let text;
  if (chart.state === ChartState.Loading) {
    text = t.notices.loading;
  } else if (chart.state === ChartState.NoData) {
    text = t.dashboard.item.no_data;
  } else {
    return null;
  }
  return <div className="dashboard-chart-message" style={{ top, bottom }}><div>{ text }</div></div>;
}
