// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import * as React from "react";
import * as colors from "./colors";
import { withUnit, scaleToFit, shortenToFit } from "../utils";
import { BarChart, Filters, State, Item, BarChartBar, BarChartGroup, isSourceSplitDate } from "../model";
import * as action from "../action";
import { Dispatch } from "../../utils/state";
import { t } from "../../translations";

const enum ValueStyle {
  Hidden,
  Value,
  ValueUnit,
}

export function BarPicture({ item, filters, chart, width, height, isDetailChart, dispatch }: { item: Item, filters: Filters, chart: BarChart, width: number, height: number, isDetailChart: boolean, dispatch: Dispatch<State> }) {
  let norm: JSX.Element | undefined;

  // Graph is generated as a `w * 100` canvas and rendered as `width * height`.
  // The value of `w` is calculated such that these two rectangles have the same ratio.
  const scale = 100 / height;
  const w = Math.round(width * scale);

  let barCount = chart.bars === undefined ? 0 : chart.bars.length;

  // In a detail graph, we should not hide the bars that are filtered out, but we must make them gray.
  let currentFilters: (string | null)[] | undefined = undefined;
  if (isDetailChart && filters.fields !== undefined && !isSourceSplitDate(chart.split) && filters.fields[chart.split] !== undefined) {
    currentFilters = filters.fields[chart.split]!;
  }

  const groupExpanded: boolean[] = [];
  const groupMarginAfter: number[] = [];
  let groupMargins = 0;

  if (chart.barGroups !== undefined) {
    if (currentFilters !== undefined) {
      for (const group of chart.barGroups) {
        let expanded = currentFilters.indexOf(group.id) !== -1;
        if (!expanded) {
          for (const bar of group.bars) {
            if (currentFilters.indexOf(bar.id) !== -1) {
              expanded = true;
              break;
            }
          }
        }
        groupExpanded.push(expanded);
        groupMargins += 0.4;
        if (expanded) {
          barCount += group.bars.length;
        } else {
          barCount += 1;
          if (groupExpanded.length !== 1 && !groupExpanded[groupExpanded.length - 2]) {
            // Previous group was also collapsed, so we can place less margin between them.
            groupMargins -= 0.4;
            groupMarginAfter[groupMarginAfter.length - 1] = 0;
          }
        }
        groupMarginAfter.push(0.4);
      }
    } else {
      for (const group of chart.barGroups) {
        barCount += group.bars.length;
        groupMargins += 0.4;
        groupExpanded.push(true);
        groupMarginAfter.push(0.4);
      }
    }
    // The space between groups counts as 0.4 bar
    // The for-loop counts one gap too many if there are no parentless bars.
    if (chart.bars === undefined || chart.bars.length === 0) groupMargins -= groupMarginAfter[groupMarginAfter.length - 1];
  }

  // Width of a single bar. Chosen such that all bars are visible.
  // We need to put chart.bars.length bars and (chart.bars.length - 1) times margin between the bars.
  // If the width of the screen is large, we restrict the width of the bars
  // for readability.
  const barMarginFactor = 1 / Math.min(3 + barCount / 4, 4);
  const barWidth = barCount === 0 ? 1 : Math.min(Math.min(width * 0.75, isDetailChart ? width - 30 / scale : width) / (barCount * (1 + barMarginFactor) - barMarginFactor) * scale, 80 * scale);
  const barMargin = barWidth * barMarginFactor;

  // Width of all items is the width of (chart.bars.length) bars and (chart.bars.length - 1) margins
  const totalBarsWidth = barCount * (barWidth + barMargin) + groupMargins * barWidth - barMargin;
  const x0 = (w - totalBarsWidth) / 2;

  const yTop = isDetailChart ? 8 : 10;
  const yBottom = isDetailChart ? 75 : 80;
  const maxBarHeight = yBottom - yTop;

  let valueStyle = ValueStyle.ValueUnit;
  let fontSize = scaleToFit(barWidth * 0.85, chart.valueUnitWidth || 1, 24 * scale);
  if (fontSize < 16 * scale) {
    fontSize = scaleToFit(barWidth * 0.85, chart.valueWidth || 1, 24 * scale);
    if (fontSize < 14 * scale) {
      valueStyle = ValueStyle.Hidden;
    } else {
      valueStyle = ValueStyle.Value;
    }
  }

  const minLabelFontSize = 12 * scale;
  const maxLabelFontSize = 20 * scale;
  let labelFontSize: number | undefined = 3.1;
  if (barWidth < 27 * scale) {
    labelFontSize *= barWidth / (30 * scale);
  }
  if (labelFontSize > maxLabelFontSize) labelFontSize = maxLabelFontSize;
  if (labelFontSize < minLabelFontSize) {
    labelFontSize = undefined;
  } else {
    // Do all labels fit with this font size?
    const fitFontSize = (isDetailChart ? 25 : 20) / chart.labelWidth!;
    if (labelFontSize < fitFontSize) {
      // All labels fit
    } else if (fitFontSize > minLabelFontSize) {
      // Labels do not fit with 'labelFontSize', but they fit when lowering the font size
      labelFontSize = fitFontSize;
    } else {
      // Labels do not fit. Set to minimum font size. Longer labels will be shortened with ellipsis.
      labelFontSize = minLabelFontSize;
    }
  }

  if (item.norm !== undefined) {
    const y = yTop + maxBarHeight - toHeight(item.norm);
    norm = <line x1={w * 0.02} y1={ y } x2={w * 0.98} y2={ y } stroke={ colors.norm } strokeWidth={ scale } strokeDasharray={ 5 * scale } />

    if (isDetailChart && x0 / scale > 60) {
      norm = <g>
        { norm }
        <rect x={ 1 } y={ y - 13 * scale } rx={ 10 * scale } ry={ 10 * scale } width={ 50 * scale } height={ 25 * scale } fill={ colors.norm } radius={ 5 * scale } />
        <text x={ 1 + 7 * scale } y={ y } fill="#fff" fontSize={ `${14 * scale}px` } dominantBaseline="middle">{ t.dashboard.item.norm }</text>
      </g>;
    }
  }

  let lines: JSX.Element[] = [];
  if (isDetailChart) {
    const blocks = getYAxisBlockCount(chart.min, chart.max, item.decimals);
    const step = (chart.max - chart.min) / blocks;
    for (let i = 0; i <= blocks; i++) {
      const value = chart.min + step * i;
      const y = yTop + maxBarHeight - toHeight(value);
      lines.push(<line key={2 * i} x1={w * 0.02 + 40 * scale} y1={ y } x2={w * 0.98} y2={ y } stroke={ colors.lightgray } strokeWidth={ scale } />);
      lines.push(<text key={2 * i + 1} x={ w * 0.02 + 36 * scale } y={ y } fill={ colors.gray } fontSize={ `${14 * scale}px` } dominantBaseline="middle" textAnchor="end">{ withUnit(value, chart.unit, item.decimals) }</text>);
    }
  }

  let bars: JSX.Element[] = [];

  // The item is clickable unless it is a detail chart of a directory, with only one bar.
  const clickable = !(isDetailChart && barCount === 1 && item.items !== undefined && item.items.length !== 0);

  let barIndex = 0;
  const groups = chart.barGroups === undefined ? [] : chart.barGroups.map((group, index) => {
    const xLeft = x0 + (barWidth + barMargin) * barIndex;

    const groupBars = groupExpanded[index] ? group.bars.map((bar) => {
      return showBar(group, bar, barIndex++);
    }) : showGroupBar(group, barIndex++);

    const xRight = x0 + (barWidth + barMargin) * barIndex - barMargin;
    barIndex += groupMarginAfter[index];

    const barHeight = toHeight(group.average);

    const onClick = () => {
      if (groupExpanded[index] && currentFilters !== undefined) {
        dispatch(action.closeGroupFilterInChart(chart, group.id));
      } else {
        dispatch(action.selectGroupFilterInChart(chart, group.id));
      }
    };

    return <g key={ index }>
      { !isDetailChart || currentFilters !== undefined ? null :
          <rect x={ xLeft - barWidth * 0.2 } y={ yTop + maxBarHeight - barHeight } width={ xRight - xLeft + barWidth * 0.4 } height={ barHeight } fill={ colors.lightgray } onClick={ onClick }>
            <title>{ group.label }</title>
          </rect>
      }
      { !groupExpanded[index] ? null :
        <g transform={ `translate(${ (xLeft + xRight) / 2 },${ isDetailChart ? 4 : 86 })` }>
          <title>{ group.label }</title>
          <text fontSize={ (labelFontSize || minLabelFontSize) + "px" } fill="currentColor" textAnchor="middle" dominantBaseline="middle" onClick={ onClick }>
            { shortenToFit(group.label, group.labelWidths, (xRight - xLeft) / (labelFontSize || minLabelFontSize)) }
          </text>
        </g>
      }
      {
        groupBars
      }
    </g>;
  });

  bars = chart.bars === undefined ? [] : chart.bars.map((bar, index) => showBar(undefined, bar, index + barIndex));

  return (
    <svg viewBox={ `0 0 ${ w } 100 `} width={ width } height={ height }>
      { lines }
      { bars }
      { groups }
      { norm }
    </svg>
  );

  function toHeight(value: number) {
    return Math.min(maxBarHeight, Math.max(0, maxBarHeight * (value - chart.min) / (chart.max - chart.min)));
  }

  function showBar(group: BarChartGroup | undefined, bar: BarChartBar, index: number) {
    return <ChartBar
      item={ item }
      chart={ chart }
      x={ x0 + (barWidth + barMargin) * index }
      y={ yTop }
      group={ group }
      bar={ bar }
      value={ valueStyle === ValueStyle.ValueUnit ? withUnit(bar.value, chart.unit, item.decimals) : bar.value.toFixed(item.decimals) }
      height={ toHeight(bar.value) }
      maxHeight={ maxBarHeight }
      key={ bar.id || "null" }
      width={ barWidth }
      labelFontSize={ labelFontSize }
      valueFontSize={ fontSize }
      valueStyle={ valueStyle }
      enabled={ currentFilters === undefined || currentFilters.indexOf(bar.id) !== -1 || (group !== undefined && currentFilters.indexOf(group.id) !== -1) }
      isDetailChart={ isDetailChart }
      clickable={ clickable }
      dispatch={ dispatch } />;
  }

  function showGroupBar(group: BarChartGroup, index: number) {
    return <ChartBar
      item={ item }
      chart={ chart }
      x={ x0 + (barWidth + barMargin) * index }
      y={ yTop }
      group={ group }
      bar={ undefined }
      value={ valueStyle === ValueStyle.ValueUnit ? withUnit(group.average, chart.unit, item.decimals) : group.average.toFixed(item.decimals) }
      height={ toHeight(group.average) }
      maxHeight={ maxBarHeight }
      key={ group.id }
      width={ barWidth }
      labelFontSize={ labelFontSize }
      valueFontSize={ fontSize }
      valueStyle={ valueStyle }
      enabled={ false }
      isDetailChart={ isDetailChart }
      clickable={ clickable }
      dispatch={ dispatch } />;
  }
}

function ChartBar({ item, chart, x, y, group, bar, value, height, maxHeight, width, labelFontSize, valueFontSize, valueStyle, enabled, isDetailChart, clickable, dispatch }: { item: Item, chart: BarChart, x: number, y: number, group: BarChartGroup | undefined, bar: BarChartBar | undefined, value: string, height: number, maxHeight: number, width: number, labelFontSize: number | undefined, valueFontSize: number, valueStyle: ValueStyle, enabled: boolean, isDetailChart: boolean, clickable: boolean, dispatch: Dispatch<State> }) {
  const valueInBar = isDetailChart && height >= 10;
  const showValue = isDetailChart && valueStyle !== ValueStyle.Hidden;
  const showLabel = (isDetailChart || chart.split === "service");

  const handler = bar === undefined
    ? action.selectGroupFilterInChart(chart, group!.id)
    : action.toggleFilterInChart(isDetailChart, chart, group === undefined ? undefined : group.id, bar.id);
  const hasHandler = isDetailChart || (item.items !== undefined && item.items.length !== 0);

  const labelWidth = labelFontSize === undefined ? 0 : (isDetailChart ? 25 : 20) / labelFontSize;

  const tooltip = (bar === undefined ? group!.label : bar.label) + " - " + value;

  return (
    <g transform={`translate(${ x },0)`}>
      { isDetailChart ? null : <rect x={ 0 } y={ y } width={ width } height={ maxHeight } fill={ colors.lightgray } onClick={ hasHandler ? () => dispatch(handler) : undefined }><title>{ tooltip }</title></rect> }
      <rect className={ isDetailChart ? "layout-color-light" : "" } x={ 0 } y={ y + maxHeight - height } width={ width } height={ height } fill={ enabled ? "currentColor": colors.lightgray } onClick={ hasHandler ? () => dispatch(handler) : undefined } cursor={ clickable ? "pointer" : "default" }>
        <title>{ tooltip }</title>
      </rect>
      {
        !showValue ? null :
          <text x={ width / 2 } y={ y + maxHeight - height + (valueInBar ? 1 : -2.5) } fontSize={ valueFontSize + "px" } textAnchor="middle" dominantBaseline={ valueInBar ? "hanging" : "text-after-edge" } fill={ valueInBar && enabled ? "#fff" : "currentColor" }>
            { value }
          </text>
      }
      <g transform={ `translate(${ width * 0.55 + 1 },${ y + maxHeight + 3 }) rotate(-50)` }>
        <title>{ tooltip }</title>
        {
          !showLabel || labelFontSize === undefined ? null :
            <text fontSize={ labelFontSize + "px" } fill="currentColor" textAnchor="end" dominantBaseline="middle" onClick={ hasHandler ? () => dispatch(handler) : undefined }>
              { bar === undefined ? shortenToFit(group!.label, group!.labelWidths, labelWidth) : shortenToFit(bar.label, bar.labelWidths, labelWidth) }
            </text>
        }
      </g>
    </g>
  );
}

export function getYAxisBlockCount(min: number, max: number, precision: number) {
  let best = 2;
  let bestValue = 1;

  const precisionExp = 10 ** precision;

  for (let blocks = 3; blocks <= 8; blocks++) {
    let step = (max - min) / blocks;
    let value = 0;
    if (correctPrecision(step)) {
      value += 2;
      if (highPrecision(step)) value += 3;
    }
    if (correctStep(min, step)) value++;

    if (value > bestValue) {
      best = blocks;
      bestValue = value;
    }
  }

  return best;

  function correctPrecision(value: number) {
    return Math.abs(value - Math.round(value / precisionExp) * precisionExp) <= Number.EPSILON;
  }
  function highPrecision(value: number) {
    return Math.abs(value - Math.round(value / precisionExp / 10) * precisionExp * 10) <= Number.EPSILON;
  }
  function correctStep(start: number, step: number) {
    const steps = Math.round(start / step);
    return Math.abs(start - start * steps) <= Number.EPSILON;
  }
}
