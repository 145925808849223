export interface DateSearchIndexer {
  index(row: number, content: string): void,
  finish(): DateSearcher,
}

export interface DateSearchIndexers {
  [column: number]: DateSearchIndexer | undefined,
}

export interface DateSearchers {
  [column: number]: DateSearcher | undefined,
}

export interface DateSearcher {
  search(min: Date | undefined, max: Date | undefined): Uint32Array,
}

export function createDateSearchIndexer(): DateSearchIndexer {
  const datesArray: number[] = [];
  const indicesArray: number[] = [];
  return { index, finish };

  function index(row: number, content: string) {
    content = content.trim();
    const timestamp = Date.parse(content) / 3600 / 24;
    if (isNaN(timestamp)) return;

    datesArray.push(timestamp);
    indicesArray.push(row);
  }
  function finish() {
    const dates = new Uint32Array(datesArray);
    const indices = new Uint32Array(indicesArray);
    indices.sort((a, b) => dates[a] - dates[b]);
    dates.sort();
    return createDateSearcher(dates, indices);
  }
}

export function createDateSearcher(dates: Uint32Array, indices: Uint32Array): DateSearcher {
  return { search };

  function search(minDate: Date | undefined, maxDate: Date | undefined) {
    const min = minDate === undefined ? 0 : findDate(dateToInt(minDate) - 1) + 1;
    const max = maxDate === undefined ? dates.length : findDate(dateToInt(maxDate)) + 1;
    return indices.slice(min, max);
  }

  // Finds the position of the largest date smaller than the given argument in `dates`
  function findDate(date: number) {
    if (dates.length === 0 || date < dates[0]) {
      return -1;
    }

    // Perform binary search.
    // Loop invariant:
    // dates[i] <= date
    // date < dates[j]
    //   where dates[dates.length] is treated as infinite
    let i = 0;
    let j = dates.length;

    while (j - i > 1) {
      const m = (i + j) >> 1; // floor((i + j) / 2)
      if (dates[m] <= date) {
        i = m;
      } else {
        j = m;
      }
    }
    return i;
  }
}

function dateToInt(date: Date) {
  return (+date / 3600 / 24) | 0;
}
