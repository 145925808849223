let suggestedFileName = "";

// Element used to find the children of a popup
const popupElement = document.createElement("div");

export let exportSpreadsheetCallback: (() => void) | undefined;

function exportCsv(target: HTMLElement, fileName: string) {
  while (target.className.split(" ").indexOf("btn") === -1) {
    target = target.parentElement!;
  }
  const button = target;
  while (target.className.split(" ").indexOf("panel") === -1) {
    target = target.parentElement!;
  }
  const table = target.querySelector("table");
  if (table === null) return;
  const lastRow = table.tBodies[table.tBodies.length - 1].lastElementChild;

  if (lastRow !== null && lastRow.id === "content-streaming") {
    exportSpreadsheetCallback = () => exportCsv(button, fileName);
    button.className = "btn btn-primary btn-loading";
    button.firstElementChild!.className = "glyphicon glyphicon-refresh";
    return;
  }
  exportSpreadsheetCallback = undefined;
  button.className = "btn btn-primary";
  button.firstElementChild!.className = "glyphicon glyphicon-cloud-download";

  suggestedFileName = fileName.replace(/ /g, "-").replace(/[^a-zA-Z0-9-_]/g, "") + ".csv";

  const data: string[] = [];
  let countFieldEnds = 0;
  let currentHasContent = false;

  if (table.tHead && table.tHead.rows.length !== 0) {
    // Write header
    const header = table.tHead.rows[0];
    for (let i = 0; i < header.cells.length; i++) {
      const cell = header.cells[i];
      writeHeaderElement(cell);
      endField();
    }
    endRow();
  }

  for (let i = 0; i < table.tBodies.length; i++) {
    const body = table.tBodies[i];
    for (let j = 0; j < body.rows.length; j++) {
      const row = body.rows[j];
      if (row.cells[0].className.split(" ").indexOf("td-full-width") !== -1) continue;
      for (let k = 0; k < row.cells.length; k++) {
        writeElement(row.cells[k]);
        endField();
      }
      endRow();
    }
  }

  const blob = new Blob(data, { type: "text/csv" });
  const download = document.createElement("a");
  download.href = URL.createObjectURL(blob);
  download.download = suggestedFileName;
  download.click();

  function writeHeaderElement(element: HTMLElement) {
    if (element.getAttribute("data-priority") === "buttons") return;
    if (element.className.split(" ").indexOf("table-search-current-query") !== -1) return;
    for (let i = 0; i < element.childNodes.length; i++) {
      const child = element.childNodes[i];
      if (child.nodeType === child.TEXT_NODE) {
        write(child.textContent);
      } else if (child.nodeType === child.ELEMENT_NODE) {
        writeHeaderElement(child as HTMLElement);
      }
    }
  }
  function writeElement(element: HTMLElement) {
    const classes = element.className.split(" ");

    if (classes.indexOf("btn") !== -1 && element.getAttribute("data-html") !== "true") return; // Button, not a Popup
    if (classes.indexOf("popover") !== -1) return;

    let childNodes;
    if (element.getAttribute("data-html") === "true") {
      // Popup
      popupElement.innerHTML = element.getAttribute("data-content") || "";
      childNodes = popupElement.childNodes;
    } else {
      childNodes = element.childNodes;
    }

    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i];
      if (child.nodeType === child.TEXT_NODE) {
        write(child.textContent);
      } else if (child.nodeType === child.ELEMENT_NODE) {
        if (currentHasContent) writeString("\n");
        writeElement(child as HTMLElement);
      }
    }
  }

  function endRow() {
    countFieldEnds = 0;
    currentHasContent = false;
    data.push("\n");
  }
  function endField() {
    if (currentHasContent) data.push('"'); // Finish field

    countFieldEnds++;
    currentHasContent = false;
  }
  function write(str: string | null) {
    if (!str) return;
    str = str.trim();
    if (!str) return;
    writeString(str);
  }
  function writeString(str: string) {
    if (currentHasContent) {
      data.push(" ");
    } else {
      while (countFieldEnds !== 0) {
        data.push(",");
        countFieldEnds--;
      }
      data.push('"');
    }
    data.push(csvEscape(str));
    currentHasContent = true;
  }
}

(window as any).fpmExportSpreadsheet = exportCsv;

function csvEscape(source: string) {
  return source.replace(/\"/g, "\"\"");
}
