import { SourceSplitDate, getSplitDatePeriod, isSourceSplitDate } from "./chart";

export interface FilterState {
  current: UserFilters,
  string: string,
  optionKeys: UserFilterKeys[] | "uninitialized" | "loading",
  options: FilterOptionsState,
  submenu: keyof FilterOptionsState | undefined,
}

export type TimePeriod = TimePeriodLastMonths | TimePeriodForTrend | TimePeriodYear | TimePeriodCustom;

export interface TimePeriodLastMonths {
  type: "last-months",
  months: 3 | 6 | 12,
}
export interface TimePeriodForTrend {
  type: "for-trend",
  split: SourceSplitDate,
}
export interface TimePeriodYear {
  type: "year",
  year: number,
}
export interface TimePeriodCustom {
  type: "custom",
  start: Date,
  end: Date,
}

export function getTimeRange(time: TimePeriod): [Date, Date] {
  let start: Date;
  let end = new Date();
  end.setMonth(end.getMonth() + 1);
  end.setDate(1);
  end.setHours(0, 0, 0, 0);

  if (time.type === "last-months") {
    start = new Date(end);
    start.setMonth(start.getMonth() - time.months);
  } else if (time.type === "for-trend") {
    const { count } = getSplitDatePeriod(time.split);
    // Make sure `end` is the end of a period
    end.setMonth(Math.ceil(end.getMonth() / count) * count);

    // We must have 3 periods, such that there is still enough information if the current period does not have data yet.
    // We thus need `3 * count` months.
    start = new Date(end.getFullYear(), end.getMonth() - 3 * count, 0, 0, 0, 0, 0);
  } else if (time.type === "custom") {
    return [
      new Date(time.start.getUTCFullYear(), time.start.getUTCMonth(), time.start.getUTCDate()),
      new Date(time.end.getUTCFullYear(), time.end.getUTCMonth(), time.end.getUTCDate() + 1),
    ];
  } else {
    start = new Date(time.year, 0, 1, 0, 0, 0, 0);
    end = new Date(time.year + 1, 0, 1, 0, 0, 0);
  }
  return [start, end];
}

export interface Filters {
  readonly fields?: FieldFilters,
}

export interface UserFilters extends Filters {
  readonly period: TimePeriod,
}

export interface FieldFilters {
  // Measurement properties
  client?: string[],
  location?: (string | null)[],
  level?: string[],

  // MeasurementChecklist properties
  supplier?: string[],
  contract?: string[],
  checklist?: string[],
  service?: string[],

  // MeasurementChecklistRequirement properties
  requirement?: string[],
  weight?: string[],

  // Checklist properties
  checklistKind?: string[],

  // Requirement properties
  category?: string[],

  // GDP (AT) filters
  at?: string[],

  // Tags
  tag?: string[],
}

export const filterFields: (keyof FieldFilters)[] = ["client", "location", "level", "supplier", "contract", "checklist", "checklistKind", "service", "requirement", "weight", "at", "category", "tag"];
export type UserFilterKeys = "service" | "client" | "supplier" | "location" | "category";
export const userFilterFields: UserFilterKeys[] = ["service", "client", "supplier", "location", "category"];

export type FilterOptions = {
  [_ in keyof FieldFilters]?: { label: string, value: string | null }[];
};

export type FilterOptionsState = {
  [_ in UserFilterKeys]?: FilterOptionState;
};
export interface FilterOptionState {
  updateId: string | undefined,
  source: string,
  filters: string,
  timestamp: number,
  list: { label: string, value: string | null, selected: boolean }[] | undefined,
}

export function emptyFilterOptions(keys: UserFilterKeys[] | undefined | "loading" | "uninitialized") {
  if (!(keys instanceof Array)) keys = userFilterFields;
  const result: FilterOptionsState = {};
  for (const key of keys) {
    result[key] = {
      updateId: undefined,
      source: "",
      filters: "",
      timestamp: 0,
      list: undefined,
    };
  }
  return result;
}

function dateToString(date: Date) {
  return `${ date.getFullYear() }-${ date.getMonth() + 1 }-${ date.getDate() }`;
}
function parseDate(input: string): Date | undefined {
  const split = input.split("-", 4);
  if (split.length !== 3) return;
  return new Date(parseInt(split[0], 10), parseInt(split[1], 10) - 1, parseInt(split[2], 10));
}

export function filtersToString(filters: Filters | UserFilters) {
  const result: string[] = [];

  const fields = filters.fields || {};

  if ("period" in filters && filters.period !== undefined) {
    if (filters.period.type === "last-months") {
      result.push(`date:last-months,${ filters.period.months }`);
    } else if (filters.period.type === "for-trend") {
      result.push(`date:trend,${ filters.period.split }`);
    } else if (filters.period.type === "year") {
      result.push(`date:year,${ filters.period.year }`);
    } else {
      const { start, end } = filters.period;
      result.push(`date:${ dateToString(start) },${ dateToString(end) }`);
    }
  }

  addField("client");
  addField("location");
  addField("level");
  addField("supplier");
  addField("contract");
  addField("checklist");
  addField("checklistKind");
  addField("service");
  addField("requirement");
  addField("weight");
  addField("at");
  addField("category");
  addField("tag");

  return result.join("|");

  function addField(key: keyof FieldFilters) {
    const values: (string | null)[] | undefined = fields[key];
    if (values !== undefined) {
      result.push(key + ":" + values.map((value) => value === null ? "null" : value).join(","));
    }
  }
}

export function parseFilters(source: string): Filters {
  const { fields } = parseUserFilters(source);
  return { fields };
}

export function parseUserFilters(source: string): UserFilters {
  const flags: string[] = [];
  let period: TimePeriod | undefined;
  const fields: { [key: string]: string[] } = {};

  for (const part of source.split("|")) {
    const index = part.indexOf(":");
    if (index === -1) {
      flags.push(part);
    } else {
      const value = part.substring(index + 1);
      const key = part.substring(0, index);
      if (key === "date") {
        const [start, end] = value.split(",");

        if (start === "last-months") {
          let months: 3 | 6 | 12 = 12;
          if (end === "3") months = 3;
          if (end === "6") months = 6;
          period = { type: "last-months", months };
        } else if (start === "trend") {
          if (isSourceSplitDate(end)) {
            period = { type: "for-trend", split: end };
          }
        } else if (start === "year") {
          try {
            period = { type: "year", year: parseInt(end, 10) };
          } catch {
            // Invalid, ignore
          }
        } else {
          try {
            const startDate = parseDate(start);
            const endDate = parseDate(end);

            if (startDate !== undefined && endDate !== undefined && isFinite(+startDate) && isFinite(+endDate)) {
              period = {
                type: "custom",
                start: startDate,
                end: endDate,
              };
            }
          } catch {
            // Invalid, ignore
          }
        }
      } else {
        try {
          fields[key] = value.split(",");
        } catch (e) {
          // Invalid, ignore this part
        }
      }
    }
  }

  if (period === undefined) {
    period = { type: "last-months", months: 6 };
  }

  return {
    period,
    fields: {
      client: fields["client"],
      location: fields["location"] === undefined ? undefined : fields["location"].map((location) => location === "null" ? null : location),
      level: fields["level"],
      supplier: fields["supplier"],
      contract: fields["contract"],
      checklist: fields["checklist"],
      checklistKind: fields["checklistKind"],
      service: fields["service"],
      requirement: fields["requirement"],
      weight: fields["weight"],
      at: fields["at"],
      category: fields["category"],
      tag: fields["tag"],
    },
  };
}

export function setFilterOptionsFor(key: UserFilterKeys, filters: Filters, current: FilterOptionsState, list: FilterOptions[UserFilterKeys], requestId: string): FilterOptionsState {
  const currentOption = current[key];
  if (currentOption === undefined) return current;
  const expectedRequestId = currentOption.updateId;
  if (expectedRequestId !== requestId) return current;

  if (list !== undefined) {
    list.sort((a, b) => a.label.localeCompare(b.label));
  }

  const fields = filters.fields || {};
  const selected = fields[key] || [];

  const option: FilterOptionState = {
    updateId: undefined,
    source: currentOption.source,
    filters: currentOption.filters,
    timestamp: Date.now(),
    list: (list || []).map((value) => ({ ...value, selected: selected.indexOf(value.value) !== -1 })),
  };

  return{
    ...current,
    [key]: option,
  };
}

export function updateFilterOptions(filters: FilterState, options: FilterOptionsState): FilterState {
  const current: UserFilters = { ...filters.current, fields: {} };

  for (const key of userFilterFields) {
    const option = options[key];
    if (option === undefined) continue;

    const selected: (string | null)[] = [];

    if (option.list !== undefined) {
      for (const item of option.list) {
        if (item.selected) {
          selected.push(item.value);
        }
      }
    }

    if (selected.length !== 0) {
      selected.sort();
      // `selected` may only contain null if it applies to locations
      current.fields![key] = selected as string[];
    }
  }

  return {
    current,
    string: filtersToString(current),
    optionKeys: filters.optionKeys,
    options,
    submenu: filters.submenu,
  };
}

export function hasUserFilters(filter: Filters) {
  const fields = filter.fields;
  if (fields === undefined) return false;

  for (const key of userFilterFields) {
    if (fields[key] !== undefined) return true;
  }
  return false;
}

export function filtersWithout(filters: UserFilters, key: UserFilterKeys): UserFilters {
  if (filters.fields === undefined) return filters;
  return {
    period: filters.period,
    fields: {
      ...filters.fields,
      [key]: undefined,
    },
  };
}
