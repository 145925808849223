// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import * as model from "./model";

type Action = (state: model.State) => model.State;

function shouldIgnore(e: React.MouseEvent<HTMLElement>) {
  return e.ctrlKey || e.metaKey;
}

export const openSubMenu = (e: React.MouseEvent<HTMLElement>, menu: string, key: string): Action => (state) => {
  if (shouldIgnore(e)) return state;

  const index = state.path.indexOf(menu);
  if (index === -1) return state;
  const path = index === 0 && key === "root" ? ["root"] : [...state.path.slice(0, index + 1), key];

  let { sidebarVisibleIfMobile } = state;

  // Hide menu on mobile if the selected item has no submenu
  if (state.menus[key] === undefined || key === "root") {
    sidebarVisibleIfMobile = false;
  }

  return { ...state, path, sidebarVisibleIfMobile };
};

export const root = (): Action => (state) => ({ ...state, path: ["root"] });

export const setMenu = (e: React.MouseEvent<HTMLElement>, visible: boolean): Action => (state) => {
  if (shouldIgnore(e)) return state;

  return { ...state, sidebarVisibleIfMobile: visible };
};

export const toggleLanguageDropdown = (): Action => (state) => ({ ...state, languageDropdownVisible: !state.languageDropdownVisible });
