import * as React from "react";
import { Dispatch } from "../../utils/state";
import { State, Root, Item, Dashboard, Tool, layout, Filters, doesManage, manageTool, filtersVisible, filtersForcedVisible, DragState, DragSide, isDragging, RedFlagVisibility, DirectoryLayout, hasUserFilters, Chart } from "../model";
import * as action from "../action";
import { ItemComponent, ChartComponent, ChartMessage } from "./chart";
import { FilterForm, TimePeriodForm } from "./filter";
import { translate } from "../../translations/get";
import { Glyphicon, Checkbox } from "../../utils/view";
import { UserPreferences } from "../model/personalize";
import { t } from "../../translations";

/**
 * Renders a page on the dashboard.
 */
export function renderPage({ state, page, dispatch }: { state: State, page: Root | Item, dispatch: Dispatch<State> }) {
  let className = "";

  let active = false;
  let hidden = false;
  let drag: DragState | undefined = undefined;
  let pageLayout: layout.ItemLayout;

  if (page.type === "root") {
    active = state.path.length === 0;
    pageLayout = state.rootLayout;
    className += " dashboard-page-root";
  } else {
    const pathIndex = state.path.indexOf(page.id);
    if (pathIndex === -1) {
      hidden = true;
    } else if (pathIndex === state.path.length - 1) {
      active = true;
    }
    pageLayout = state.itemLayouts[page.id];
  }

  const hasDetailChart = page.type !== "root" && page.detailCharts.length > 0;
  if (!hasDetailChart) {
    className += " dashboard-page-no-detailchart";
  }

  if (active) {
    className += " active";
    if (isDragging(state)) {
      drag = state.drag;
      className += " page-drag";
    }
  } else if (hidden) {
    className += " page-hidden";

    // Ignore all actions
    dispatch = () => {};
  }

  let backButton: JSX.Element | undefined;

  if (page.type !== "root") {
    backButton = <a className="dashboard-page-back btn btn-primary" href="javascript:;" onClick={ () => dispatch(action.closeItem) }>&lsaquo; { t.actions.back }</a>;
  }

  const showFilters = filtersVisible(state, page);
  const hasOtherContent = (state.activeTool === Tool.Source || state.activeTool === Tool.RedFlags) && active;

  let mainHeight = layout.getMainHeight(state.windowHeight, page, hasOtherContent);
  if (!hasDetailChart && showFilters) {
    mainHeight -= layout.interfaceTopFiltersHeight;
  }
  const [items, itemsHeight] = renderItems({ state, width: state.width, height: mainHeight, dashboard: state.dashboard as Dashboard, page, pageLayout, showFilters, filters: state.filters.current, drag, dispatch });

  let height = state.windowHeight - (page.type === "root" ? layout.topBarHeightWithoutBreadcrumbs : layout.topBarHeightWithBreadcrumbs) - layout.marginBottom;
  if (active && itemsHeight > height) height = itemsHeight;
  if (active && hasOtherContent) {
    height += layout.marginBottom;
  }

  return {
    height,
    element: <div className={ "dashboard-page-wrapper" + className } style={{ height: height + "px" }}>
      <div className={ "dashboard-page" + className }>
        { backButton }
        <DashboardTools page={ page } state={ state } dispatch={ dispatch } />
        <div className="dashboard-header-bg"></div>
        <h2 onClick={ state.manage && state.manage.edit ? (() => dispatch(action.manage.startEditTitle)) : undefined }>
          { translate(page.title) }
          { state.manage && state.manage.edit && <a href="javascript:;" style={{ fontSize: "24px", marginLeft: "10px" }}><Glyphicon icon="edit" /></a> }
        </h2>
        { showFilters ? <FilterForm inTool={ false } filters={ state.filters } dispatch={ dispatch } /> : null }
        { !showFilters && page.type === "item" && page.detailCharts.length === 0 ? <div className="dashboard-header-empty-spacer" /> : null }
        <DetailCharts state={ state } page={ page } dispatch={ dispatch } hasOtherContent={ hasOtherContent } showFilters={ showFilters } />
        { items }
      </div>
    </div>
  };
}

function DashboardTools({ page, state, dispatch }: { page: Root | Item, state: State, dispatch: Dispatch<State> }) {
  let className = "dashboard-tools";

  if (state.activeTool !== undefined && state.activeTool !== Tool.Source && state.activeTool !== Tool.RedFlags) {
    className += " open";
  }

  let tools = [];

  if (!filtersForcedVisible(state, page)) {
    tools.push(
      <DashboardToolItem key="filters" state={ state } dispatch={ dispatch } tool={ Tool.Filter } icon="filter" label={ t.dashboard.filters.filters } />
    );
  }

  let filterPeriodText = t.dashboard.time_period.short.custom;
  if (state.filters.current.period.type === "last-months") {
    filterPeriodText = t.dashboard.time_period.short.last_months[state.filters.current.period.months];
  }
  tools.push(
    <DashboardToolItem key="timeperiod" state={ state } dispatch={ dispatch } tool={ Tool.TimePeriod } icon="calendar" label={ filterPeriodText } />
  );

  if (page.type === "item" && (page.items === undefined || page.items.length === 0)) {
    tools.push(
      <DashboardToolItem key="sources" state={ state } dispatch={ dispatch } tool={ Tool.Source } icon="duplicate" label={ t.dashboard.item.sources } />
    );
  }

  if (page.type === "item" && page.redFlagVisibility === RedFlagVisibility.Number && page.redflags !== undefined && page.redflags !== 0) {
    tools.push(
      <DashboardToolItem key="redflags" state={ state } dispatch={ dispatch } tool={ Tool.RedFlags } icon="flag" label={ page.redflags.toString() } className="dashboard-tool-item-redflag" />
    );
  }

  // Temporarily removed:
  // <DashboardToolItem state={ state } dispatch={ dispatch } tool={ Tool.Export } label="Exporteer" />

  return (
    <div className={ className }>
      <ul>
        { tools }
        {
          !hasUserFilters(state.filters.current) ? null
            : <li className="dashboard-tools-remove-filters" onClick={ () => dispatch(action.removeAllFilters) }>
                <a href="javascript:;">
                  <Glyphicon icon="trash" />&nbsp;{ t.dashboard.filters.remove }
                </a>
              </li>
        }
      </ul>
      <div className="dashboard-tool-bg" onClick={ () => dispatch(action.closeTool) }></div>
      <div className="dashboard-tool">
        <div className="dashboard-tool-close layout-color-light" onClick={ () => dispatch(action.closeTool) }><span>x</span></div>
        { state.activeTool === Tool.Filter ? <FilterForm inTool={ true } filters={ state.filters } dispatch={ dispatch } /> : null }
        { state.activeTool === Tool.Personalize ? <Personalize preferences={ state.user.preferences } dispatch={ dispatch } /> : null }
        { state.activeTool === Tool.TimePeriod ? <TimePeriodForm filters={ state.filters } dispatch={ dispatch } /> : null}
      </div>
    </div>
  );
}

function DashboardToolItem({ state, dispatch, tool, icon, label, className }: { state: State, dispatch: Dispatch<State>, tool: Tool, icon?: string, label: string, className?: string }) {
  return <li className={ tool === state.activeTool ? "active" : "" }><a href="javascript:;" className={ className } onClick={ () => dispatch(action.toggleTool(tool)) }>
    { icon !== undefined ? <Glyphicon icon={ icon } /> : null }
    { icon !== undefined ? " " + label : label }
  </a></li>;
}

function Personalize({ preferences, dispatch }: { preferences: UserPreferences, dispatch: Dispatch<State> }) {
  return <div className="dashboard-tool-personalize">
    <h3>{ t.dashboard.personalize.title }</h3>
    <div>
      { t.dashboard.personalize.description }
      <div className="btn-group">
        <a href="javascript:;" onClick={ () => dispatch(action.closeTool) } className="btn btn-primary">{ t.actions.ok }</a>
        { preferences.order.length !== 0 ? <a href="javascript:;" onClick={ () => dispatch(action.removePreferences) } className="btn btn-danger">{ t.dashboard.personalize.delete }</a> : null }
      </div>
    </div>
  </div>;
}

/**
 * Shows the detail chart(s) of a page if needed
 */
function DetailCharts({ state, page, dispatch, hasOtherContent, showFilters }: { state: State, page: Root | Item, hasOtherContent: boolean, showFilters: boolean, dispatch: Dispatch<State> }) {
  const manageCharts = manageTool(state, "charts");

  if (page.type !== "item" || (page.detailCharts.length === 0 && !manageCharts)) return <div></div>;

  let className = "dashboard-page-details";

  let width = state.width;
  let height = layout.detailGraphHeight;

  if (hasOtherContent) {
    height = layout.smallDetailGraphHeight;
  } else if (page.items === undefined || page.items.length === 0 || doesManage(state, "charts")) {
    height = Math.max(height, state.windowHeight - layout.interfaceTopHeight - layout.topBarHeightWithBreadcrumbs - layout.marginBottom);
  }
  if (showFilters) height -= layout.interfaceTopFiltersHeight;

  if (page.detailCharts.length > 1 || doesManage(state, "charts")) {
    width -= 206;
    className += " dashboard-page-details-multiple";
  }

  const editChart = manageTool(state, "edit-chart");

  const someManage = manageCharts !== undefined || editChart !== undefined;

  // selectedDetailChartIndex may only be undefined when managing the charts of an item and the main chart is not used as a detail chart.
  const selectedDetailChartIndex = page.selectedDetailChart === undefined && (!someManage || page.chartAsDetail) ? 0 : page.selectedDetailChart;
  const selectedDetailChart = selectedDetailChartIndex === undefined ? page.chart : page.detailCharts[selectedDetailChartIndex]?.chart;

  let top = layout.interfaceTopHeight;
  if (showFilters) top += layout.interfaceTopFiltersHeight;

  const hasOtherDetailCharts = page.chartAsDetail ? page.detailCharts.length > 1 : page.detailCharts.length > 0;

  return (
    <div className={ className } style={{ top: top + "px", height: height + "px" }}>
      <ul className="dashboard-page-tabs" style={{ top: (showFilters ? "0" : `${ layout.interfaceTopFiltersHeight }px`) }}>
        {
          someManage && !page.chartAsDetail
            ? <li className={ page.selectedDetailChart === undefined ? "active" : "" }>
                <a href="javascript:;" onClick={ () => dispatch(action.selectDetailChart(undefined)) }>Standaard grafiek</a>
                {
                  someManage
                    ? <a href="javascript:;" className="dashboard-page-tabs-manage" onClick={ () => dispatch(action.manage.startEditChart(undefined, undefined)) }><Glyphicon icon="edit" /></a>
                    : null
                }
              </li>
            : null
        }
        {
          someManage && (hasOtherDetailCharts || page.sourceType === "directory")
            ? <li><Checkbox checked={ hasOtherDetailCharts === page.chartAsDetail } // For "Don't show detail chart", roles of true and false are swapped. We swap (invert) when hasOtherDetailCharts is false.
                            label={ hasOtherDetailCharts ? t.dashboard_manage.tools.charts.first_as_default : t.dashboard_manage.tools.charts.no_detail_chart }
                            onChange={ (checked) => dispatch(action.manage.setChartAsDetailChart(hasOtherDetailCharts === checked)) } /></li> // Also swapped in the event listener.
            : null
        }
        {
          page.detailCharts.map(({ title }, index) => {
            let detailIndex: number | undefined = index;
            if (page.chartAsDetail) detailIndex--;
            if (detailIndex < 0) detailIndex = undefined;
            return (
              <li key={ index } className={ index === page.selectedDetailChart ? "active" : "" }>
                <a href="javascript:;" onClick={ () => dispatch(action.selectDetailChart(index)) }>{ translate(title) }</a>
                { // When page.chartAsDetail is true, the first element of the list will be the detail chart. This chart cannot be removed. This can be discovered with detailIndex = undefined
                  manageCharts && detailIndex !== undefined
                    ? <a href="javascript:;" className="dashboard-page-tabs-manage" onClick={ () => dispatch(action.manage.removeChart(detailIndex!)) }><Glyphicon icon="trash" /></a>
                    : null
                }
                {
                  manageCharts
                    ? <a href="javascript:;" className="dashboard-page-tabs-manage" onClick={ () => dispatch(action.manage.startEditChart(index, detailIndex) ) }><Glyphicon icon="edit" /></a>
                    : null
                }
              </li>
            )
          })
        }
        {
          manageCharts
            ? <li><a href="javascript:;" className="dashboard-page-tabs-manage" onClick={ () => dispatch(action.manage.addChart) }><Glyphicon icon="plus" /></a></li>
            : null
        }
      </ul>
      { selectedDetailChart === undefined ? null : <div onClick={ (page.items === undefined || page.items.length === 0) ? () => dispatch(action.clickChart) : undefined }>
            <ChartComponent item={ page } chart={ selectedDetailChart } width={ width } height={ height } filters={ state.filters.current } isDetailChart={ true } dispatch={ dispatch } />
          </div>
      }
      { selectedDetailChart === undefined ? null : <ChartMessage chart={ selectedDetailChart } top="0" bottom="0" /> }
    </div>
  );
}

/**
 * Shows the list of items on the current page if the page is a directory
 */
export function renderItems({ state, width, height, dashboard, page, pageLayout, showFilters, filters, drag, dispatch }: { state: State, width: number, height: number, dashboard: Dashboard, page: Root | Item, pageLayout: layout.ItemLayout, showFilters: boolean, filters: Filters, drag: DragState | undefined, dispatch: Dispatch<State> }): [JSX.Element, number] {
  const items: JSX.Element[] = [];
  let hiddenMarker: JSX.Element | null = null;
  const dragItems: JSX.Element[] = [];
  let dragItemSpot: JSX.Element | null = null;

  const halfMargin = layout.margin / 2;

  let y = layout.interfaceTopHeight;
  if (page.type === "item" && page.detailCharts.length !== 0) {
    y += layout.detailGraphHeight + layout.margin;
  } else {
    if (showFilters) y += layout.interfaceTopFiltersHeight;
  }

  if (page.items === undefined || doesManage(state, "charts")) return [<div></div>, y];

  const draggable = page.items.length > 1 && (state.manage === undefined || state.manage.edit);

  if (pageLayout.itemsPerRow !== undefined) {
    const { itemsPerRow, style } = pageLayout;
    let i = 0;

    const firstHiddenRow = pageLayout.firstHiddenRow === undefined ? itemsPerRow.length : pageLayout.firstHiddenRow;
    const averageHeight = Math.max((height + layout.margin) / firstHiddenRow, 280) - layout.margin;

    for (let row = 0; row < itemsPerRow.length; row++) {
      if (row === firstHiddenRow) {
        // Don't show this button when dragging, unless the hidden items are already shown
        if (state.showHiddenItems || !drag) {
          let className = "dashboard-hidden-items-header dashboard-hidden-items-header-";
          if (state.showHiddenItems) {
            className += "open";
          } else {
            className += "closed";
          }
          hiddenMarker = <div className={ className } style={{ top: y + "px" }} onClick={ () => dispatch(action.toggleHiddenItems) }><Glyphicon icon="play-circle" /> Verborgen items</div>;
        }
        y += layout.hiddenItemsHeaderHeight;
        if (!state.showHiddenItems) {
          y += layout.margin; // Needed for the drag-hidden overlay
          break;
        }
      }

      const itemCount = itemsPerRow[row];

      // Calculate width of items. Example:
      // Let width = 200, itemCount = 3.
      // Then the itemWidth should be 60, since
      // 3 * 60 + 2 * margin = 200.
      // Note that the margin is only placed between items, and not on the left and right side,
      // thus one time less than `itemCount`.
      // By adding `margin` to `width`, we pretend as if margin is placed `itemCount` times,
      // which makes the calculation easy.
      const itemWidth = (width + layout.margin) / (style === DirectoryLayout.Fixed ? itemsPerRow[0] : itemCount) - layout.margin;

      // First row is scaled with a factor of 1.3, last row with 0.7.
      // If there is only one row or if the layout is fixed, this factor is 1.
      const itemHeightFactor = row >= firstHiddenRow ? 0.3
        : firstHiddenRow <= 1 || style === DirectoryLayout.Fixed ? 1 : 1.3 - 0.6 * (row / (firstHiddenRow - 1));
      const itemHeight = Math.max(averageHeight * itemHeightFactor, 240);

      // Index of the first item of this row
      const rowMinimumId = i;
      // Index of the last item of this row
      const rowMaximumId = i + itemCount - 1;
      const dragHoverItemOnRow = drag !== undefined && drag.hover !== undefined && (
        drag.hover.index > rowMinimumId && drag.hover.index <= rowMaximumId
        || drag.hover.index === rowMinimumId && drag.hover.side === DragSide.Right
        || drag.hover.index === rowMaximumId + 1 && drag.hover.side === DragSide.Left
      );
      const dragSelectedOnRow = drag !== undefined && drag.selected >= rowMinimumId && drag.selected <= rowMaximumId;

      for (let rowIndex = 0; rowIndex < itemCount; rowIndex++, i++) {
        const item = page.items[i];

        const x = rowIndex * (itemWidth + layout.margin);
        let dx = 0;
        if (dragHoverItemOnRow) {
          if (i === drag!.selected) {
            // Should not move the selected element.
          } else if (i < drag!.hover!.index) {
            dx = -1;
          } else if (i > drag!.hover!.index) {
            dx = 1;
          } else if (drag!.hover!.side === DragSide.Left) {
            dx = 1;
          } else {
            dx = 1;
          }

          if (dragSelectedOnRow && !dragShouldMoveItem(drag!.selected, drag!.hover!.index, i)) {
            dx = 0;
          }

          if (dragSelectedOnRow) {
            dx *= itemWidth + layout.margin;
          } else {
            dx *= itemWidth / 3;
          }
        }
        items.push(
          <ItemComponent key={ i } index={ i } dragging={ drag !== undefined && drag.selected === i } draggable={ draggable } item={ dashboard.items[item] } x={ x } dx={ dx } y={ y } width={ itemWidth } height={ itemHeight } filters={ filters } dispatch={ dispatch } />
        );

        if (drag !== undefined) {
          const last = rowIndex === itemCount - 1;
          const dragWider = last && style === DirectoryLayout.Fixed ? itemsPerRow[0] - itemCount : 0;

          const halfWidth = itemWidth / 2;
          dragItems.push(
            <DragComponent key={ i * 2 } x={ x - halfMargin } y={ y - halfMargin } width={ halfMargin + halfWidth} height={ itemHeight + layout.margin } drag={ drag } index={ i } side={ DragSide.Right } dispatch={ dispatch } />,
            <DragComponent key={ i * 2 + 1 } x={ x + halfWidth } y={ y - halfMargin } width={ halfMargin + halfWidth * (1 + 2 * dragWider) } height={ itemHeight + layout.margin } drag={ drag } index={ i + 1 } side={ DragSide.Left } dispatch={ dispatch } />
          );

          if (drag.hover !== undefined && dragHoverItemOnRow) {
            let spotX: number | undefined;
            let spotWidth = itemWidth;
            if (drag.hover.index > drag.selected && dragSelectedOnRow
                ? drag.hover.index - 1 === i
                : drag.hover.index === i) {
              spotX = x;
              if (!dragSelectedOnRow) {
                if (rowIndex === 0) {
                  spotWidth = itemWidth / 3 - layout.margin;
                } else {
                  spotX -= itemWidth / 3;
                  spotWidth = 2 * itemWidth / 3 - layout.margin;
                }
              }
            } else if (drag.hover.index === i + 1) {
              if (dragSelectedOnRow) {
                spotX = x;
              } else {
                spotX = x + itemWidth * 2 / 3 + layout.margin;
                spotWidth = itemWidth * (dragWider === 0 ? 1 : 2) / 3 - layout.margin;
              }
            }

            if (spotX !== undefined) {
              dragItemSpot = <div className="dashboard-item-spot" style={{ left: spotX + "px", top: y + "px", width: spotWidth + "px", height: itemHeight + "px" }} />;
            }
          }
        }
      }

      y += itemHeight + layout.margin;
    }
  } else {
    console.log("Fixed layout: not implemented yet");
  }

  if (drag !== undefined && page.type === "root" && state.manage === undefined && !state.showHiddenItems) {
    items.push(
      <DragComponent key={ -1 } x={ 0 } y={ y + layout.marginBottom - 60 } width={ width } height={ 60 } drag={ drag } index={ items.length + 1 } side={ DragSide.Right } isHide dispatch={ dispatch } />
    );
  }

  let dragItemMoving: JSX.Element | null = null;
  if (drag !== undefined && drag.touch !== undefined) {
    dragItemMoving = <div className="dashboard-item-drag-moving" style={{ left: drag.touch.currentX + "px", top: drag.touch.currentY + "px" }}><Glyphicon icon="move" /></div>;
  }

  return [
    <div className="dashboard-page-items">
      { items }
      { hiddenMarker }
      { dragItems }
      { dragItemSpot }
      { dragItemMoving }
    </div>,
    y - layout.margin + layout.marginBottom,
  ];
}

function DragComponent({ x, y, width, height, index, side, drag, isHide, dispatch }: { x: number, y: number, width: number, height: number, index: number, drag: DragState, side: DragSide, isHide?: boolean, dispatch: Dispatch<State> }) {
  let className = "dashboard-item-droppable";
  if (isHide) {
    className += " dashboard-item-droppable-hide";
    if (drag.hover !== undefined && drag.hover.index === index && drag.hover.side === side) {
      className += " dashboard-item-droppable-selected";
    }
  }

  return <div
    className={ className }
    onDragEnter={ (e) => {
      e.preventDefault();
      dispatch(action.drag.hoverStart("drag", index, side)) 
    } }
    onDragExit={ (e) => {
      e.preventDefault();
      dispatch(action.drag.hoverEnd("drag", index, side))
    } }
    onDragOver={ (e) => { e.preventDefault(); } }
    onDrop={ () => { dispatch(action.drag.dragDrop, true) } }
    data-index={ index }
    data-side={ side }
    style={{ left: x + "px", top: y + "px", width: width + "px", height: height + "px" }}>
    { isHide ? "Verberg" : null }
  </div>;
}

function dragShouldMoveItem(selected: number, hover: number, index: number) {
  if (selected === hover) return false;
  if (selected < hover) {
    if (index <= selected) return false;
    if (index >= hover) return false;
    return true;
  } else {
    if (index >= selected) return false;
    if (index < hover) return false;
    return true;
  }
}
