import * as React from "react";
import * as action from "../action";
import { State, getCurrentItem, getSourceForItem, chartNumberKeys, Chart, SourceSplit, FilterOptions, filterKeys, filterNames, SourceSplitDate, getBenchmarkSourceForItem, DashboardBenchmarkSourceItem, Item, NoDashboardReason, DirectoryLayout } from "../model";
import { Dispatch } from "../../utils/state";
import { Input, Glyphicon, Select, Checkbox } from "../../utils/view";
import { translate } from "../../translations/get";
import { ParseError } from "../model/atformula";
import { t } from "../../translations";

export function ManageOverlay({ state, dispatch }: { state: State, dispatch: Dispatch<State> }) {
  if (state.dashboard.type !== "dashboard" || state.manage === undefined || !state.manage.edit) {
    return null;
  }

  if (state.manage.committedAction !== undefined) {
    return (
      <div className="dashboard-manage-tool dashboard-manage-tool-loading">{ t.notices.change_is_being_applied }</div>
    );
  }

  if (state.manage.tool === undefined) {
    const item = getCurrentItem(state);
    const source = item !== undefined && item.type === "item" ? getSourceForItem(state, item) : undefined;
    const children = item === undefined || item.items === undefined ? 0 : item.items.length;
    return (
      <div className="dashboard-manage-menu">
        <MenuItem onClick={ () => dispatch(action.manage.startEditTitle) } icon="font" text={ state.path.length === 0 ? t.dashboard_manage.actions.edit_motto : t.dashboard_manage.actions.edit_title } />
        { source !== undefined && (source.type === "at" || source.type === "audit") ? <MenuItem onClick={ () => dispatch(action.manage.startEditFilters(undefined)) } icon="list" text={ t.dashboard_manage.actions.edit_filters } /> : null }
        { source !== undefined && source.type === "directory" ? <MenuItem onClick={ () => dispatch(action.manage.startEditWeights) } icon="list" text={ t.dashboard_manage.actions.edit_unit_weights } /> : null }
        { source !== undefined && source.type === "at" ? <MenuItem onClick={ () => dispatch(action.manage.startEditAtFormula(undefined)) } icon="wrench" text={ t.dashboard_manage.actions.edit_at_formula } /> : null }
        { state.path.length === 0 ? null : <MenuItem onClick={ () => dispatch(action.manage.startManageCharts) } icon="stats" text={ t.dashboard_manage.actions.edit_charts } /> }
        { state.path.length === 0 ? null : <MenuItem onClick={ () => dispatch(action.manage.startEditNorm) } icon="edit" text={ t.dashboard_manage.actions.edit_norm_trend } /> }
        { state.path.length === 0 ? null : <MenuItem onClick={ () => dispatch(action.manage.removeItem) } icon="trash" text={ t.dashboard_manage.actions.delete_item } /> }
        { state.path.length === 0 || !state.manage.admin ? null : <MenuItem onClick={ () => dispatch(action.manage.startEditBenchmark) } icon="dashboard" text={ t.dashboard_manage.actions.set_benchmark } /> }
        { children >= 2 && item !== undefined && item.layout === DirectoryLayout.Fixed ? <MenuItem onClick={ () => dispatch(action.manage.setDirectoryLayout(DirectoryLayout.Fluent)) } icon="picture" text={ t.dashboard_manage.actions.directory_layout_fixed } /> : null }
        { children >= 2 && item !== undefined && item.layout === DirectoryLayout.Fluent ? <MenuItem onClick={ () => dispatch(action.manage.setDirectoryLayout(DirectoryLayout.Fixed)) } icon="picture" text={ t.dashboard_manage.actions.directory_layout_fluent } /> : null }
        { state.path.length === 0 || source !== undefined && source.type === "directory" ? <MenuItem onClick={ () => dispatch(action.manage.startAddItem) } icon="plus" text={ t.dashboard_manage.actions.add_item } /> : null }
      </div>
    );
  }

  const tool = state.manage.tool;

  if (tool.type === "title") {
    const { title } = tool;
    return (
      <div className="dashboard-manage-tool dashboard-manage-tool-title">
        <form onSubmit={ (e) => { e.preventDefault(); dispatch(action.manage.saveTitle); } }>
          <h3>{ t.dashboard_manage.tools.title.caption }</h3>
          <Input id="dashboard-manage-title-en" value={ title.en } label={ t.languages.en } onChange={ (value) => dispatch(action.manage.editTitle("en", value), true) } />
          <Input id="dashboard-manage-title-nl" value={ title.nl } label={ t.languages.nl } onChange={ (value) => dispatch(action.manage.editTitle("nl", value), true) } />
          <br />
          <a href="javascript:;" onClick={ () => dispatch(action.manage.cancelTool) }>{ t.actions.cancel }</a> - <a href="javascript:;" onClick={ () => dispatch(action.manage.saveTitle) }>{ t.actions.save }</a>
        </form>
      </div>
    );
  } else if (tool.type === "add-item") {
    return (
      <div className="dashboard-manage-tool dashboard-manage-tool-title">
        <h3>{ t.dashboard_manage.tools.add_item.caption }</h3>
        { t.dashboard_manage.tools.add_item.description }
        <br />
        <a href="javascript:;" onClick={ () => dispatch(action.manage.addItem("directory")) }>{ t.dashboard_manage.tools.add_item.directory }</a><br />
        <a href="javascript:;" onClick={ () => dispatch(action.manage.addItem("audit")) }>{ t.dashboard_manage.tools.add_item.audit }</a><br />
        <a href="javascript:;" onClick={ () => dispatch(action.manage.addItem("at")) }>{ t.dashboard_manage.tools.add_item.at }</a><br /><br />
        <a href="javascript:;" onClick={ () => dispatch(action.manage.cancelTool) }>Annuleer</a>
      </div>
    );
  } else if (tool.type === "edit-weights") {
    return (
      <div className="dashboard-manage-tool dashboard-manage-tool-title">
        <form onSubmit={ (e) => { e.preventDefault(); dispatch(action.manage.saveTitle); } }>
          <h3>{ t.dashboard_manage.tools.edit_weights.caption }</h3>
          <Input id="dashboard-manage-edit-formula-unit" label={ t.dashboard_manage.tools.edit_weights.unit } value={ tool.unit } onChange={ (value) => dispatch(action.manage.editUnit(value), true) } />
          <Input id="dashboard-manage-edit-formula-decimals" label={ t.dashboard_manage.tools.edit_weights.decimals } value={ tool.decimals } onChange={ (value) => dispatch(action.manage.editDecimals(value), true) } type="number" />
          { tool.items.map((item, index) =>
              <Input key={ index } id={ "dashboard-manage-weight-" + index } label={ translate(item.title) } value={ item.weight } type="number" onChange={ (value) => dispatch(action.manage.editWeight(index, value), true) } />
          ) }
          <br />
          <a href="javascript:;" onClick={ () => dispatch(action.manage.cancelTool) }>{ t.actions.cancel }</a> - <a href="javascript:;" onClick={ () => dispatch(action.manage.saveWeights) }>{ t.actions.save }</a>
        </form>
      </div>
    );
  } else if (tool.type === "charts") {
    return (
      <div className="dashboard-manage-tool dashboard-manage-tool-charts">
        <h3>{ t.dashboard_manage.tools.charts.caption }</h3> - <a href="javascript:;" onClick={ () => dispatch(action.manage.cancelTool) }>{ t.actions.back }</a>
      </div>
    );
  } else if (tool.type === "edit-chart") {
    const options: { text: string, value: Chart["type"], }[] = [
      { text: t.dashboard.chart_type.number, value: "number" },
      { text: t.dashboard.chart_type.traffic_light, value: "traffic-light" },
      { text: t.dashboard.chart_type.bar, value: "bar" },
      { text: t.dashboard.chart_type.trend, value: "trend" },
    ];
    const splitOptions: { text: string, value: SourceSplit, }[] = tool.chartType === "trend"
      ? [
          { text: t.dashboard.chart_date_period.month, value: "date-1-month" },
          { text: t.dashboard.chart_date_period.quarter, value: "date-3-month" },
        ]
      : [
          { text: t.dashboard.filters.service, value: "service" },
          { text: t.dashboard.filters.location, value: "location" },
          { text: t.dashboard.filters.category, value: "category" },
          { text: t.dashboard.filters.client, value: "client" },
          { text: t.dashboard.filters.supplier, value: "supplier" },
        ];
    const labels = {
      min: t.dashboard_manage.tools.edit_chart.min,
      max: t.dashboard_manage.tools.edit_chart.max,
      norm: t.dashboard.item.norm,
      transitionGreenOrange: t.dashboard_manage.tools.edit_chart.transition_green_orange,
      transitionOrangeRed: t.dashboard_manage.tools.edit_chart.transition_orange_red,
    };
    return (
      <div className="dashboard-manage-tool dashboard-manage-tool-edit-chart">
        <h3>{ t.dashboard_manage.tools.edit_chart.caption }</h3>
        { tool.title === undefined ? null : <Input id="dashboard-manage-title-en" value={ tool.title.en } label={ t.languages.en } onChange={ (value) => dispatch(action.manage.editTitle("en", value), true) } /> }
        { tool.title === undefined ? null : <Input id="dashboard-manage-title-nl" value={ tool.title.nl } label={ t.languages.nl } onChange={ (value) => dispatch(action.manage.editTitle("nl", value), true) } /> }
        {
          <Select name="dashboard-manage-chart-type" onChange={ (value) => dispatch(action.manage.editChartField("chartType", value)) } label={ t.dashboard_manage.tools.edit_chart.chart_type } options={ options } selected={ tool.chartType } />
        }
        {
          tool.chartType === "bar" || tool.chartType === "trend"
            ? <Select name="dashboard-manage-chart-split" onChange={ (value) => dispatch(action.manage.editChartField("split", value)) } label={ t.dashboard_manage.tools.edit_chart.split_on } options={ splitOptions } selected={ tool.split } />
            : null
        }
        {
          chartNumberKeys(tool.chartType).map((key) =>
            <Input key={ key } id={ "dashboard-manage-chart-key-" + key } label={ labels[key] } value={ tool[key] } onChange={ (value) => dispatch(action.manage.editChartField(key, value), true) } />
          )
        }
        <br />
        { t.dashboard_manage.tools.edit_chart.info }
        <br />
        <a href="javascript:;" onClick={ () => dispatch(action.manage.startManageCharts) }>{ t.actions.cancel }</a> - <a href="javascript:;" onClick={ () => dispatch(action.manage.saveChart) }>{ t.actions.save }</a>
      </div>
    );
  } else if (tool.type === "edit-filters") {
    let keyOptions: { text: string, value: keyof FilterOptions | undefined }[] = [];
    if (tool.filters !== undefined) {
      keyOptions = filterKeys
        .filter((key) => tool.filters![key] !== undefined)
        .map((key) => {
          const count = tool.counts[key] || 0;
          return { text: `${ translate(filterNames)[key] } (${ count === 0 ? t.dashboard_manage.tools.edit_filters.all : `${ count } ${ t.dashboard_manage.tools.edit_filters.selected }` })`, value: key }
        });
    }
    if (tool.active === undefined) {
      keyOptions = [{ text: t.actions.select, value: undefined }, ...keyOptions];
    }

    const filterOptions = tool.active === undefined || tool.filters === undefined ? undefined : tool.filters[tool.active];

    return (
      <div className="dashboard-manage-tool">
        <h3>{ tool.benchmarkId === undefined ? t.dashboard_manage.tools.edit_filters.item_caption : t.dashboard_manage.tools.edit_filters.benchmark_caption }</h3>
        <Select dropdown={ true } name="dashboard-manage-edit-filter-active" onChange={ (value) => dispatch(action.manage.filterOptionsSetActive(value) )} label={ t.dashboard_manage.tools.edit_filters.choose_filters_for } options={ keyOptions } selected={ tool.active} />
        {
          filterOptions === undefined ? null :
            t.dashboard_manage.tools.edit_filters.info_no_selection
        }
        {
          filterOptions === undefined ? null :
            filterOptions.map((option, index) => <Checkbox key={ index } label={ option.label } checked={ option.selected} onChange={ () => dispatch(action.manage.toggleFilterOptions(index)) } />)
        }
        <a href="javascript:;" onClick={ () => dispatch(tool.benchmarkId === undefined ? action.manage.cancelTool : action.manage.startEditBenchmark) }>{ t.actions.cancel }</a> - <a href="javascript:;" onClick={ () => dispatch(action.manage.saveFilters) }>{ t.actions.save }</a>
      </div>
    );
  } else if (tool.type === "edit-formula") {
    return (
      <div className="dashboard-manage-tool">
        <h3>{ t.dashboard_manage.tools.edit_formula.caption }</h3>
        {
          tool.expression === undefined ? t.notices.loading :
            <Input id="dashboard-manage-edit-formula" label="Formule" value={ tool.expression } onChange={ (value) => dispatch(action.manage.editAtFormula(dispatch, value), true) } onBlur={ () => dispatch(action.manage.tryParseAtFormula)} />
        }
        {
          tool.unit === undefined || tool.benchmarkId !== undefined ? null :
            <Input id="dashboard-manage-edit-formula-unit" label="Eenheid" value={ tool.unit } onChange={ (value) => dispatch(action.manage.editUnit(value), true) } />
        }
        {
          tool.benchmarkId !== undefined ? null :
            <Input id="dashboard-manage-edit-formula-decimals" label="Aantal decimalen" value={ tool.decimals } onChange={ (value) => dispatch(action.manage.editDecimals(value), true) } type="number" />
        }
        {
          tool.result !== undefined && tool.expression !== undefined && tool.result.type === "parse-error" ? <AtParseError error={ tool.result } source={ tool.expression } /> : null
        }
        {
          tool.result !== undefined && tool.result.type !== "parse-error"
            ? <div><Glyphicon icon="check" /> { t.dashboard_manage.tools.edit_formula.valid_expression }</div>
            : null
        }
        <br />
        <a href="javascript:;" onClick={ () => dispatch(tool.benchmarkId === undefined ? action.manage.cancelTool : action.manage.startEditBenchmark) }>{ t.actions.cancel }</a> - <a href="javascript:;" onClick={ () => dispatch(action.manage.saveAtFormula) }>{ t.actions.save }</a>
        <br />
        { t.dashboard_manage.tools.edit_formula.expression_may_contain }
        <ul>
          <li>{ t.dashboard_manage.tools.edit_formula.expression_number }</li>
          <li>{ t.dashboard_manage.tools.edit_formula.expression_questions }</li>
          <li>{ t.dashboard_manage.tools.edit_formula.expression_arithmetics }</li>
          <li>{ t.dashboard_manage.tools.edit_formula.expression_parentheses }</li>
        </ul>
      </div>
    );
  } else if (tool.type === "edit-norm-trend") {
    const splitOptions: { text: string, value: SourceSplitDate | undefined, }[] = [
      { text: t.dashboard.chart_date_period.month, value: "date-1-month" },
      { text: t.dashboard.chart_date_period.quarter, value: "date-3-month" },
      { text: t.dashboard.chart_date_period.year, value: "date-12-month" },
      { text: t.dashboard_manage.tools.edit_norm_trend.no_trend, value: undefined },
    ];
    return (
      <div className="dashboard-manage-tool">
        <form onSubmit={ (e) => { e.preventDefault(); dispatch(action.manage.saveNorm); } }>
          <h3>{ t.dashboard_manage.tools.edit_norm_trend.norm_caption }</h3>
          <Input id="dashboard-manage-edit-norm" label="Norm" value={ tool.norm } onChange={ (value) => dispatch(action.manage.editNorm(value), true) } />
          <h3>{ t.dashboard_manage.tools.edit_norm_trend.trend_caption }</h3>
          { t.dashboard_manage.tools.edit_norm_trend.trend_description }
          <Select name="dashboard-manage-chart-split" onChange={ (value) => dispatch(action.manage.editTrend(value)) } label="Periode" options={ splitOptions } selected={ tool.trend } />

          <a href="javascript:;" onClick={ () => dispatch(action.manage.cancelTool) }>{ t.actions.cancel }</a> - <a href="javascript:;" onClick={ () => dispatch(action.manage.saveNorm) }>{ t.actions.save }</a>
        </form>
      </div>
    );
  } else if (tool.type === "edit-benchmark") {
    const item = getCurrentItem(state);
    const benchmarks = item !== undefined && item.type === "item" ? getBenchmarkSourceForItem(state, item) : undefined;
    return (
      <div className="dashboard-manage-tool">
        <h3>{ t.dashboard_manage.tools.set_benchmark.caption }</h3>
        { benchmarks === undefined ? null : 
          <ol>
            { benchmarks.sources.map(({ source, weight, period }, index) =>
              <BenchmarkSource key={ index } source={ source } period={ period } weight={ weight } index={ index } item={ item as Item } dispatch={ dispatch } />
            )}
          </ol>
        }
        { benchmarks === undefined ? t.dashboard_manage.tools.set_benchmark.no_benchmark : <h3>{ t.dashboard_manage.tools.set_benchmark.add_benchmark_source }</h3> }
        <br />
        <a href="javascript:;" onClick={ () => dispatch(action.manage.addBenchmarkSource("audit")) }>{ t.dashboard_manage.tools.set_benchmark.add_audit_source }</a><br />
        <a href="javascript:;" onClick={ () => dispatch(action.manage.addBenchmarkSource("at")) }>{ t.dashboard_manage.tools.set_benchmark.add_at_source }</a><br />
        <a href="javascript:;" onClick={ () => dispatch(action.manage.addBenchmarkSource("constant")) }>{ t.dashboard_manage.tools.set_benchmark.add_constant_source }</a><br />
        <br />
        <a href="javascript:;" onClick={ () => dispatch(action.manage.cancelTool) }>{ t.actions.close }</a>
      </div>
    );
  } else if (tool.type === "edit-benchmark-item") {
    const options = [3, 6, 12].map((months: 3 | 6 | 12) => ({ text: t.dashboard.time_period.full.last_months[months], value: months }));
    return (
      <div className="dashboard-manage-tool dashboard-manage-tool-title">
        <form onSubmit={ (e) => { e.preventDefault(); dispatch(action.manage.saveTitle); } }>
          <h3>{ t.dashboard_manage.tools.edit_benchmark_item.caption }</h3>
          { tool.value === undefined ? null :
            <Input id="dashboard-manage-benchmark-value" value={ tool.value } label={ t.dashboard_manage.tools.edit_benchmark_item.value } onChange={ (value) => dispatch(action.manage.editBenchmarkItem("value", value), true) } /> }
          <Input id="dashboard-manage-benchmark-weight" value={ tool.weight } label={ t.dashboard_manage.tools.edit_benchmark_item.weight } onChange={ (value) => dispatch(action.manage.editBenchmarkItem("weight", value), true) } />
          { tool.value !== undefined ? null :
          <Select dropdown={ true } name="dashboard-manage-benchmark-period" onChange={ (value: 3 | 6 | 12) => dispatch(action.manage.editBenchmarkPeriod(value))} label={ t.dashboard_manage.tools.edit_benchmark_item.period } options={ options } selected={ tool.period.type === "last-months" ? tool.period.months : -1 } /> }
          <br />
          <a href="javascript:;" onClick={ () => dispatch(action.manage.startEditBenchmark) }>{ t.actions.cancel }</a> - <a href="javascript:;" onClick={ () => dispatch(action.manage.saveBenchmarkItem) }>{ t.actions.save }</a>
        </form>
      </div>
    );
  }

  return null;
}

function AtParseError({ error, source }: { error: ParseError, source: string }) {
  return <div>
    <h3>{ t.dashboard_manage.tools.edit_formula.parse_error }</h3>
    <pre style={{ fontFamily: "Courier" }}>
      { source.substr(error.position - 30, 60) }
      <br />
      { new Array(Math.min(30, error.position) + 1).join(" ") + "^" }
    </pre>
    { t.dashboard_manage.tools.edit_formula.expected_on_position_left } { error.position } { t.dashboard_manage.tools.edit_formula.expected_on_position_right }
    <ul>
      {
        error.expected.map((value, index) => 
          <li key={ index }>{ value === "EOF" ? t.dashboard_manage.tools.edit_formula.eof : value }</li>
        )
      }
    </ul>
  </div>
}

function MenuItem({ onClick, icon, text }: { onClick(): void, icon: string, text: string }) {
  return <a href="javascript:;" className="btn btn-primary" onClick={ onClick }><Glyphicon icon={ icon } />{ text }</a>;
}

function BenchmarkSource({ item, source, weight, index, dispatch }: DashboardBenchmarkSourceItem & { item: Item, index: number, dispatch: Dispatch<State> }) {
  let name;
  let actions;

  if (typeof source === "number") {
    name = t.dashboard_manage.tools.set_benchmark.constant + ": " + source.toFixed(item.decimals);
    actions = [{ name: t.actions.edit, action: () => dispatch(action.manage.startEditBenchmarkItem(index)) }];
  } else if (source.type === "audit") {
    name = t.dashboard_manage.tools.set_benchmark.audit;
    actions = [{ name: t.dashboard_manage.tools.set_benchmark.edit_weight_period, action: () => dispatch(action.manage.startEditBenchmarkItem(index)) }, { name: "Wijzig filters", action: () => dispatch(action.manage.startEditFilters(index)) }];
  } else if (source.type === "at") {
    name = t.dashboard_manage.tools.set_benchmark.at;
    actions = [
      { name: t.dashboard_manage.tools.set_benchmark.edit_weight_period, action: () => dispatch(action.manage.startEditBenchmarkItem(index)) },
      { name: t.dashboard_manage.tools.set_benchmark.edit_filters, action: () => dispatch(action.manage.startEditFilters(index)) },
      { name: t.dashboard_manage.tools.set_benchmark.edit_at_formula, action: () => dispatch(action.manage.startEditAtFormula(index)) }
    ];
  } else {
    return null;
  }

  actions.push({ name: t.actions.delete, action: () => dispatch(action.manage.removeBenchmarkSource(index)) });

  return <li>
    { name + " - " + t.dashboard_manage.tools.edit_benchmark_item.weight + ": " + weight }
    <div className="btn-group">
      { actions.map(({ name, action }, index) => <a key={ index } href="javascript:;" className="btn btn-primary" onClick={ () => action() }>{ name }</a>) }
    </div>
    <br />
  </li>;
}
