import { Root, Item, DirectoryLayout } from "./item";
import { State } from "./state";

export const topBarHeightWithoutBreadcrumbs = 111 - 55;
export const topBarHeightWithBreadcrumbs = 111;

// Height of the interface above the contents of the dashboard
export const interfaceTopHeight = 70;

export const interfaceTopFiltersHeight = 40;

// Height of the details graphs
export const detailGraphHeight = 440;

export const smallDetailGraphHeight = 400;

// Margin at bottom, which we should be empty if possible.
export const marginBottom = 30;

// Margin between dashboard items and margin between the detail chart and the items.
export const margin = 15;

// Height for the button to show hidden items
export const hiddenItemsHeaderHeight = 30;

export interface ItemLayout {
  itemsPerRow: number[],
  firstHiddenRow: number,
  style: DirectoryLayout,
}

export const emptyItemLayout: ItemLayout = { itemsPerRow: [], firstHiddenRow: 0, style: DirectoryLayout.Fluent };

export function getMainHeight(windowHeight: number, item: Root | Item, hasOtherContent: boolean) {
  let height = windowHeight - interfaceTopHeight - marginBottom;
  if (item.type === "item") {
    if (item.detailCharts.length === 0) {
      height -= topBarHeightWithBreadcrumbs;
    } else {
      height -= detailGraphHeight + margin + topBarHeightWithBreadcrumbs;
    }
  } else {
    height -= topBarHeightWithoutBreadcrumbs;
  }
  if (hasOtherContent) height -= smallDetailGraphHeight + margin;
  if (item.type === "root" && item.firstHidden !== item.items.length) height -= hiddenItemsHeaderHeight + margin;
  return height;
}

export function getItemLayout(state: State, item: Root | Item) {
  if (item.layout === DirectoryLayout.Fluent) {
    return getItemsPerRowFluent(state.width, state.windowHeight, item);
  } else {
    return getItemsPerRowFixed(state.width, state.windowHeight, item);
  }
}

export function getItemsPerRowFluent(width: number, windowHeight: number, item: Root | Item): ItemLayout {
  const children = (item.items || []).length;

  const height = getMainHeight(windowHeight, item, false);
  const maxPerRow = Math.max(Math.round(width / 160), 1);

  const firstHidden = item.type === "root" ? item.firstHidden : children;
  const itemsPerRow = getItemsPerRowWorker(width, height, firstHidden, maxPerRow);
  const firstHiddenRow = itemsPerRow.length;

  let remaining = children - firstHidden;
  while (remaining > 0) {
    const row = Math.min(remaining, maxPerRow);
    itemsPerRow.splice(firstHiddenRow, 0, row);
    remaining -= row;
  }

  return { itemsPerRow, firstHiddenRow, style: DirectoryLayout.Fluent };
}

function getItemsPerRowWorker(width: number, height: number, children: number, maxPerRow: number) {
  let rows: number[] = [];

  for (rows of find()) {
    if (rows.length === 0 || 1.6 * width / rows[0] >= height / rows.length) {
      return rows;
    }
  }
  return rows;

  function* find() {
    for (let nextTry = Math.max(Math.round(width / 400), 1); nextTry >= 1; nextTry--) {
      yield* findWithFirstRow(nextTry);
    }
  }

  function* findWithFirstRow(firstRowDefault: number) {
    const itemsPerRow: number[] = [];

    let remaining = children;
    let nextRow = firstRowDefault;
    while (remaining !== 0) {
      if (remaining < nextRow) {
        nextRow = remaining;
      }

      itemsPerRow.push(nextRow);
      remaining -= nextRow;
      nextRow++;
      if (nextRow > maxPerRow) {
        nextRow = maxPerRow;
      }
    }

    if (itemsPerRow.length < 2) {
      yield itemsPerRow;
      return;
    }

    const last = itemsPerRow[itemsPerRow.length - 1];
    const secondLast = itemsPerRow[itemsPerRow.length - 2];
    if (last >= secondLast) {
      yield itemsPerRow;
      return;
    }

    // `last < secondLast`, should be the other way around.
    // We try to add elements to last row if possible,
    // or to remove all elements from last row and move them to other rows.

    // Remove everything from last row and add those items to other rows.
    const alternative = itemsPerRow.slice(0, -1);

    remaining = last;
    for (let row = alternative.length - 1; row >= 0; row--) {
      const reduce = Math.ceil(remaining / (row + 1));
      alternative[row] += reduce;
      remaining -= reduce;
    }

    yield alternative;

    if (secondLast - last < itemsPerRow.length - 2) {
      // Add more to last row
      const newRows = [...itemsPerRow];
      newRows[newRows.length - 1] = secondLast;
      for (let todo = secondLast - last; todo >= 0; todo--) {
        newRows[newRows.length - 2 - todo]--;
      }

      yield newRows;
    }
  }
}

export function getItemsPerRowFixed(width: number, windowHeight: number, item: Root | Item): ItemLayout {
  // Minimize number of empty slots at last row
  // Ratio item width and item height should be close to one
  const children = (item.items || []).length;
  const firstHidden = item.type === "root" ? item.firstHidden : children;

  const height = getMainHeight(windowHeight, item, false);
  const maxPerRow = Math.max(Math.round(width / 160), 1);

  let bestPerRow = 1;
  let bestValue = -Infinity;

  for (let perRow = maxPerRow; perRow > 0; perRow--) {
    const rows = ((firstHidden + perRow - 1) / perRow) | 0;
    let value = 0;
    if (firstHidden % perRow !== 0) value -= 1;

    const itemWidth = width / perRow;
    const itemHeightRaw = height / rows;
    const itemHeight = Math.max(260, itemHeightRaw);

    if (itemHeightRaw < 260) {
      // Does not fit on the screen. Penalty for the proportion that is not visible.
      value -= (itemHeight * rows) / height - 0.9;
    }

    // Is the ratio close to 1?
    value += Math.min(itemWidth, itemHeight) / Math.max(itemWidth, itemHeight) * 2;

    if (value > bestValue) {
      bestValue = value;
      bestPerRow = perRow;
    }
  }

  let remaining = firstHidden;
  const itemsPerRow: number[] = [];
  while (remaining > 0) {
    itemsPerRow.push(Math.min(bestPerRow, remaining));
    remaining -= bestPerRow;
  }
  const firstHiddenRow = itemsPerRow.length;
  remaining = children - firstHidden;
  while (remaining > 0) {
    itemsPerRow.push(Math.min(bestPerRow, remaining));
    remaining -= bestPerRow;
  }
  return { itemsPerRow, firstHiddenRow, style: DirectoryLayout.Fixed };
}

