import * as React from "react";
import { scaleToFit } from "../utils";
import { NumberChart, getTrendSign, RedFlagVisibility, Item } from "../model";
import { TripletPicture, Orientation } from "./trafficlightchart";
import * as colors from "./colors";

// The decimals and unit are shown in a smaller font, with this factor.
const unitFactor = 3 / 5;
const margin = 0; // Margin between value and (decimals and unit).

export function NumberPicture({ item, chart, width, height }: { item: Item, chart: NumberChart, width: number, height: number }) {
  return <TripletPicture width={ width } height={ height } element={ element } />;

  function element(index: number, x: number, y: number, elementWidth: number, elementHeight: number, scale: number, orientation: Orientation) {

    if (index === 1) {
      // Middle: show value
      if (chart.text === undefined || chart.textDecimalUnit === undefined) return <g />;

      const totalTextWidth = chart.text.width + chart.textDecimalUnit.width * unitFactor + margin;
      const textAvailableWidth = orientation === Orientation.Horizontal ? elementWidth * 1.4 : elementWidth * 0.7
      const textScale = scaleToFit(textAvailableWidth, totalTextWidth, textAvailableWidth * 0.38);

      const left = x - totalTextWidth * textScale / 2;
      const unitLeft = left + (chart.text.width + margin) * textScale;

      return <g>
        <text x={ left + "px" } y={ y + "px" } fill="currentColor" fontSize={ textScale + "px" } textAnchor="left" dominantBaseline="middle">{ chart.text.content }</text>
        <text x={ unitLeft + "px" } y={ (y - textScale * 0.52) + "px" } fill="currentColor" fontSize={ textScale * unitFactor + "px" } textAnchor="left" dominantBaseline="hanging">{ chart.textDecimalUnit.content }</text>
      </g>;
    }

    const fontSize = (orientation === Orientation.Horizontal
      ? Math.min(Math.max(elementWidth * 3.33, elementHeight) * 0.143, 35)
      : Math.min(Math.max(elementWidth, elementHeight * 3.33) * 0.143, 35)) * 0.5;

    let color = "";
    let icon = "";

    if (index === 0) {
      // Left: show trend
      const trend = getTrendSign(item);
      if (trend === 1) {
        icon = "\uE113"; // chevron-up
        color = colors.success;
      } else if (trend === -1) {
        icon = "\uE114"; // chevron-down
        color = colors.danger;
      } else if (trend === 0) {
        icon = "\uE165";
        color = colors.info;
      }
    } else if (index === 2) {
      if (item.redflags !== undefined && (item.redFlagVisibility === RedFlagVisibility.Boolean || item.redFlagVisibility === RedFlagVisibility.Number)) {
        icon = "\uE034";
        color = item.redflags === 0 ? colors.success : colors.danger;
      }
    }

    return <text x={ x } y={ y } fill={ color } textAnchor="middle" dominantBaseline="middle" fontFamily="Glyphicons Halflings" fontSize={ fontSize + "px" }>{ icon }</text>;
  }
}
