import { Action } from "../../utils/state";
import { State, Item, Root, FieldFilters, FilterOptionsState, updateFilterOptions, getItem, Tool, filtersToString, BarChart, doesManage, TimePeriod, UserFilters, FilterState, TimePeriodCustom, UserFilterKeys, FilterOptionState, userFilterFields, emptyFilterOptions, SourceDetailsState, getTimeRange, isSourceSplitDate, RedFlagDetailsState } from "../model";
import { getItemLayout, emptyItemLayout } from "../model/layout";
import * as manage from "./manage";
import * as drag from "./drag";

export { manage, drag };

// Opens an item. Shows the detail chart(s) of this item and its nested items
// in case of a directory.
export const openItem = (item: Item): Action<State> => (state) => {
  const path = [...state.path, item.id];
  return { ...state, path, activeTool: undefined };
};

// Closes an item and goes to the parent item.
export const closeItem: Action<State> = (state) => {
  const path = state.path.slice(0, -1);
  return { ...state, path, activeTool: undefined };
};

export const removePreferences: Action<State> = (state) => {
  return {
    ...state,
    user: {
      preferences: { order: [], hidden: [], },
      shouldPush: true,
    },
  };
};

export const toggleHiddenItems: Action<State> = (state) => ({
  ...state,
  showHiddenItems: !state.showHiddenItems,
});

// Selects a detail chart.
export const selectDetailChart = (index: number | undefined): Action<State> => (state) => {
  if (state.dashboard.type !== "dashboard" || state.path.length === 0) {
    // This should never apply,
    // but we handle it to prevent runtime errors
    return state;
  }

  const id = state.path[state.path.length - 1];
  const page = getItem(state, id);

  if (page === undefined || page.type === "root") {
    // When the root is shown, no detail charts are visible.
    // So this should never apply.
    return state;
  }

  const newPage = { ...page, selectedDetailChart: index };

  return changeItem(newPage)(state);
};

// Opens the provided submenu. If this menu is already opened, it will be closed
export const toggleFiltersSubmenu = (submenu: UserFilterKeys | undefined): Action<State> => (state) => ({
  ...state,
  activeTool: Tool.Filter,
  filters: { ...state.filters, submenu: state.filters.submenu === submenu ? undefined : submenu },
});

export const openTool = (tool: Tool): Action<State> => (state) => {
  if (state.activeTool !== tool) return toggleTool(tool)(state);
  return state;
};

export const toggleTool = (tool: Tool | undefined): Action<State> => (state) => {
  if (tool === Tool.Source && state.path.length === 0) return state;

  const activeTool = state.activeTool === tool ? undefined : tool;
  let filters = state.filters;

  if (state.activeTool === Tool.Filter) {
    // User is closing the filter tool
    filters = { ...filters, submenu: undefined };
  }

  return { ...state, activeTool, filters, sourceDetails: undefined, redFlagDetails: undefined };
};

export const closeTool = toggleTool(undefined);

export const clickChart: Action<State> = (state) => {
  if (state.activeTool !== Tool.Source && state.activeTool !== Tool.RedFlags) return toggleTool(Tool.Source)(state);
  return state;
};

export const toggleFilter = (field: UserFilterKeys, index: number): Action<State> => (state) => {
  const oldFieldState = state.filters.options[field];

  if (oldFieldState === undefined || oldFieldState.list === undefined) return state;

  const option = oldFieldState.list[index];

  const newOptions = [
    ...oldFieldState.list.slice(0, index),
    { label: option.label, value: option.value, selected: !option.selected },
    ...oldFieldState.list.slice(index + 1),
  ];
  const fieldState: FilterOptionState = {
    ...oldFieldState,
    list: newOptions,
  };

  const filterOptions: FilterOptionsState = { ...state.filters.options, [field]: fieldState };

  return { ...state, activeTool: Tool.Filter, filters: updateFilterOptions(state.filters, filterOptions) };
};

export const setTimePeriod = (period: TimePeriod): Action<State> => (state) => {
  const filters = { ...state.filters.current, period };
  return { ...state, filters: { current: filters, string: filtersToString(filters), optionKeys: state.filters.optionKeys, options: emptyFilterOptions(state.filters.optionKeys), submenu: undefined }, sourceDetails: undefined, redFlagDetails: undefined };
};

export const setTimePeriodCustom: Action<State> = (state) => {
  const [ start, end ] = getTimeRange(state.filters.current.period);
  return setTimePeriod({ type: "custom", start, end })(state);
};

export const setTimePeriodDate = (key: "start" | "end", date: Date | null): Action<State> => (state) => {
  if (state.filters.current.period.type !== "custom" || date === null) return state;
  const period: TimePeriodCustom = { ...state.filters.current.period };
  period[key] = date;
  if (+period.start > +period.end) {
    if (key === "end") {
      period.start = period.end;
    } else {
      period.end = period.start;
    }
  }
  const current: UserFilters = { ...state.filters.current, period };
  const filters: FilterState = {
    ...state.filters,
    current,
    string: filtersToString(current),
  };
  return { ...state, filters };
};

export const selectSourceDetailsItem = (selected: number | undefined): Action<State> => (state) => {
  if (state.sourceDetails === undefined || state.sourceDetails.type === "loading" || state.sourceDetails.selected === selected) return state;
  const sourceDetails: SourceDetailsState = {
    ...state.sourceDetails,
    selected,
    popup: undefined,
  };
  return { ...state, sourceDetails };
};

export const selectRedFlag = (selected: number | undefined): Action<State> => (state) => {
  if (state.redFlagDetails === undefined || state.redFlagDetails.type === "loading" || state.redFlagDetails.selected === selected) return state;
  const redFlagDetails: RedFlagDetailsState = {
    ...state.redFlagDetails,
    selected,
    popup: undefined,
  };
  return { ...state, redFlagDetails };
};

export const selectGroupFilterInChart = (chart: BarChart, group: string): Action<State> => (state) => {
  if (chart.bars !== undefined && chart.barGroups !== undefined && chart.bars.length === 0 && chart.barGroups.length === 1) return state;
  const fields = state.filters.current.fields || {};
  const newFields: FieldFilters = { ...fields, [chart.split]: [group] };
  const filters: UserFilters = { ...state.filters.current, fields: newFields };
  return { ...state, filters: { current: filters, string: filtersToString(filters), optionKeys: state.filters.optionKeys, options: emptyFilterOptions(state.filters.optionKeys), submenu: undefined }, sourceDetails: undefined, redFlagDetails: undefined };
};

export const closeGroupFilterInChart = (chart: BarChart, group: string): Action<State> => (state) => {
  if (chart.bars === undefined || chart.barGroups === undefined || isSourceSplitDate(chart.split)) return state;

  for (const barGroup of chart.barGroups) {
    if (barGroup.id === group) {
      const fields = state.filters.current.fields || {};

      let newList: (string | null)[] | undefined = fields[chart.split];
      const bars = barGroup.bars.map((bar) => bar.id);
      if (newList !== undefined) {
        newList = newList.filter((value) => value !== group && bars.indexOf(value) === -1);
        if (newList.length === 0) newList = undefined;
      }

      const newFields: FieldFilters = { ...fields, [chart.split]: newList };
      const filters: UserFilters = { ...state.filters.current, fields: newFields };
      return { ...state, filters: { current: filters, string: filtersToString(filters), optionKeys: state.filters.optionKeys, options: emptyFilterOptions(state.filters.optionKeys), submenu: undefined }, sourceDetails: undefined, redFlagDetails: undefined };
    }
  }
  return state;
};

export const toggleFilterInChart = (isDetailChart: boolean, chart: BarChart, group: string | undefined, id: string | null): Action<State> => (state) => {
  if (chart.bars === undefined || chart.barGroups === undefined || isSourceSplitDate(chart.split)) return state;

  if (state.activeTool === Tool.Filter || doesManage(state)) return state;

  if (chart.bars.length === 1 && chart.barGroups.length === 0) {
    // Not something to filter, ignore
    return state;
  }

  if (chart.barGroups.length === 1 && chart.bars.length === 0) {
    // Only one group, so we can ignore the group.
    group = undefined;
  }

  const fields = state.filters.current.fields || {};
  let newFields;
  const currentList: (string | null)[] | undefined = fields[chart.split];
  if (currentList === undefined || !isDetailChart) {
    // For non-detail charts, we will add a singleton filter, as that is the most intuitive.
    newFields = { ...fields, [chart.split]: [id] };
  } else {
    if (currentList.indexOf(id) === -1) {
      if (hasTwoBars()) {
        // The multi-select works counter intuitive when there are two bars,
        // so we just work with a single select in this case.
        newFields = { ...fields, [chart.split]: [id] };
      } else {
        let newList = currentList;
        if (group) {
          newList = newList.filter((value) => value !== group);
          for (const barGroup of chart.barGroups) {
            if (barGroup.id === group) {
              if (barGroup.bars.length === 2) {
                // The multi-select works counter intuitive when there are two bars,
                // so we just work with a single select in this case.
                newList = [];
              }
              break;
            }
          }
        } else {
          // Remove any filters from groups & locations in groups
          newList = chart.bars.filter((bar) => newList.indexOf(bar.id) !== -1).map((bar) => bar.id);
        }
        newFields = { ...fields, [chart.split]: [...newList, id].sort() };
      }
    } else if (currentList.length === 1) {
      newFields = { ...fields, [chart.split]: group === undefined ? undefined : [group] };
    } else {
      let newList = currentList.filter((item) => item !== id);
      if (group !== undefined) {
        outer: for (const barGroup of chart.barGroups || []) {
          if (barGroup.id === group) {
            for (const bar of barGroup.bars) {
              if (newList.indexOf(bar.id) !== -1) break outer;
            }
            // No selection remains in the current group. Add the group itself.
            newList = [...newList, group].sort();
            break;
          }
        }
      }
      newFields = { ...fields, [chart.split]: newList };
    }
  }

  const filters = { ...state.filters.current, fields: newFields };

  return { ...state, filters: { current: filters, string: filtersToString(filters), optionKeys: state.filters.optionKeys, options: emptyFilterOptions(state.filters.optionKeys), submenu: undefined }, sourceDetails: undefined, redFlagDetails: undefined };

  function hasTwoBars() {
    if (chart.bars === undefined || chart.barGroups === undefined) return false;

    if (chart.bars.length === 2) return chart.barGroups.length === 0;
    if (chart.bars.length === 1) return chart.barGroups.length === 1 && chart.barGroups[0].bars.length === 1;
    if (chart.bars.length === 0) {
      if (chart.barGroups.length === 2) {
        return chart.barGroups[0].bars.length === 1 && chart.barGroups[1].bars.length === 1;
      }
    }
    return false;
  }
};

export const removeAllFilters: Action<State> = (state) => {
  const filterOptions = { ...state.filters.options };

  for (const key of userFilterFields) {
    const values = filterOptions[key];
    if (values === undefined) continue;

    filterOptions[key]!.list = values.list === undefined ? undefined : values.list.map((item) => ({ ...item, selected: false }));
  }
  return { ...state, filters: updateFilterOptions(state.filters, filterOptions), sourceDetails: undefined, redFlagDetails: undefined };
};

export const removeFilters = (field: UserFilterKeys): Action<State> => (state) => {
  const option = state.filters.options[field];
  if (option === undefined || option.list === undefined) return state;

  const filterOptions: FilterOptionsState = {
    ...state.filters.options,
    [field]: {
      ...option,
      list: option.list.map((item) => ({ ...item, selected: false })),
    },
  };
  return { ...state, filters: updateFilterOptions(state.filters, filterOptions)};
};

// Changes an item or the root in the state.
const changeItemOrRoot = (newValue: Item | Root): Action<State> => (state) => {
  if (state.dashboard.type !== "dashboard") {
    // This should never apply, but we handle it to prevent runtime errors
    return state;
  }
  if (newValue.type === "root") {
    const dashboard = { ...state.dashboard, root: newValue };
    return { ...state, dashboard };
  }
  return changeItem(newValue)(state);
};

const changeItem = (newItem: Item): Action<State> => (state) => {
  if (state.dashboard.type !== "dashboard") {
    // This should never apply, but we handle it to prevent runtime errors
    return state;
  }
  const items = { ...state.dashboard.items, [newItem.id]: newItem };
  const dashboard = { ...state.dashboard, items };
  return { ...state, dashboard };
};

export const resize = (width: number, windowHeight: number): Action<State> => (state) => updateLayout({ ...state, width, windowHeight });

export const updateLayout: Action<State> = (state) => {
  let rootLayout: State["rootLayout"] = emptyItemLayout;
  const itemLayouts: State["itemLayouts"] = {};

  if (state.dashboard.type === "dashboard") {
    rootLayout = getItemLayout(state, state.dashboard.root);

    for (const key of Object.keys(state.dashboard.items)) {
      itemLayouts[key] = getItemLayout(state, state.dashboard.items[key]!);
    }
  }

  return { ...state, rootLayout, itemLayouts };
};
