import { Translations } from "../../translations";
import { Chart, SourceSplitDate } from "./chart";

export function getItemFromDashboard(dashboard: Dashboard, id: string | undefined) {
  if (id === undefined) {
    return dashboard.root;
  } else {
    return dashboard.items[id];
  }
}

export interface Dashboard {
  type: "dashboard",
  id: string,
  rawId: string,
  root: Root,
  items: { [id: string]: Item | undefined },
}

export interface ItemBase {
  itemId: string,
  title: Translations,
  // If the current item is a directory (or root), this denotes the layout of the child elements.
  layout: DirectoryLayout,
  items?: string[],
}

export enum RedFlagVisibility {
  Hidden,
  Number,
  Boolean,
}

export enum DirectoryLayout {
  Fluent,
  Fixed,
}

export interface Item extends ItemBase {
  id: string,
  // id that is preserved, even after copying the dashboard for a new version.
  originalId: string,
  type: "item",
  sourceType: "audit" | "at" | "directory",
  // Key of the parent item. If the parent is the root,
  // this field will be `undefined`.
  parent: string | undefined,
  chart: Chart,
  detailCharts: { title: Translations, chart: Chart }[],
  chartAsDetail: boolean,
  selectedDetailChart: number | undefined,
  decimals: number,
  hasBenchmark: boolean,
  norm?: number,
  value?: number,
  redflags?: number,
  redFlagVisibility?: RedFlagVisibility,
  trendPeriod: SourceSplitDate | undefined,
  trend?: number,
  benchmark?: number,
}

export function getTrendSign(item: Item): -1 | 0 | 1 | undefined {
  if (item.trend === undefined) return undefined;
  if (Math.abs(item.trend) < 10 ** (-item.decimals)) return 0;
  return item.trend > 0 ? 1 : -1;
}

export interface Root extends ItemBase {
  type: "root",
  items: string[],
  firstHidden: number,
}

export function restrictDashboard(dashboard: Dashboard, compatible: { [id: string]: boolean | undefined }) {
  handleDirectory(dashboard.root);

  function handleDirectory(item: ItemBase): boolean {
    if (item.items != null) {
      item.items = item.items.filter(handle);
      return item.items.length > 0;
    }
    return false;
  }

  function handle(id: string): boolean {
    const item = dashboard.items[id];

    if (item == null) return false;

    if (item.items != null) {
      return handleDirectory(item);
    } else {
      return compatible[id] === true; // compatible[id] can be undefined
    }
  }
}
