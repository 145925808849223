export function mapObject<K extends string, T, V>(object: { [_ in K]?: T }, fn: (x: T, key: K) => V): { [_ in K]?: V};
export function mapObject<O extends { [K in string]: any }, V>(object: O, fn: (x: O[keyof O], key: keyof O) => V): { [_ in keyof O]?: V};
export function mapObject<K extends string, T, V>(object: { [_ in K]?: T }, fn: (x: T, key: K) => V): { [_ in K]?: V} {
  const result: { [_ in K]?: V} = {};

  for (const key of Object.keys(object) as K[]) {
    const value: T | undefined = object[key];
    if (value === undefined) continue;
    result[key] = fn(value, key);
  }
  return result;
}
