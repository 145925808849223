// This program has been developed by students from the bachelor Computer Science
// at Utrecht University within the Software and Game project course (spring 2017)
// © Copyright Utrecht University (Department of Information and Computing Sciences)

// This little snippet allows you to conditionally show divs depending on the selected value in a dropdown
//
// Example:
//
// <select id="demo-select">
//   <option value="1">First option</option>
//   <option value="2">Second option</option>
// </select>
// <div select-conditional="demo-select" conditional-value="1">
//   Only shown when first option is selected
// </div>
// <div select-conditional="demo-select" conditional-value="2">
//   Only shown when second option is selected
// </div>

export function enableConditionalSelect() {
  $("select").each(function(_: number, sel: HTMLInputElement) {
    const changedisplay = function(el: HTMLInputElement) {
      const sc = $(`*[select-conditional="${el.id}"]`);
      sc.hide();
      sc.filter(`*[conditional-value="${el.value}"]`).show();
    };
    $(sel).on("change", (e) => { changedisplay(e.target); });
    changedisplay(sel);
  });
}
