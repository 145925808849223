import { findParent } from "../utils/html";
import { enableDatePicker } from "../utils/form";
import { isMobile } from "./navigation";

// Returns true when an action was performed (opening a popup or triggering the default action)
export function clickMobileTableRow(row: HTMLTableRowElement, editableForm?: HTMLFormElement, editableFields?: { [index: number]: HTMLElement }): boolean {
  const classNames = row.className.split(" ");

  const table = row.parentElement!.parentElement as HTMLTableElement;
  const tableClassNames = table.className.split(" ");
  const hasHiddenColumns = tableClassNames.indexOf("table-hide-column") !== -1;
  const hasSingleHiddenColumn = tableClassNames.indexOf("table-hide-column-single") !== -1;

  const mobile = isMobile();

  const editable = editableForm !== undefined && editableFields !== undefined;

  if (classNames.indexOf("table-row-mobile-default") !== -1 && (hasSingleHiddenColumn || (!hasHiddenColumns && mobile)) && !editable) {
    const td = row.children[row.children.length - 1];
    if (td == null || td.children.length === 0) return false;
    const button = td.children[0];
    if (button.tagName !== "A") return false;
    (button as HTMLAnchorElement).click();
    return true;
  }

  const showMobilePopup = classNames.indexOf("table-row-mobile-popup") !== -1 && mobile;

  if (!hasHiddenColumns && !showMobilePopup && !editable) return false; // No reason to show the popup

  const header = table.tHead == null ? getHeader(row.parentElement!.children) : table.tHead.rows[0];

  const elements: HTMLElement[] = [];

  let finalButton: HTMLElement | undefined;
  for (let i = 0; i < row.children.length; i++) {
    const td = row.children[i];
    if (td.tagName !== "TD") continue;

    const th = header === undefined || i >= header.children.length ? null : header.children[i];
    if (th === null) continue;

    if (editableFields !== undefined && editableFields[i] !== undefined) {
      appendList(th.childNodes, "action-popup-header");
      appendList(editableFields[i].childNodes, "action-popup-field");
    } else {
      if (td.childNodes.length === 0) continue;
      appendList(th.childNodes, "action-popup-header");
      appendList(td.childNodes, "action-popup-field");
    }
  }

  if (finalButton !== undefined) {
    elements.push(finalButton);
  }

  if (editableForm !== undefined) {
    for (const element of elements) {
      editableForm.appendChild(element);
    }
    setActionPopup([editableForm]);
    enableDatePicker($("#action-popup-content").find(".datepicker") as any);
  } else {
    setActionPopup(elements);
  }
  selectedRow = row;
  selectedRowClass = row.className;
  row.className += " table-row-selected-action-popup";

  if (!mobile) {
    const popupWrapper = document.getElementById("action-popup-wrapper")!;

    const rect = row.getBoundingClientRect();

    // Find position to show the popup. We try to align it at the selected row,
    // but if that causes that the popup will be (partially) outside of the view,
    // we instead align it at the bottom.
    const scrollY = window.scrollY;
    let top = rect.top + scrollY - 10;
    const height = popupWrapper.getBoundingClientRect().height;
    const windowHeight = document.body.parentElement!.clientHeight; // Height of html element.
    const viewBottom = windowHeight + scrollY;

    if (top + height > viewBottom) {
      // Popup is outside of the view.
      // Realign at bottom
      top = viewBottom - height;
    }

    popupWrapper.style.left = `${ rect.right - 350 + document.body.scrollLeft + 10 }px`;
    popupWrapper.style.top = `${ top }px`;
  }
  return true;

  function appendList(list: NodeListOf<Node & ChildNode>, className: string) {
    const div = document.createElement("div");
    div.className = className;
    for (let i = 0; i < list.length; i++) {
      const cloned = list[i].cloneNode(true) as HTMLElement;
      const classes = cloned.className != null && cloned.className.split(" ");
      if (classes && classes.indexOf("btn-group") !== -1) {
        // Don't put buttons in a group, instead copy all buttons
        while (cloned.childNodes.length > 0) {
          div.appendChild(cloned.childNodes[0]);
        }
      } else if (classes && classes.indexOf("table-search-current-query") !== -1) {
        continue; // Ignore this element
      } else if (cloned.nodeType === Node.ELEMENT_NODE && cloned.getAttribute("data-html") === "true" && cloned.getAttribute("data-toggle") === "popover") {
        // Don't show a button with a popup. Instead, show the contents of the popup
        const content = document.createElement("div");
        content.innerHTML = cloned.getAttribute("data-content") || "";
        div.appendChild(content);
      } else {
        const input = findInputInCell(list[i] as HTMLElement);
        const clonedInput = findInputInCell(cloned);
        if (input !== undefined && (input.type === "checkbox" || input.type === "radio")) {
          // Don't show checkbox. Instead, show a 'select' button at the bottom of the popup
          const button = document.createElement("a");
          button.href = "javascript:;";
          button.className = "btn btn-primary";
          button.addEventListener("click", () => {
            input.click();
            actionPopupClose();
          });
          const glyphicon = document.createElement("i");
          glyphicon.className = (input as HTMLInputElement).checked ? "glyphicon glyphicon-check" : "glyphicon glyphicon-unchecked";
          button.appendChild(glyphicon);
          finalButton = document.createElement("div");
          finalButton.className = "action-popup-field";
          finalButton.appendChild(button);
          return;
        } else {
          if (input !== undefined && clonedInput !== undefined) {
            if (input.tagName === "SELECT") {
              clonedInput.value = input.value;
              clonedInput.addEventListener("change", () => input.value = clonedInput.value);
            } else {
              clonedInput.addEventListener("input", () => input.value = clonedInput.value);
            }
          }
          div.appendChild(cloned);
        }
      }
    }
    elements.push(div);
  }
}

function findInputInCell(element: HTMLElement) {
  if (element.tagName === "INPUT" || element.tagName === "SELECT") {
    return element as HTMLInputElement | HTMLSelectElement;
  }
  if (!element.className) return;
  const classes = element.className.split(" ");
  if (classes.indexOf("form-group") === -1 && classes.indexOf("checkbox") === -1) return;
  const label = element.children[0];
  if (label == null || label.tagName !== "LABEL") return;
  // A checkbox is nested in the label, other inputs are nested in `element`.
  const input = label.children[1] || element.children[1];
  if (input == null || (input.tagName !== "INPUT" && input.tagName !== "SELECT")) return;
  return input as HTMLInputElement | HTMLSelectElement;
}

let selectedRow: HTMLTableRowElement | undefined;
let tagPopupOpen = false;
let selectedRowClass = "";

function setActionPopup(elements?: HTMLElement[], hideJumpButtons = false) {
  const popup = document.getElementById("action-popup")!;
  const popupContent = document.getElementById("action-popup-content")!;

  if (selectedRow !== undefined) {
    selectedRow.className = selectedRowClass;
    selectedRow = undefined;
  }

  popupContent.innerHTML = "";
  if (elements === undefined) {
    popup.className = "action-popup action-popup-hidden";

    const popupWrapper = document.getElementById("action-popup-wrapper")!;
    popupWrapper.style.left = "";
    popupWrapper.style.top = "";
    tagPopupOpen = false;
    return;
  }

  if (popup.scrollTo) {
    popup.scrollTo(0, 0);
  } else {
    popup.scrollTop = 0;
  }
  popup.className = "action-popup" + (hideJumpButtons ? " action-popup-hide-jump-buttons" : "");
  for (const element of elements) {
    popupContent.appendChild(element);
  }
}

function getHeader(rows: HTMLCollection) {
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    if (row.children.length !== 0 && row.children[0].tagName === "TH") return row;
  }
  return undefined;
}

export function actionPopupSelectPreviousRow() {
  if (selectedRow && selectedRow.previousElementSibling && selectedRow.previousElementSibling.firstElementChild) {
    (selectedRow.previousElementSibling.firstElementChild as HTMLElement).click();
    return true;
  }
  return false;
}
export function actionPopupSelectNextRow() {
  if (selectedRow && selectedRow.nextElementSibling && selectedRow.nextElementSibling.firstElementChild) {
    (selectedRow.nextElementSibling.firstElementChild as HTMLElement).click();
    return true;
  }
  return false;
}
export function actionPopupClose() {
  if (selectedRow !== undefined || tagPopupOpen) {
    setActionPopup(undefined);
    return true;
  }
  return false;
}
export function actionPopupConfirm() {
  if (selectedRow === undefined) return false;
  const td = selectedRow.children[selectedRow.children.length - 1];
  if (td == null || td.children.length === 0) return false;
  const button = td.children[0];
  if (button.tagName !== "A") return false;
  (button as HTMLAnchorElement).click();
  return true;
}

export function actionPopupEdit(e: MouseEvent) {
  const a = findParent(e.target as HTMLElement, "A");
  if (a === undefined || a.lastElementChild === null) return;
  const form = a.lastElementChild.cloneNode(true) as HTMLFormElement;
  const fields: { [index: number]: HTMLElement } = {};

  for (let i = form.children.length - 1; i >= 0; i--) {
    const column = form.children[i].getAttribute("data-column");
    if (column !== null) {
      fields[parseInt(column, 10)] = form.children[i] as HTMLElement;
      form.removeChild(form.children[i]);
    }
  }

  let tr: HTMLTableRowElement | undefined = findParent(a, "TR") as HTMLTableRowElement | undefined;
  if (tr === undefined || tr.tagName !== "TR") tr = selectedRow;
  if (tr === undefined) return;

  clickMobileTableRow(tr, form, fields);
  e.preventDefault();
  e.stopImmediatePropagation();
}

export function actionPopupTags(e: MouseEvent) {
  tagPopupOpen = true;
  const element = findParent(e.target as HTMLElement, "DIV");
  if (element === undefined) return;

  const title = document.createElement("div");
  title.className = "action-popup-header action-popup-tags-header";
  title.textContent = element.children[element.children.length - 2].firstChild!.textContent;

  const content = [title];

  for (let i = 0; i < element.children.length - 3; i += 2) {
    const input = element.children[i];
    const tag = element.children[i + 1];
    if (input.tagName !== "INPUT" || tag.tagName !== "DIV" || (input as HTMLInputElement).checked) {
      continue;
    }
    const field = document.createElement("div");
    field.className = "action-popup-field action-popup-field-add-tag";
    const button = document.createElement("a");
    field.append(button);
    button.href = "javascript:;";
    button.addEventListener("click", () => {
      (input as HTMLInputElement).checked = true;
      actionPopupClose();
    });
    button.className = "btn btn-primary";
    button.textContent = tag.firstChild!.textContent;
    content.push(field);
  }
  setActionPopup(content, true);

  if (!isMobile()) {
    const elementButton = findParent(e.target as HTMLElement, "A")!;
    const elementTags = elementButton.parentElement!;

    const popupWrapper = document.getElementById("action-popup-wrapper")!;

    const rectButton = elementButton.getBoundingClientRect();
    const rectTags = elementTags.getBoundingClientRect();

    // Find position to show the popup. We try to align it at the selected row,
    // but if that causes that the popup will be (partially) outside of the view,
    // we instead align it at the bottom.
    const scrollY = window.scrollY;
    let top = rectTags.top + rectTags.height + scrollY;
    const left = Math.max(rectTags.left, rectButton.left + rectButton.width - 350) + document.body.scrollLeft;
    const height = popupWrapper.getBoundingClientRect().height;
    const windowHeight = document.body.parentElement!.clientHeight; // Height of html element.
    const viewBottom = windowHeight + scrollY;

    if (top + height > viewBottom) {
      // Popup is outside of the view.
      // Realign at bottom
      top = viewBottom - height;
    }

    popupWrapper.style.left = `${ left }px`;
    popupWrapper.style.top = `${ top }px`;
  }
}

(window as any).navigationActionPopupPrevious = actionPopupSelectPreviousRow;
(window as any).navigationActionPopupNext = actionPopupSelectNextRow;
(window as any).navigationActionPopupEdit = actionPopupEdit;
(window as any).navigationActionPopupTags = actionPopupTags;
