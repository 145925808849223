import * as React from "react";
import { State, Item } from "../model";
import { Dispatch } from "../../utils/state";
import { isMobile } from "../../navigation/navigation";
import * as action from "../action";
import { RedFlagPopup, RedFlagItem } from "../protocol/server";
import { Glyphicon } from "../../utils/view";
import { t } from "../../translations";

export function RedFlagDetails({ state, details, dispatch }: { state: State, details: State["redFlagDetails"], dispatch: Dispatch<State> }) {
  if (details === undefined) {
    return null;
  }

  let subtitle;
  if (details.type === "loading") {
    subtitle = t.notices.loading;
  } else if (details.redflags.length === 1) {
    subtitle = t.dashboard.redflags.critical_requirement_one;
  } else if (details.redflags.length !== 0) {
    subtitle = details.redflags.length + " " + t.dashboard.redflags.critical_requirements;
  } else {
    subtitle = t.dashboard.redflags.critical_requirements_none;
  }

  let selectedItem = details.type !== "redflags" || details.selected === undefined ? undefined
    : details.redflags[details.selected];

  return (
    <div className="dashboard-source">
      <div className="dashboard-source-header">
        <div className="dashboard-tool-close layout-color-light" onClick={ () => dispatch(action.closeTool) }><span>x</span></div>
        <h3>{ t.dashboard.item.redflags }</h3>
        <h4>{ subtitle }</h4>
      </div>
      <div className="dashboard-source-popup-wrapper">
        {
          selectedItem === undefined ? null : <Popup state={ state } item={ selectedItem } popup={ details.type === "redflags" ? details.popup : undefined } dispatch={ dispatch } />
        }
      </div>
      <div className="dashboard-source-list">
        {
          details.type === "redflags" && details.redflags.map((item, index) => {
            let className = "dashboard-source-item";
            if (item === selectedItem) className += " selected";
            return <div className={ className } key={ index } onClick={ () => dispatch(action.selectRedFlag(index)) }>
              <div className="dashboard-source-item-header">
                <div>{ item.code + " " + item.name }</div>
                <div><Glyphicon icon="flag" />{ item.count === 1 ? "" : " " + item.count }</div>
              </div>
              <div className="dashboard-source-item-body">
                <div>{ item.locationCount === 1 ? item.locationFirst : item.locationCount + " " + t.dashboard.redflags.locations }</div>
                <div>{ item.date }</div>
              </div>
              <div></div>
            </div>
          })
        }
      </div>
    </div>
  );
}

function Popup({ state, item, popup, dispatch }: { state: State, item: RedFlagItem, popup: RedFlagPopup | { type: "loading" } | undefined, dispatch: Dispatch<State> }) {
  let more = null;
  if (popup !== undefined) {
    if (popup.type === "loading") {
      more = <div className="dashboard-source-popup-message"><br /><br />{ t.dashboard.redflags.loading_more_data }<br /><br /></div>
    } else {
      more = <div>
        { popup.failedRequirements.map((requirement, index) =>
          <div className="dashboard-redflag-popup-requirement" key={ index }>
            <div className="dashboard-redflag-popup-requirement-date">{ requirement.date }</div>
            <div className="dashboard-redflag-popup-requirement-location">{ requirement.location }</div>
            <div className="dashboard-redflag-popup-requirement-client">{ requirement.client }</div>
            <div className="dashboard-redflag-popup-requirement-checklist">{
              requirement.supplier + "-" + requirement.service
                + (requirement.contractDescription === "" ? "" : "-" + requirement.contractDescription)
                + (requirement.checklistDescription === "" ? "" : "-" + requirement.checklistDescription)
            }</div>
            <div className="dashboard-redflag-popup-requirement-notes">{ requirement.notes }</div>
            <a href={ requirement.path } className="btn btn-default">{ t.actions.show }</a>
          </div>
        )}
        { popup.forbiddenRequirements === 0 ? null :
          <div className="dashboard-source-popup-message"><br /><br />{ popup.forbiddenRequirements === 1 ? t.dashboard.redflags.no_permission_one : t.dashboard.redflags.no_permission_multiple }<br /><br /></div>
        }
      </div>;
    }
  }
  return <div className="dashboard-source-popup" style={{ height: (state.windowHeight - (isMobile() ? 0 : 91) - 65) + "px" }}>
    <div>
      <div className="action-popup-header">{ t.dashboard.redflags.requirement }</div>
      <div className="action-popup-field">{ item.code + " " + item.name }</div>
      <div className="action-popup-header">{ t.dashboard.redflags.count_red_flags }</div>
      <div className="action-popup-field">{ item.count }</div>

      { more }

      <a href="javascript:;" className="btn btn-primary" onClick={ () => dispatch(action.selectRedFlag(undefined)) }>{ t.actions.close }</a>
    </div>
  </div>;
}
