import { Action } from "../utils/state";
import { Item, Chart, State, SourceSplit, getItem, ChartState, filtersToString, BarChart, BarChartGroup, BarChartBar, isSourceSplitDate, TrendChartValue, trendChartRange, getTimeRange, UserFilters, hasUserFilters } from "./model";
import { Socket } from "./socket";
import * as protocol from "./protocol";
import { ManageInfo } from "./protocol";
import { textWidth, textWidths, getText } from "./utils";

// Stores are NOT immutable.
// They will be modified. This is not an issue,
// since they are not used to render the page.

export interface Stores {
  items: {
    [id: string]: Store | undefined,
  },
  pending: protocol.ClientRequestSource[],
  lastUpdate: number,
  updatesInSession: number,
}

interface UpdateJob {
  reused: Set<number>,
  requests: protocol.ClientRequestSource[],
}

export interface Store {
  [filters: string]: CacheValues | undefined,
}

export type CachedSplit = Pick<Required<BarChart>, "bars" | "barGroups" | "valueWidth" | "valueUnitWidth" | "labelWidth"> & { unit: string | undefined };

export interface CacheValues {
  average?: Cached<{ value: number | undefined, redflags: number | undefined, benchmark: number | undefined, count: number, unit: string | undefined }>,
  split: { [split: string]: Cached<CachedSplit> | undefined },
}

export type Cached<T> = {
  state: "loading",
  // `data` contains old data if available
  data: T | undefined,
  date: Date,
  messageId: number,
} | {
  state: "loaded",
  data: T,
  date: Date,
};

export interface SplitValue {
  id: string,
  label: string,
  labelWidth: number,
  value: number,
}

export function createStores(): Stores {
  return {
    items: {},
    pending: [],
    lastUpdate: 0,
    updatesInSession: 0,
  };
}

function removeKey<V>(object: { [k: string]: V }, key: string): { [k: string]: V } {
  const { [key]: deleted, ...result } = object;
  return result;
}

function cancelPending(stores: Stores, socket: Socket, manageInfo: ManageInfo | null, messageId: () => number, old: protocol.ClientRequestSource[], reused: Set<number>) {
  if (old.length === 0) return;

  const ids = [];

  // Remove 'promises' of the canceled requests from the store.
  for (const request of old) {
    if (reused.has(request.messageId)) continue;

    const item = stores.items[request.source];
    let alreadyLoaded = false;
    if (item !== undefined) {
      const cacheValues = item[request.filters];
      if (cacheValues !== undefined) {
        if (request.split === undefined) {
          if (cacheValues.average !== undefined && cacheValues.average.state === "loaded") {
            // If the data was already loaded, we can keep in the cache.
            // We also cannot cancel the request anymore, as it was already processed.
            alreadyLoaded = true;
          } else {
            cacheValues.average = undefined;
          }
        } else {
          const oldValue = cacheValues.split[request.split];
          if (oldValue !== undefined && oldValue.state === "loaded") {
            alreadyLoaded = true;
          } else {
            cacheValues.split[request.split] = undefined;
          }
        }
      }
      removeIfEmpty(stores, request.source, request.filters);
    }
    if (!alreadyLoaded) {
      ids.push(request.messageId);
    }
  }

  if (ids.length !== 0) {
    socket.send({
      type: "cancel-pending",
      manageInfo,
      messageId: messageId(),
      messages: ids,
    });
  }
}

function removeIfEmpty(stores: Stores, id: string, filters: string) {
  const item = stores.items[id];
  if (item === undefined) return;
  const cacheValues = item[filters];
  if (cacheValues !== undefined && cacheValues.average === undefined) {
    for (const key in cacheValues.split) {
      if (cacheValues.split[key] !== undefined) {
        return;
      }
    }
    // empty, remove
    stores.items[id] = removeKey(item, filters);
  }
}

function getOrCreateStore(stores: Stores, id: string, filters: string) {
  let store = stores.items[id];

  if (store === undefined) {
    // Store does not exist.

    store = {};
    stores.items[id] = store;
  }

  if (store[filters] !== undefined) {
    // Store and filter exist, all ok
    return store[filters]!;
  }

  // Store exists, but filters were not added yet.
  const values: CacheValues = {
    average: undefined,
    split: {},
  };

  store[filters] = values;

  return values;
}


export const updateCharts = (stores: Stores, messageId: () => number, dashboardId: string, socket: Socket, manageInfo: ManageInfo | null, requestData: boolean): Action<State> => (state) => {
  if (state.dashboard.type !== "dashboard") return state;

  const isFiltered = hasUserFilters(state.filters.current);

  const items = { ...state.dashboard.items };

  let current = getItem(state, state.path[state.path.length - 1]);

  const job: UpdateJob = { requests: [], reused: new Set() };

  if (current === undefined) return state;

  if (current.items !== undefined) {
    for (const key of current.items) {
      let item = items[key];

      // Should not apply
      if (item === undefined) continue;

      const chart = updateChart(stores, messageId, dashboardId, job, manageInfo, item.id, state.filters.string, state.filters.current, item, item.chart, requestData);

      item = { ...item, chart };
      // To save some query time, we don't do additional queries for the norm of an item if filters are set. We might already have enough information to show it,
      // if so then we can use that. If not, we will just show the norm in the generic blue color (instead of green or red).
      item = updateChart(stores, messageId, dashboardId, job, manageInfo, item.id, state.filters.string, state.filters.current, item, item, requestData && !isFiltered);
      item = updateTrend(item);
      items[key] = item;
    }
  }

  if (current.type === "item") {
    current = updateChart(stores, messageId, dashboardId, job, manageInfo, current.id, state.filters.string, state.filters.current, current, current, requestData);
    current = updateTrend(current);

    const detailCharts = current.detailCharts.map(({ title, chart }) => {
      let currentFilters = state.filters.string;
      let currentFiltersObject = state.filters.current;
      if (chart.type === "bar" && !isSourceSplitDate(chart.split)) {
        // Remove the filter on `source.split` and load all bars
        if (state.filters.current.fields !== undefined && state.filters.current.fields[chart.split] !== undefined) {
          currentFiltersObject = {
            ...state.filters.current,
            fields: {
              ...state.filters.current.fields,
              [chart.split]: undefined,
            },
          };
          currentFilters = filtersToString(currentFiltersObject);
        }
      }
      return {
        title,
        chart: updateChart(stores, messageId, dashboardId, job, manageInfo, (current as Item).id, currentFilters, currentFiltersObject, current as Item, chart, requestData),
      };
    });

    items[current.id] = {
      ...current,
      chart: updateChart(stores, messageId, dashboardId, job, manageInfo, current.id, state.filters.string, state.filters.current, current, current.chart, requestData),
      detailCharts,
    };
  }

  if (requestData) {
    cancelPending(stores, socket, manageInfo, messageId, stores.pending, job.reused);
    stores.pending = job.requests;
    for (const request of job.requests) {
      socket.send(request);
    }
  }

  const dashboard = { ...state.dashboard, items };
  return { ...state, dashboard };

  function updateTrend(item: Item) {
    if (item.trendPeriod !== undefined) {
      if (isFiltered) {
        // To save some query time, we don't show the trend when filters are applied
        return { ...item, trend: undefined };
      } else {
        const filtersTrend: UserFilters = {
          ...state.filters.current,
          period: {
            type: "for-trend",
            split: item.trendPeriod,
          },
        };
        return updateChart(stores, messageId, dashboardId, job, manageInfo, item.id, filtersToString(filtersTrend), filtersTrend, item, { type: "item-trend", item, }, requestData).item;
      }
    } else {
      return item;
    }
  }
};

interface TrendItemMock {
  type: "item-trend",
  item: Item,
}

function updateChart(stores: Stores, messageId: () => number, dashboardId: string, job: UpdateJob, manageInfo: ManageInfo | null, id: string, filters: string, filtersObject: UserFilters, item: Item, chart: Item, requestData: boolean): Item;
function updateChart(stores: Stores, messageId: () => number, dashboardId: string, job: UpdateJob, manageInfo: ManageInfo | null, id: string, filters: string, filtersObject: UserFilters, item: Item, chart: TrendItemMock, requestData: boolean): TrendItemMock;
function updateChart(stores: Stores, messageId: () => number, dashboardId: string, job: UpdateJob, manageInfo: ManageInfo | null, id: string, filters: string, filtersObject: UserFilters, item: Item, chart: Chart, requestData: boolean): Chart;
function updateChart(stores: Stores, messageId: () => number, dashboardId: string, job: UpdateJob, manageInfo: ManageInfo | null, id: string, filters: string, filtersObject: UserFilters, item: Item, chart: Item | Chart | TrendItemMock, requestData: boolean): Chart | Item | TrendItemMock {
  const values = getOrCreateStore(stores, id, filters);

  if (chart.type === "item") {
    updateAverage();
    let value: number | undefined;
    let redflags: number | undefined;
    let benchmark;
    if (values.average !== undefined && values.average.data !== undefined && values.average.data.count !== 0) {
      value = values.average.data.value;
      redflags = values.average.data.redflags;
      benchmark = values.average.data.benchmark;
    }
    return { ...chart, value, redflags, benchmark };
  } else if (chart.type === "item-trend") {
    let trend: number | undefined;
    if (chart.item.trendPeriod !== undefined) {
      updateSplit(chart.item.trendPeriod);
      const cache = values.split[chart.item.trendPeriod];

      if (cache === undefined || cache.data === undefined) {
        // Cache not available
      } else {
        // Find latest value
        let latestId = -1;
        let latestValue = 0;
        for (const bar of cache.data.bars) {
          const barId = +bar.id!;
          if (barId > latestId) {
            latestId = barId;
            latestValue = bar.value;
          }
        }
        // Find value before latest
        let previousId = -1;
        let previousValue = 0;
        for (const bar of cache.data.bars) {
          const barId = +bar.id!;
          if (barId > previousId && barId !== latestId) {
            previousId = barId;
            previousValue = bar.value;
          }
        }
        if (latestId !== -1 && previousId !== -1 && latestId === previousId + 1) {
          trend = latestValue - previousValue;
        }
      }
    }
    const newItem: Item = {
      ...chart.item,
      trend,
    };
    return { type: "item-trend", item: newItem };
  } else if (chart.type === "number" || chart.type === "traffic-light") {
    updateAverage();

    if (values.average !== undefined && values.average.data !== undefined) {
      // Update chart with data from cache.
      chart = {
        ...chart,
        state: values.average.data.count > 0 ? ChartState.Normal : ChartState.NoData,
        value: values.average.data.value,
        unit: values.average.data.unit,
      };
    } else {
      // Cache not available, set to `undefined`.
      chart = {
        ...chart,
        state: ChartState.Loading,
        value: undefined,
        unit: undefined,
      };
    }
    if (chart.type === "number") {
      const value = chart.value || 0;

      let stringNumber;
      let stringDecimalUnit = "";
      if (item.decimals > 0) {
        const str = value.toFixed(item.decimals);
        const dot = str.indexOf(".");
        stringNumber = str.substring(0, dot);
        stringDecimalUnit = str.substring(dot);
      } else {
        stringNumber = value.toFixed(0);
      }
      if (chart.unit) {
        stringDecimalUnit += " " + chart.unit;
      }
      chart = {
        ...chart,
        text: getText(stringNumber),
        textDecimalUnit: getText(stringDecimalUnit),
      };
    }
    return chart;
  } else if (chart.type === "bar") {
    updateSplit(chart.split);

    // Check if cache exists
    const cache = values.split[chart.split];
    if (cache === undefined || cache.data === undefined) {
      // Cache not available
      return {
        ...chart,
        bars: [],
        state: ChartState.Loading,
        valueWidth: 1,
        valueUnitWidth: 1,
        labelWidth: 1,
      };
    }

    return {
      ...chart,
      bars: cache.data.bars,
      barGroups: cache.data.barGroups,
      state: cache.data.bars.length !== 0 || cache.data.barGroups.length !== 0 ? ChartState.Normal : ChartState.NoData,
      unit: cache.data.unit,
      valueWidth: cache.data.valueWidth,
      valueUnitWidth: cache.data.valueUnitWidth,
      labelWidth: cache.data.labelWidth,
    };
  } else if (chart.type === "trend") {
    updateSplit(chart.split);

    // Check if cache exists
    const cache = values.split[chart.split];
    if (cache === undefined || cache.data === undefined) {
      // Cache not available
      return {
        ...chart,
        values: undefined,
        state: ChartState.Loading,
      };
    }

    const { from, to, array } = trendChartRange(chart, getTimeRange(filtersObject.period));

    for (const bar of cache.data.bars) {
      const itemId = +(bar.id || "0");
      const index = itemId - from;
      if (itemId < from || index >= to) {
        console.log("Index out of range for data for trend chart");
        continue;
      }
      array[index] = { value: bar.value };
    }

    return {
      ...chart,
      fromPeriod: from,
      values: array,
      unit: cache.data.unit,
      state: cache.data.bars.length !== 0 ? ChartState.Normal : ChartState.NoData,
    };
  } else {
    // Unknown chart type
    return chart;
  }

  // Check if the cache should be updated
  function shouldUpdate<T>(value: Cached<T> | undefined) {
    if (!requestData) return false;
    if (value === undefined) return true;

    const age = Date.now() - +value.date;

    // Reload if cache is older than one minute
    return age >= 60 * 1000;
  }

  function send(request: protocol.ClientRequestSource) {
    job.requests.push(request);
  }

  function updateAverage() {
    if (!shouldUpdate(values.average)) return;

    const mid = messageId();

    values.average = createCachedLoading<{ value: number | undefined, redflags: number | undefined, benchmark: number | undefined, count: number, unit: string | undefined }>(mid, values.average);

    send({
      type: "request-source",
      manageInfo,
      messageId: mid,
      source: id,
      dashboardId,
      filters,
    });
  }

  function updateSplit(split: SourceSplit) {
    if (!shouldUpdate(values.split[split])) return;

    const mid = messageId();

    values.split[split] = createCachedLoading<CachedSplit>(mid, values.split[split]);

    send({
      type: "request-source",
      manageInfo,
      messageId: mid,
      source: id,
      split,
      dashboardId,
      filters,
    });
  }
}

function createCachedLoading<T>(id: number, old?: Cached<T>): Cached<T> {
  return {
    state: "loading",
    data: old === undefined ? undefined : old.data,
    date: new Date(),
    messageId: id,
  };
}
function createCachedLoaded<T>(data: T): Cached<T> {
  return {
    state: "loaded",
    data,
    date: new Date(),
  };
}

export function updateStore(stores: Stores, message: protocol.ServerProvideSource) {
  const store = getOrCreateStore(stores, message.source, message.filters);
  store.average = createCachedLoaded({ value: message.value, redflags: message.redflags, benchmark: message.benchmark, count: message.count, unit: message.unit });
}

export function updateStoreSplit(stores: Stores, message: protocol.ServerProvideSourceSplit) {
  const store = getOrCreateStore(stores, message.source, message.filters);

  const bars: CachedSplit["bars"] = [];
  const barGroups: CachedSplit["barGroups"] = [];
  const barGroupMap: Map<string, BarChartGroup> = new Map();

  if (!isSourceSplitDate(message.split)) {
    // Sort the values on their label
    message.values.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }

  for (const { parent } of message.values) {
    if (parent !== null) {
      const group: BarChartGroup = {
        id: parent.id,
        label: parent.label,
        labelWidths: textWidths(parent.label),
        bars: [],
        average: 0,
      };
      if (!barGroupMap.has(parent.id)) {
        barGroupMap.set(parent.id, group);
        barGroups.push(group);
      }
    }
  }

  let valueWidth = 0;
  let labelWidth = 0;

  for (const { id, label, value, parent } of message.values) {
    const width = textWidth(value.toFixed(message.decimals));
    if (width > valueWidth) valueWidth = width;

    const bar: BarChartBar = {
      id,
      label,
      labelWidths: textWidths(label),
      value,
    };
    const barLabelWidth = bar.labelWidths[bar.labelWidths.length - 1];
    if (barLabelWidth > labelWidth) labelWidth = barLabelWidth;

    if (parent === null) {
      const group = id === null ? undefined : barGroupMap.get(id);
      if (group !== undefined) {
        group.bars.splice(0, 0, bar);
        group.average += value;
      } else {
        bars.push(bar);
      }
    } else {
      const group = barGroupMap.get(parent.id)!;
      group.bars.push(bar);
      group.average += value;
    }
  }

  for (const group of barGroups) {
    group.average /= group.bars.length;
    const width = textWidth(group.average.toFixed(message.decimals));
    if (width > valueWidth) valueWidth = width;
    const barLabelWidth = group.labelWidths[group.labelWidths.length - 1];
    if (barLabelWidth > labelWidth) labelWidth = barLabelWidth;
  }

  const valueUnitWidth = valueWidth + (message.unit ? textWidth(" " + message.unit) : 0);

  store.split[message.split] = createCachedLoaded({ bars, barGroups, unit: message.unit, valueWidth, valueUnitWidth, labelWidth });
}
